import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useToasts } from '../../../components/core';
import { Api } from '../../../components/Axios';
import { AMModalError } from '../../../components/core/AMModalPopup/AMModalError';
import { SensorErrors } from '../constants/SensorErrors';
import '../Sensor.scss';
import './collectorConfiguration.scss';
import { CollectorConfigurationSplunk } from '../reference/Splunk/CollectorConfigurationSplunk';
import { CollectorConfigurationSumoLogic } from '../reference/SumoLogicCollector/CollectorConfigurationSumoLogic';
import { CollectorConfigurationQRadar } from '../reference/QRadarCollector/CollectorConfigurationQRadar';
import { AlertComponent } from '../AlertComponent';
import { formatDate } from '../../../utils/util-methods';
import { MESSAGES, DIRECT, COLLECTOR_ID_MAPPING, DYNAMIC_FIELDS_COLLECTOR_MAPPING, COLLECTORS_WITH_TEST_CONN } from '../constants/sensor-labels';
import { AMAdminPopUp } from '../../ref/AMAdminCommonComponents';
import { CrowdStrikeCollector } from '../reference/CrowdStrikeCollector';
import { CloudTrailCollector } from '../reference/CloudTrailCollector';
import { AWSCloudWatch } from '../reference/AWSCloudWatch';
import { AWSVPCCollector } from '../reference/AWSVPCCollector';
import { GCPVPCCollector } from '../reference/GCPVPCCollector';
import { CatoNetworkCollector } from '../reference/CatoNetworkCollector';
import { AzureNSGFlowCollector } from '../reference/AzureNSGFlowCollector';
import { SyslogCollect } from '../reference/SyslogCollector';
import { CiscoUmbrellaCollector } from '../reference/CiscoUmbrellaCollector';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';
import { PingIdentityCollector } from '../reference/PingIdentityCollector';
import { OktaCollector } from '../reference/OktaCollector';
import { OneLoginCollector } from '../reference/OneLoginCollector';
import { IBMVerifySaasCollector } from '../reference/IBMVerifySaasCollector';
import { AzureADCollector } from '../reference/AzureADCollector';
import { GoogleWorkspaceCollector } from '../reference/GoogleWorkspaceCollector';
import { Perimeter81Collector } from '../reference/Perimeter81Collector';
import { IBMVerifyAccess } from '../reference/IBMVerifyAccess';

let id = 0;
const getUniqueId = () => {
    return id++ + '';
};

export const CollectorConfigurationContainer = ({
    collectorName = '',
    id = '',
    onCloseHandler,
    data,
    setShowFlowSensor,
    afterSaveCollectorOperation
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [collectorId, setCollectorId] = useState(id);
    const [collectionMethod, setCollectionMethod] = useState([]);
    const [isEdit, setIsEdit] = useState(id !== '' ? true : false);
    const [availableCollectionMethods, setAvailableCollectionMethods] =
        useState([]);
    const [collectorData, setCollectorData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const {
        register,
        setError,
        getValues,
        setValue,
        clearErrors,
        watch,
        trigger,
        errors
    } = useForm({ mode: 'onChange' });
    const { addToast } = useToasts();
    const values = watch();

    useEffect(() => {
        console.log({ errors });
    }, [errors]);

    const onCollectionMethodSelect = (e, val) => {
        setCollectionMethod(val);
        clearErrors('collection_method');
        const collectionMethodObj = availableCollectionMethods.find(item => item._ui_id == val[0].key);
        if (collectionMethodObj && collectionMethodObj.is_direct_already_configured) {
            setAlerts([createAlertMessage(`${MESSAGES[9]} ${collectionMethodObj.label}`, "error", false)])
        } else {
            setAlerts([])
        }
        if (val && val[0]['key'] === 'FLOW') {
            onCloseHandler();
            setShowFlowSensor(true);
        } else if (val && val[0]['key'] === 'SIEM') {
            onCloseHandler();
            history.push(`/deploy/supportedCollector?category=SIEM`);
        }
    };

    const getCollectionMethodId = (colMethod) => {
        const colMethodTemp = availableCollectionMethods.find((item) => {
            return item._ui_id === colMethod;
        });
        let res = '';
        if (
            colMethodTemp &&
            colMethodTemp.siem_id &&
            colMethodTemp.siem_id !== ''
        ) {
            if (colMethodTemp.is_via_sumologic) {
                res = '14';
            } else if (colMethodTemp.is_via_qradar) {
                res = '13';
            } else if (colMethodTemp.is_via_splunk) {
                res = '12';
            }
        } else if (
            colMethodTemp && colMethodTemp.collection_method &&
            (colMethodTemp.collection_method === 'API')
        ) {
            res = data.collector_data_source;
        } else if (colMethodTemp && colMethodTemp.collection_method &&
            colMethodTemp.collection_method === 'Syslog' &&
            collectorName === COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS
        ) {
            res = COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS
        } else if (colMethodTemp && colMethodTemp.collection_method &&
            colMethodTemp.collection_method === 'Syslog') {
            res = "Syslog";
        } else if (colMethodTemp && colMethodTemp.is_direct && colMethodTemp.label === "Cloud Direct") {
            switch (collectorName) {
                case COLLECTOR_ID_MAPPING.Ping_identity_PingOne_IdP:
                    res = COLLECTOR_ID_MAPPING.Ping_identity_PingOne_IdP;
                    break;
                case COLLECTOR_ID_MAPPING.Okta_IdP:
                    res = COLLECTOR_ID_MAPPING.Okta_IdP;
                    break;
                case COLLECTOR_ID_MAPPING.OneLogin_IdP:
                    res = COLLECTOR_ID_MAPPING.OneLogin_IdP;
                    break;
                case COLLECTOR_ID_MAPPING.IBM_Verify_SaaS:
                    res = COLLECTOR_ID_MAPPING.IBM_Verify_SaaS;
                    break;
                case COLLECTOR_ID_MAPPING.Google_Workspace:
                    res = COLLECTOR_ID_MAPPING.Google_Workspace;
                    break;
                case COLLECTOR_ID_MAPPING.Microsoft_Entra_ID:
                    res = COLLECTOR_ID_MAPPING.Microsoft_Entra_ID;
                    break;
            }
        }
        return res;
    };

    const getDynamicCollectorMeta = () => {
        return DYNAMIC_FIELDS_COLLECTOR_MAPPING.find(item => item.id === data.collector_data_source);
    }

    const getCollectorConfigurationComponent = (colMethod) => {
        let props = {
            register,
            setValue,
            collectorId,
            collectorName,
            collectorData
        };
        let res = getCollectionMethodId(colMethod);
        let collectionMethodData = availableCollectionMethods.find((item: any) => item?._ui_id === colMethod);
        switch (res) {
            case '12':
                return (
                    <CollectorConfigurationSplunk
                        {...props} dynamicCollectors={getDynamicCollectorMeta()}
                    ></CollectorConfigurationSplunk>
                );
            case '14':
                return (
                    <CollectorConfigurationSumoLogic
                        {...props} dynamicCollectors={getDynamicCollectorMeta()}
                    ></CollectorConfigurationSumoLogic>
                );
            case '13':
                return (
                    <CollectorConfigurationQRadar
                        {...{ ...props, clearErrors }} dynamicCollectors={getDynamicCollectorMeta()}
                        additionalCollector={getAdditionalCollectorConfig(res, props, collectionMethodData)}
                    ></CollectorConfigurationQRadar>
                );
            case COLLECTOR_ID_MAPPING.Crowdstrike_Falcon_EDR:
                return <CrowdStrikeCollector {...props} />;
            case COLLECTOR_ID_MAPPING.AWS_S3:
                return <CloudTrailCollector {...props} />;
            case COLLECTOR_ID_MAPPING.AWS_Managed_AD:
                return <AWSCloudWatch {...props} />;
            case COLLECTOR_ID_MAPPING.AWS_VPC_Logs:
                return <AWSVPCCollector {...props} collectionMethodData={collectionMethodData} />;
            case COLLECTOR_ID_MAPPING.GCP_VPC_Logs:
                return (
                    <GCPVPCCollector
                        {...props}
                        setError={setError}
                        getValues={getValues}
                        clearErrors={clearErrors}
                    />
                );
            case COLLECTOR_ID_MAPPING.Cato_SASE:
                return <CatoNetworkCollector {...props} />;
            case COLLECTOR_ID_MAPPING.Azure_NSG_Logs:
                return <AzureNSGFlowCollector {...props} />;
            case COLLECTOR_ID_MAPPING.SYSLOG:
                return <SyslogCollect {...props} collectorName={collectorName} collectionMethodData={collectionMethodData} />
            case COLLECTOR_ID_MAPPING.Cisco_Umberlla_SIG:
            case COLLECTOR_ID_MAPPING.Cisco_Umbrella_DNS_Security:
                return <CiscoUmbrellaCollector {...props} />;
            case COLLECTOR_ID_MAPPING.Ping_identity_PingOne_IdP:
                return <PingIdentityCollector {...props} />
            case COLLECTOR_ID_MAPPING.Okta_IdP:
                return <OktaCollector {...props} />
            case COLLECTOR_ID_MAPPING.OneLogin_IdP:
                return <OneLoginCollector {...props} />
            case COLLECTOR_ID_MAPPING.IBM_Verify_SaaS:
                return <IBMVerifySaasCollector {...props} />
            case COLLECTOR_ID_MAPPING.Google_Workspace:
                return <GoogleWorkspaceCollector setError={setError}
                    getValues={getValues}
                    clearErrors={clearErrors} {...props} />
            case COLLECTOR_ID_MAPPING.Microsoft_Entra_ID:
                return <AzureADCollector {...props} />
            case COLLECTOR_ID_MAPPING.Checkpoint_Perimeter_81:
                return <Perimeter81Collector {...props} />
            case COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS:
                return <IBMVerifyAccess {...props} collectionMethodData={collectionMethodData} />
            case DIRECT:
            default:
                return <></>;
        }
    };

    const fetchCollectionMethods = () => {
        Api.get(
            '/collectors/collectionmethod?data_src=' +
            encodeURIComponent(collectorName)
        )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.length === 0) {
                        setAlerts([createAlertMessage(`${MESSAGES[6]}`, 'error', false)]);
                    }
                    let tempData = res.data.map((item) => {
                        return { ...item, _ui_id: getUniqueId() };
                    });
                    if (data.is_via_qradar || data.is_via_splunk || data.is_via_sumologic) {
                        tempData = [
                            ...tempData,
                            { label: 'Add New SIEM', _ui_id: 'SIEM', showSeparator: true },
                        ];
                    }
                    if (data.is_via_flow_sensor) {
                        tempData = [
                            ...tempData,
                            { label: 'Add New Flow Sensor', _ui_id: 'FLOW', showSeparator: true }
                        ];

                    }

                    setAvailableCollectionMethods(tempData);
                }
            })
            .catch((error: any) => { });
    };

    const compareObjects = (obj1, obj2) => {
        let objEqual = false;
        const obj1Keys = Object.keys(obj1).sort();
        const obj2Keys = Object.keys(obj2).sort();
        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        } else {
            const areEqual = obj1Keys.every((key, index) => {
                const objValue1 = obj1[key];
                const objValue2 = obj2[obj2Keys[index]];
                return objValue1 === objValue2;
            });
            if (areEqual) {
                return true;
            } else {
                return false;
            }
        }
    };

    const getCollectorDataWithUniqueId = (
        collectorObj,
        collectionMethodList
    ) => {
        let res = collectionMethodList.find((item) => {
            const { _ui_id, flow_sensor_ip, flow_sensor_name, collection_method, is_direct_already_configured, ...rest } = item;
            return compareObjects(rest, collectorObj);
        });
        return res;
    };

    const getAdditionalCollectorConfig = (res, props, collectorMethodData) => {
        if (res === '13' && collectorName === COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS) {
            return <IBMVerifyAccess {...props} collectionMethodData={collectorMethodData} showSyslog={false} />
        }
        return <></>
    }

    const isAdditionalConfigAvailable = (res) => {
        if (res === '13' && collectorName === COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS) {
            return COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS
        }
        return null;
    }

    const fetchCollectorData = () => {
        Api.get(`/collectors/managed/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const collectionMethodList = [{
                        ...res.data.collection_method,
                        _ui_id: getUniqueId()
                    }]
                    setAvailableCollectionMethods(collectionMethodList);
                    setCollectorData(res.data);
                    setCollectionMethod([{ key: collectionMethodList[0]._ui_id, value: collectionMethodList[0].label }]);
                    setValue('collection_method', [{ key: collectionMethodList[0]._ui_id, value: collectionMethodList[0].label }]);
                    setValue('collector_name', res.data.name);
                    setAlerts(getAlertMessages(res.data));
                }
            })
            .catch((error: any) => { });
    };

    useEffect(() => {
        if (isEdit) {
            fetchCollectorData();
        } else {
            fetchCollectionMethods();
        }
    }, []);

    const isNotEmpty = (field) => {
        return field !== undefined && typeof field !== 'undefined' && field !== null && field !== "";
    }

    const getSplunkDynamicFieldsData = (tempData) => {
        const dynamicMeta = getDynamicCollectorMeta();
        let res = {};
        dynamicMeta.collectors.forEach((item, idx) => {
            if (isNotEmpty(tempData[`source_type_${idx}`]) && isNotEmpty(tempData[`source_index_${idx}`])) {
                res[item.id] = {
                    source_type: tempData[`source_type_${idx}`],
                    source_index: tempData[`source_index_${idx}`],
                    source_timezone: tempData['source_timezone'][0]?.key
                }
            }
        })
        return res;
    }

    const getSumoLogicDynamicFieldsData = (tempData) => {
        const dynamicMeta = getDynamicCollectorMeta();
        let res = {};
        dynamicMeta.collectors.forEach((item, idx) => {
            if (isNotEmpty(tempData[`index_${idx}`]) && isNotEmpty(tempData[`source_prefix_${idx}`])) {
                res[item.id] = {
                    index: tempData[`index_${idx}`],
                    source_prefix: tempData[`source_prefix_${idx}`],
                    source_timezone: tempData['source_timezone'][0]?.key
                }
            }
        })
        return res;
    }

    const getQRadarDynamicFieldsData = (tempData) => {
        const dynamicMeta = getDynamicCollectorMeta();
        let res = {};
        console.log({ tempData })
        dynamicMeta.collectors.forEach((item, idx) => {
            if (isNotEmpty(tempData[`source_type_${idx}`]) && isNotEmpty(tempData[`source_ids_${idx}`]) && tempData[`protocol_${idx}`].length > 0) {

                let additional = { name: "", pattern: "" };
                if (tempData[`protocol_${idx}`][0]?.key === 'other') {
                    additional = {
                        name: tempData[`name_${idx}`],
                        pattern: tempData[`pattern_${idx}`]
                    };
                    if (isNotEmpty(additional['name']) && isNotEmpty(additional['pattern'])) {
                        res[item.id] = {
                            source_type: tempData[`source_type_${idx}`],
                            source_ids: tempData[`source_ids_${idx}`],
                            source_timezone: tempData[`source_timezone`][0]?.key,
                            protocol_header: {
                                protocol: tempData[`protocol_${idx}`][0]?.key,
                                ...additional
                            }
                        };
                    }
                } else {
                    res[item.id] = {
                        source_type: tempData[`source_type_${idx}`],
                        source_ids: tempData[`source_ids_${idx}`],
                        source_timezone: tempData[`source_timezone`][0]?.key,
                        protocol_header: {
                            protocol: tempData[`protocol_${idx}`][0]?.key,
                            ...additional
                        }
                    };
                }
            }
        })
        return res;
    }

    const isDynamicView = () => {
        return DYNAMIC_FIELDS_COLLECTOR_MAPPING.some(item => item.id === data.collector_data_source)
    }

    const getCollectorConfig = (res, tempData) => {
        switch (res) {
            case '12':
                if (isDynamicView()) {
                    return getSplunkDynamicFieldsData(tempData)
                } else {
                    return {
                        source_type: tempData['source_type'],
                        source_index: tempData['source_index'],
                        source_timezone: tempData['source_timezone'][0]?.key
                    };
                }
            case '14':
                if (isDynamicView()) {
                    return getSumoLogicDynamicFieldsData(tempData)
                } else {
                    return {
                        index: tempData['index'],
                        source_prefix:
                            tempData['source_prefix'],
                        source_timezone:
                            tempData['source_timezone'][0]?.key
                    };
                }
            case '13':
                if (isDynamicView()) {
                    return getQRadarDynamicFieldsData(tempData)
                } else {
                    let additional = { name: "", pattern: "" };
                    if (tempData['protocol'][0]?.key === 'other') {
                        additional = {
                            name: tempData['name'],
                            pattern: tempData['pattern']
                        };
                    }
                    return {
                        source_type: tempData['source_type'],
                        source_ids: tempData['source_ids'],
                        source_timezone: tempData['source_timezone'][0]?.key,
                        protocol_header: {
                            protocol: tempData['protocol'][0]?.key,
                            ...additional
                        }
                    };
                }

            case COLLECTOR_ID_MAPPING.Crowdstrike_Falcon_EDR:
                return {
                    client_id: tempData['client_id'],
                    secret: tempData['secret'],
                    base_url: tempData['base_url']
                };
            case COLLECTOR_ID_MAPPING.AWS_S3:
                return {
                    cloudtrail_sqs: tempData['cloudtrail_sqs'],
                    cloudtrail_aws_access_key:
                        tempData['cloudtrail_aws_access_key'],
                    cloudtrail_aws_access_secret:
                        tempData['cloudtrail_aws_access_secret']
                };
            case COLLECTOR_ID_MAPPING.AWS_Managed_AD:
                return {
                    aws_cloudwatch_log_group: tempData['aws_cloudwatch_log_group'],
                    aws_cloudwatch_region: tempData['aws_cloudwatch_region'],
                    aws_cloudwatch_access_key: tempData['aws_cloudwatch_access_key'],
                    aws_cloudwatch_access_secret: tempData['aws_cloudwatch_access_secret'],
                    managed_ad: 'managed_ad'
                };
            case COLLECTOR_ID_MAPPING.AWS_VPC_Logs:
                return {
                    aws_query_url: tempData['aws_query_url'],
                    aws_access_key_id: tempData['aws_access_key_id'],
                    aws_access_key_secret: tempData['aws_access_key_secret'],
                    aws_vpc_flow_log_format: tempData['aws_vpc_flow_log_format']
                };


            case COLLECTOR_ID_MAPPING.GCP_VPC_Logs:
                return {
                    project_id: tempData['project_id'],
                    topic_name: tempData['topic_name'],
                    subscription_name: tempData['subscription_name'],
                    service_acc_key: tempData['service_acc_key']
                };
            case COLLECTOR_ID_MAPPING.Cato_SASE:
                return {
                    account_ids: tempData['account_ids'],
                    api_key: tempData['api_key']
                };
            case COLLECTOR_ID_MAPPING.Azure_NSG_Logs:
                return {
                    storage_account_name: tempData['storage_account_name'],
                    storage_access_key: tempData['storage_access_key'],
                    container: tempData['container'],
                    tenant_id: tempData['tenant_id'],
                    client_id: tempData['client_id'],
                    client_secret: tempData['client_secret']
                };
            case COLLECTOR_ID_MAPPING.Cisco_Umberlla_SIG:
            case COLLECTOR_ID_MAPPING.Cisco_Umbrella_DNS_Security:
                if (tempData['managed_bucket'] === 'self_managed_bucket') {
                    return {
                        managed_bucket: tempData['managed_bucket'],
                        sqsurl: tempData['sqsurl'],
                        accesskey: tempData['accesskey'],
                        secretkey: tempData['secretkey'],
                    };
                } else {
                    return {
                        managed_bucket: tempData['managed_bucket'],
                        accesskey: tempData['accesskey'],
                        secretkey: tempData['secretkey'],
                        bucket_arn: tempData['bucket_arn'],
                    };
                }
            case COLLECTOR_ID_MAPPING.Ping_identity_PingOne_IdP:
                return {
                    ping_token_endpoint: tempData['ping_token_endpoint'],
                    ping_client_id: tempData['ping_client_id'],
                    ping_client_secret: tempData['ping_client_secret'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.Okta_IdP:
                return {
                    okta_sub_domain: tempData['okta_sub_domain'],
                    okta_api_token: tempData['okta_api_token'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.OneLogin_IdP:
                return {
                    ol_sub_domain: tempData['ol_sub_domain'],
                    ol_client_id: tempData['ol_client_id'],
                    ol_client_secret: tempData['ol_client_secret'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.IBM_Verify_SaaS:
                return {
                    ibm_token_endpoint: tempData['ibm_token_endpoint'],
                    ibm_client_id: tempData['ibm_client_id'],
                    ibm_client_secret: tempData['ibm_client_secret'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.Google_Workspace:
                return {
                    customer_id: tempData['customer_id'],
                    delegate_email: tempData['delegate_email'],
                    service_acc_key: tempData['service_acc_key'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.Microsoft_Entra_ID:
                return {
                    tanent_id: tempData['tanent_id'],
                    client_id: tempData['client_id'],
                    client_secret: tempData['client_secret'],
                    mfa_enabled: tempData['mfa_enabled'],
                };
            case COLLECTOR_ID_MAPPING.Checkpoint_Perimeter_81:
                return {
                    sqsurl: tempData['sqsurl'],
                    accesskey: tempData['accesskey'],
                    secretkey: tempData['secretkey']
                };
            case COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS: {
                return {
                    ibm_verify_access_host_ip: tempData['ibm_verify_access_host_ip'],
                    ibm_verify_access_username: tempData['ibm_verify_access_username'],
                    ibm_verify_access_password: tempData['ibm_verify_access_password']
                }
            }
            case DIRECT:
            case 'default':
                return null;
        }
    };

    const [showHFS, setShowHFS] = useState(false);

    const prepareData = () => {
        const tempData = getValues();
        const colMethodId = getCollectionMethodId(collectionMethod[0]?.key);
        let res = getCollectorConfig(colMethodId, tempData);

        const additionalCollector = isAdditionalConfigAvailable(colMethodId)
        if (additionalCollector) {
            const tempRes = getCollectorConfig(additionalCollector, tempData);
            res = { ...res, ...tempRes };
        }

        let collectionMethodTemp = JSON.parse(JSON.stringify(availableCollectionMethods)).find((item) => {
            return item._ui_id == collectionMethod[0]?.key;
        });

        if (collectionMethodTemp && collectionMethodTemp.hasOwnProperty('_ui_id')) {
            delete collectionMethodTemp._ui_id;
        }
        if (['12', '13', '14'].includes(colMethodId)) {
            if (Object.keys(res).length === 0) {
                return null;
            }
        }

        return {
            name: tempData['collector_name'],
            category: data['category'],
            collection_method: collectionMethodTemp,
            collector_config: res,
            vendor_name: data['vendor_name'],
            collector_data_source: data['collector_data_source']
        };
    };

    const handleClose = () => {
        setShowHFS(false);
    };

    const HostedSensorPopUp = ({ collectorData, popUpButtons }: any | null) => {
        return (
            <AMAdminPopUp
                popUpTitle={"Delete Collector"}
                popUpButtons={popUpButtons}
                handleClose={handleClose}
            >
                <span className="font14">
                    <strong>Direct collection  method (Hosted Flow sensor) will be available  after few minutes. Please check  "Managed Sensor" tab for more information. </strong> ?{" "}
                </span>
            </AMAdminPopUp>
        );
    };

    const validateBeforeDataPrepare = () => {
        let isValid = true;
        const colMethodId = getCollectionMethodId(collectionMethod[0]?.key);
        const tempData = getValues();

        if (errors && errors !== null && Object.keys(errors).length > 0 && !isDynamicView()) {
            const newErrors = Object.keys(errors).filter(item => !['collection_method', 'source_timezone', 'protocol'].includes(item));
            if (newErrors && newErrors.length > 0) {
                isValid = false;
            }
        }

        if (errors && errors !== null && Object.keys(errors).length > 0) {
            addToast('Please fill all the mandatory fields.', {
                appearance: 'error',
                autoDismiss: true
            });
            isValid = false;
        }

        return isValid;
    };

    const saveConfiguration = async () => {
        const formTrigger = await trigger();
        const tempData = getValues();
        if (tempData && tempData['protocol'] && tempData['protocol'].length === 0) {
            setError('protocol', { type: 'required' });
            return;
        } else {
            clearErrors('protocol');
        }
        if (collectionMethod.length === 0) {
            setError('collection_method', { type: 'required' });
            return;
        } else {
            clearErrors('collection_method');
        }
        if (!formTrigger) {
            return;
        }
        if (!validateBeforeDataPrepare())
            return;
        let payload = prepareData();

        if (!payload) {
            addToast('Please fill atleast one configuration.', {
                appearance: 'error',
                autoDismiss: true
            });
            return;
        }
        // console.table({errors})
        // console.log({payload})
        // return;

        let headers = {},
            apiUrl = `/collectors/managed`;
        if (isEdit) {
            headers = { Operation: 'PUT' };
            apiUrl = `${apiUrl}/${id}`;
        }

        setIsLoaded(false);

        Api.post(apiUrl, payload, { headers: headers })
            .then((res) => {
                setLoading(false);
                if (res) {
                    setIsLoaded(true);
                    if (res.data && typeof res.data != 'string') {
                        setAlerts(getTestConnectionAlertMessages(res.data));
                    } else {
                        if (isEdit) {
                            addToast('Collector updated successfully.', {
                                appearance: 'success',
                                autoDismiss: true
                            });
                            if (afterSaveCollectorOperation) {
                                afterSaveCollectorOperation(data.category)
                            }
                            onCloseHandler();
                        } else {
                            addToast('Collector added successfully.', {
                                appearance: 'success',
                                autoDismiss: true
                            });
                            if (
                                res.status === 201 &&
                                res.data === 'hosted flow sensor is added'
                            ) {
                                setShowHFS(true);
                            }
                        }
                        onCloseHandler();
                        history.push('/deploy/manageCollector');
                    }
                }
            })
            .catch((error: any) => {
                setLoading(false);
                setIsLoaded(true);
                if (error.response.status === 500) {
                    addToast('Sorry, something went wrong there, try again.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
                else if (error.response.status === 419) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })

                } else if (error.response.status === 404 || error.response.status === 400) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })

                } else if (error.response.status === 400) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })

                }
            });
    };

    const getTestConnectionAlertMessages = (data) => {
        let res = [];
        if (data && Object.keys(data).length > 0 && data['error_message'] && data['error_message'] !== "") {
            if (res.length === 0) {
                res.push(
                    createAlertMessage(
                        `${MESSAGES[11]} Expand to view specific issue details.`,
                        'error',
                        true
                    )
                );
            }
            let tempAdditionalErrors = []
            Object.keys(data).forEach(item => {
                let isSecondary = item === 'User-Inputs';
                if (isSecondary) {
                    tempAdditionalErrors[1] = createAlertMessage(
                        `${data[item]}`,
                        'error',
                        false,
                        isSecondary
                    )
                } else {
                    tempAdditionalErrors[0] = createAlertMessage(
                        `${data[item]}`,
                        'error',
                        false,
                        isSecondary
                    )
                }
            })
            res = res.concat(tempAdditionalErrors)
        } else {
            res.push(
                createAlertMessage(
                    `${MESSAGES[2]}`,
                    'success',
                    false
                )
            );
        }
        return res;
    };

    const getAlertMessages = (data) => {
        let res = [];
        if (data.fs_last_heart_beat && moment(data.fs_last_heart_beat).isBefore("2021-01-01T00:00:00Z") && !data.sensor_settings) {
            res.push(createAlertMessage(
                `${MESSAGES[13]}`,
                'inactive',
                false
            ))
            return res;
        }
        if (
            moment
                .duration(moment().diff(moment(data.last_flow_time)))
                .asHours() < 2 && moment
                    .duration(moment().diff(moment(data.fs_last_flow_time)))
                    .asHours() < 2
        ) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[0]} ${formatDate(data.last_flow_time)}`,
                    'success',
                    false
                )
            );
        } else {
            if (data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")) {
                res.push(
                    createAlertMessage(
                        `${MESSAGES[1]} ${formatDate(
                            data.last_flow_time
                        )} Expand to view specific issue details.`,
                        'error',
                        true
                    )
                );

                res.push(
                    createAlertMessage(
                        `${MESSAGES[4]} ${formatDate(data.last_flow_time)}`,
                        'error',
                        false
                    )
                );
            } else {
                res.push(
                    createAlertMessage(
                        `${MESSAGES[11]} Expand to view specific issue details.`,
                        'error',
                        true
                    )
                );
                res.push(
                    createAlertMessage(
                        `${MESSAGES[5]}`,
                        'error',
                        false
                    )
                );
            }
        }

        const additionalErrors = sensorSettingsErrors(data);
        if (data.sensor_settings && Object.keys(data.sensor_settings).length > 0 && data.sensor_settings['error_message'] && data.sensor_settings['error_message'] !== "") {
            if (res.length === 0) {
                res.push(
                    createAlertMessage(
                        `${MESSAGES[11]} Expand to view specific issue details.`,
                        'error',
                        true
                    )
                );
            }
            let tempAdditionalErrors = []
            Object.keys(data.sensor_settings).forEach(item => {
                let isSecondary = item === 'User-Inputs';
                if (isSecondary) {
                    tempAdditionalErrors[1] = createAlertMessage(
                        `${data.sensor_settings[item]}`,
                        'error',
                        false,
                        isSecondary
                    )
                } else {
                    tempAdditionalErrors[0] = createAlertMessage(
                        `${data.sensor_settings[item]}`,
                        'error',
                        false,
                        isSecondary
                    )
                }
            })
            res = res.concat(tempAdditionalErrors)
        }
        return res;
    };

    const sensorSettingsErrors = (data) => {
        let res = [];
        if (data.sensor_settings) {
            res = Object.keys(data.sensor_settings).map((sensor_setting: any, idx: number) =>
                `${sensor_setting} - ${data.sensor_settings[sensor_setting]}`
            )
        }
        return res;
    }

    const createAlertMessage = (text, type, isSummary, isSecondary) => {
        return { type, isSummary, text, isSecondary };
    };


    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    useEffect(() => {
        register('collection_method', {
            required: false,
            shouldUnregister: true
        });
    }, []);

    const isTestAndSaveVisible = () => {
        let res = false;

        if (collectionMethod && collectionMethod[0] && testCollectionMethod(collectionMethod[0]['value']) && COLLECTORS_WITH_TEST_CONN.indexOf(collectorName) > -1) {
            if (collectorName === COLLECTOR_ID_MAPPING.Cisco_Umbrella_DNS_Security) {
                const tempData = getValues();
                if (!tempData['managed_bucket'] || tempData['managed_bucket'] === 'self_managed_bucket') {
                    res = true;
                }
            } else if (collectorName === COLLECTOR_ID_MAPPING.AWS_VPC_Logs) {
                const tempData = getValues();
                if (!tempData['managed_bucket'] || tempData['managed_bucket'] === 'vpc_managed') {
                    res = true;
                }
            } else {
                res = true;
            }
        }

        return res;
    }

    const testCollectionMethod = (val) => {
        switch (collectorName) {
            case COLLECTOR_ID_MAPPING.Cisco_Umbrella_DNS_Security:
            case COLLECTOR_ID_MAPPING.GCP_VPC_Logs:
            case COLLECTOR_ID_MAPPING.Azure_NSG_Logs:
            case COLLECTOR_ID_MAPPING.AWS_VPC_Logs:
            case COLLECTOR_ID_MAPPING.AWS_S3:
                return isTestableFlow(val);
            default:
                return 'Cloud Direct' === val;
        }
    }

    const isTestableFlow = (val) => {
        if (val === 'Cloud Direct') {
            return true;
        }
        /* const found = availableCollectionMethods.find(item => item.label === val);
        if (!found.hasOwnProperty('siem_id')) {
            return true;
        } */
        return false;
    }

    const getSaveBtnText = (str) => {
        return isTestAndSaveVisible() ? `Test and ${str}` : str;
    }

    const hasAddNewSIEM = availableCollectionMethods.some(item => item.label === 'Add New SIEM');


    return (
        // {showHFS && <HostedSensorPopUp></HostedSensorPopUp>}
        
        <form
            className='sensor-form-container collector-main-container'
            onSubmit={(e) => e.preventDefault()}
        >

            <div className='form-body-div scrollbar-container add-sensor-body-div'>
                {alerts.length > 0 && <AlertComponent messages={alerts} />}
                <div className='sensor-flex-container collector-container'>
                    <div className='sensor-flex-container-item'>
                        <label>Name*</label>
                        <input
                            type='text'
                            placeholder='Enter Collector name'
                            name='collector_name'
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/,
                                shouldUnregister: true
                            })}
                            className={'flex-basis-100'}
                            autoComplete='off'
                        />
                    </div>

                    <div className='sensor-flex-container-item'>
                        <label>Collection Method*</label>
                        <div style={{ width: "98.5%", marginTop: "12%" }} className="autocom">
                            <CheckboxAutocomplete
                                label="Select Collection Method"
                                className={'flex-basis-100 form_drodown'}
                                options = {availableCollectionMethods.map(item => ({
                                    key: item._ui_id,
                                    value: item.label,
                                    showSeparator: hasAddNewSIEM 
                                      ? item.label === 'Add New SIEM' 
                                      : item.label === 'Add New Flow Sensor',
                                    className: (item.label === 'Add New SIEM' 
                                    || item.label === 'Add New Flow Sensor') ? 'link-text' : ''
                                  }))}
                                value={collectionMethod}
                                onChange={(e, val) => {
                                    onCollectionMethodSelect(e, val);
                                }}
                                getItemLabel={getItemLabel}
                                multiple={false}
                                disabled={isEdit}
                                showSeparator={true}
                            />
                        </div>
                    </div>
                </div>
                {collectionMethod && collectionMethod.length > 0 &&
                    getCollectorConfigurationComponent(collectionMethod[0]?.key)}
            </div>



            <AMModalError errors={errors} errorMap={SensorErrors} />
            <div className='sensor-footer-container'>
                <div className='launch_button'>
                    <button
                        type='button'
                        onClick={onCloseHandler}
                        className={'float_left stepper-action-btn button_grey'}
                    >
                        Cancel
                    </button>

                    <button
                        onClick={() => {
                            if (isLoaded)
                                saveConfiguration();
                        }}
                        type='button'
                        className={
                            'float_right stepper-action-btn button_styled'
                        }
                    >
                        {isLoaded ? <>
                            {isEdit ? getSaveBtnText('Save') : getSaveBtnText('Add')}</> : (<div className='loader-spinner'></div>)}

                    </button>

                </div>
            </div>
        </form>
    );
};