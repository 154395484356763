
import './donut-skeleton.scss';

export const DonutSkeleton = () => {
    return (
        <>
            <div className='skeleton-chart-container animate-pulse'>
                <div className='skeleton-title'></div>
                <div className='skeleton-content'>
                    <div className='skeleton-left'></div>
                    <div className='skeleton-right'>
                        {[...Array(3).keys()].map(p => {
                            return <div key={p} className='skeleton-rows'></div>
                        })}
                    </div>
                </div>

            </div>
        </>
    )
}
