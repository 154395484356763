import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMBreadcrumb } from '../../../components/core/AMBreadcrump/AMBreadcrumb';
import { AccessesObj, AssetsMappingObj, HostDetailObj, IdAssetWidget, IssueFilters, IssueParams, MatchedRuleData, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { convertToCommaValue, findDifferenceInDays, getExportFileName, getRiskLevelColor, getTimeDiffString, trimAfterSecondWord } from '../../../utils/util-methods';
import { AccessColumnMarkKnownOptions, AccessesActionMenu } from '../constants/IDPostureMenu';
import '../identity_asset.scss';
import { BasePostureActionMenu } from '../ref/PostureActionMenus/BasePostureActionMenu';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import { ReportByIdAsset, RuleRootWidget, SummaryWidget } from '../sub';
import RuleMatch from './rule-match/rulematch';
import MatchRule from './matchrule/matchrule';
import { Chip, Tooltip } from '@mui/material';
import '../posture_root_tooltip.css';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import { AMCountryFlag } from '../../../components/core/AMCountryFlag/AMCountryFlag';
import { IDScore } from '../../../common/IDScore/IDScore';
import { useLensSearch, usePostureAdvanceSearch, usePostureArchival } from '../ref/Hooks/Posture';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import { validateLocalIps } from '../ref/Hooks/Posture';
import Tags from '../../issue_prev/issues/tags/tags';
import { PostureTabTooltip } from '../ref/PostureTabTooltip';
import { FailedDirPopUp } from './sub/FailedDirPopUp';
import { AMCheckbox } from '../../../components/core/AMCheckbox/AMCheckbox';
import AMMultiCheckbox, { IAMMultiCheckboxMenuItem } from '../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { IPostureActionButtonsRef, PostureActionButtons, PostureArchiveTooltip } from '../ref/PostureArchive/PostureArchive';
import { IApiResponse, PostureService } from '../ref/Hooks/PostureService';
import { useAuthDetails } from '../../../components/Authorization';
import { PostureCategory } from '../ref/PostureCategories/PostureCategory';
import { DirectoryCategory } from '../ref/PostureCategories/DirectoryCategory';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import AdditionalResult from '../sub/AdditionalResult';
import { getAdditionalResultCellValue, getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';


const is4k = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;
const Accesses = ({ query, setQuery }: any) => {
    const checkFlag = false;
    const location = useLocation();
    const search = useLocation().search;
    const hard_refresh_state: boolean = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
    const [tableFetchError, setTableFetchError] = useState('No records found.');
    const [showRuleMatch, setShowRuleMatch] = useState(false);
    const [showMatchRule, setShowMatchRule] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
    const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
        ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
    const [searchInput, setSearchInput] = useState<string>('');
    const [tagFlag, setTagFlag] = useState<boolean | undefined>();

    const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
    const [filterData, setFilterData] = useState<IssueFilters | undefined>();
    const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    const {
        handleApplyAdvancedSearch,
        handleManualSearch,
        generateTagsFromSearch,
        getSearchHeaders, PartnerConfigState,
        preMetaDataHandler
    } = usePostureAdvanceSearch();
    const { PostureSearchState, PostureChartState } = usePostureStateContext();
    const { setTags, tags } = PostureSearchState;
    const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
    const [isClearAll, setIsClearAll] = useState(false);
    const [failedDir, setFailedDir] = useState<AccessesObj>();
    const [zoomLevel, setZoomLevel] = useState('hourly');
    const updatedExtremesRef = useRef({ min: null, max: null });

    const {
        selectAll,
        onSetSelectRow,
        getSelectRow,
        onSelectAllClick,
        selectAllIndeterminate,
        setArchivalData,
        setOpenIssuesCount,
        onArchive,
        selectCount,
        isIncidentsOpen,
        PostureMultiSelectCheckboxItems,
        resetSelectionState,
        setCurrentPage,
        currentPage,
        onArchiveEstimate
    } = usePostureArchival('Access');
    const {
        responseData, setResponseData,
        showAdvanceSearch, setShowAdvanceSearch,
        idWidget, setIdWidget,
        ruleWidget, setRuleWidget,
        ruleRootIdentity, setRuleRootIdentity,
        showGraph, setShowGraph,
        selectedItems, setSelectedItems,
        items, setItems,
        widgetData, setWidgetData,
        totalCount, setTotalCount
    } = PostureChartState;
    const { PostureSummary } = PostureService();
    const { authDetails } = useAuthDetails()!;
    const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
    const [selectedField, setSelectedField] = useState(null);
    const [showAdditionalResult, setShowAdditionalResult] = useState(false);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
    const { isPresetPeriodOptionSelected } = useTimeBasedSearchActions();
    const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
    const MoreActionsOptions: IPlaybookActions<Array<any>> = [
        {
            actionCallback: () => {
                if (archiveBtnRef?.current?.onArchiveBtnClick)
                    archiveBtnRef?.current?.onArchiveBtnClick()
            },
            actionId: 'mark-archive',
            actionLabel: 'Archive',
            isDisabled: selectCount == 0,
            isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
        }
    ];


    const highlightSortDir = () => {
        if (query.order_by === 'asc') {
            setCurrentSort(' tablesort_up tablesort_up_selected ');
        } else if (query.order_by === 'desc') {
            setCurrentSort(' tablesort_down tablesort_down_selected ');
        }
    };


    useEffect(() => {
        setShowAdditionalResult(true)
    }, [query.q]);

    useEffect(() => {
        let tempTags: string[] = [];
        if (param.q) {
            // tempTags = [...param.q];
            tempTags = q.split('+');
        }
        if (param.rule_name && !tempTags.includes(param.rule_name)) {
            tempTags.push(param.rule_name);
        }
        if (param.risk && !tempTags.includes(param.risk?.toString())) {
            tempTags.push(param.risk?.toString());
        }
        if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
            tempTags.push(param.d_protocol);
        }
        if (param.d_name && !tempTags.includes(param.d_name)) {
            tempTags.push(param.d_name);
        }
        if (param.hash && !tempTags.includes(param.hash)) {
            tempTags.push(param.hash);
        }
        if (
            param.s_time &&
            param.e_time &&
            !tempTags.includes(param.s_time?.toString()) &&
            !tempTags.includes(param.e_time?.toString())
        ) {
            tempTags.push(param.s_time?.toString(), param.e_time?.toString());
        }

        if (tempTags.length > 0) {
            setTags(tempTags);
            setTagFlag(false);
        }

        highlightSortDir();
        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        if (
            !history.location.pathname.includes('matchrule') &&
            !history.location.pathname.includes('flow')
        ) {

            fetchIdentities(cancelTokenSource.token);
        }
        return () => {
            cancelTokenSource.cancel('Operation canceled due to component unmounting.');
        };

    }, [query]);

    useEffect(() => {
        fetchWidget();
    }, []);

    const fetchIdentities = async (cancelToken?: CancelToken) => {
        setTableFetchError('No records found.');
        const searchHeaders = await getSearchHeaders(param);
        setLoading(true);
        Api.get('/accesses', {
            params: param,
            paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken,
            ...searchHeaders
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setTotalCount(res.data.total);
                    setResponseData(res.data);
                    setArchivalData(res.data);
                    setOpenIssuesCount(res.data.total);
                    setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
                    if (currentPage && selectCount > 0 && selectAllIndeterminate) {
                        resetSelectionState()
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.code === 'ERR_CANCELED') {
                    return
                }
                setLoading(false);
                setResponseData({ result: 'error' } as ServerResponse);
                addToast('Error while fetching data.', {
                    appearance: 'error',
                    autoDismiss: true
                });
                setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
            });
    };

    const reloadIdentities = () => {
        reloadRef.current.classList.add('reload_spin');
        param.hard_refresh = true;
        setQuery(param);

        fetchIdentities();
        outDated();
        fetchWidget();
        setTimeout(() => {
            if (reloadRef.current.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin');
            }
        }, 750);
    };

    const {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    } = query;

    const [ruleMatch, setRuleMatch] = useState<AccessesObj | undefined>();
    const [matchRuleData, setMatchRuleData] = useState<AccessesObj | undefined>();
    const [prevUrl, setPrevUrl] = useState<string>(
        window.location.pathname + window.location.search
    );
    const [lastUpdate, setLastUpdate] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [showUnsuccessAuth, setShowUnsuccessAuth] = useState(false);
    const [openFlow, setOpenFlow] = useState<boolean>(false);
    const [flowsTrendResponseData, setFlowsTrendResponseData] =
        useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({
        chartTitle: ''
    });

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    let history = useHistory();
    const { addToast } = useToasts();
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    let param: IssueParams = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    };
    const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);

    useEffect(() => {
        const directory = tags.find((item: string) =>
            item.includes('num_of_auth_failed_flow')
        );
        if (directory) {
            setShowUnsuccessAuth(true);
        } else {
            setShowUnsuccessAuth(false);
        }
    }, [tags]);

    const LoadSorting = (field: string) => {
        if (param.sort_by === field) {
            param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
            setQuery(param);
        } else {
            param.sort_by = field;
            param.order_by = 'desc';
            setQuery(param);
        }
    };

    const handleSort = (e: React.MouseEvent) => {
        const col = e.currentTarget.getAttribute('data-headerName');
        setSelectedColumn(col);
        LoadSorting(col || '');
    };



    const getChartTitle = (identityName: string, assetName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Access: ${identityName} → ${assetName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)})  for Access: ${trimAfterSecondWord(
                    identityName + ' → ' + assetName,
                    35
                )}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Access: ${identityName} → ${assetName}`}
                >{`Hourly flows trend for Access: ${trimAfterSecondWord(
                    identityName + ' → ' + assetName,
                    35
                )}`}</span>
            );
        }
    };

    // const handleFlowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    //     setOpen(true);
    //     let issueId = e.currentTarget.id?.toString();
    //     let flows = responseData?.result.find((item: AccessesObj) => item._id === issueId);
    //     setIssueFlow(flows);
    //     history.push("/accesses/flow")
    // }

    function getSearchFields() {
        let timeFilter = appliedFilterTimestamp();
        if (timeFilter.length > 0) {

            let startDate = moment(timeFilter[0]).unix();
            let endDate = moment(timeFilter[1]);

            if (endDate.isAfter(moment())) {
                endDate = moment().unix();
            } else {
                endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
            }

            // Calculate the time difference in hours
            let diffInHours = (endDate - startDate) / 3600;

            // Determine the interval based on the difference
            let interval;
            if (diffInHours < 12) {
                interval = '1 MINUTE';
                setZoomLevel('1-minute');
            } else if (diffInHours < 48) {
                interval = '10 MINUTE';
                setZoomLevel('10-minute');
            } else {
                interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
                setZoomLevel('hourly');
            }

            let searchFields = {
                "any_activity_time": {
                    "value": [startDate, endDate],
                    "type": "between"
                },
                "interval": interval
            };

            // Convert to JSON and encode
            return encodeURIComponent(JSON.stringify(searchFields));

        }
        return null; // Return null or an empty string if timeFilter is empty
    }


    function getSearchFieldsForDate(min, max) {


        let startDate = moment(min).unix();
        let endDate = moment(max);

        if (endDate.isAfter(moment())) {
            endDate = moment().unix();
        } else {
            endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
        }

        // Calculate the time difference in hours
        let diffInHours = (endDate - startDate) / 3600;

        // Determine the interval based on the difference
        let interval;
        if (diffInHours < 12) {
            interval = '1 MINUTE';
            setZoomLevel('1-minute');
        } else if (diffInHours < 48) {
            interval = '10 MINUTE';
            setZoomLevel('10-minute');
        } else {
            interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
            setZoomLevel('hourly');
        }

        let searchFields = {
            "any_activity_time": {
                "value": [startDate, endDate],
                "type": "between"
            },
            "interval": interval
        };



        // Convert to JSON and encode
        return encodeURIComponent(JSON.stringify(searchFields));



    }


    const cancelTokenSourceRef = useRef<any>(null);

    const handleRangeChange = (min: any, max: any) => {

        const searchHeaders = {
            headers: {
                search_fields: getSearchFieldsForDate(min, max)
            }
        };

        updatedExtremesRef.current = { min, max };


        const currentTime = +new Date();

        const data = JSON.parse(sessionStorage.getItem('flowDataAccess'));


        const encodedIdentityName = encodeURIComponent(data.identityName);
        const encodedAssetName = encodeURIComponent(data.assetName);

        const dirParams = `&directory_name=${encodeURIComponent(data.directory_name)}&is_directory_public=${encodeURIComponent(data.is_directory_public)}`;
        const flowTrendURL = `/assets/flowcounts?search_type=access&search_value1=${encodedIdentityName}&search_value2=${encodedAssetName}${dirParams}&asset_type=${encodeURIComponent(data.asset_type)}&identity_type=${encodeURIComponent(data.identity_type)}`;



        let queryData = `identity_name:${encodedIdentityName}+asset_name:${encodedAssetName}`;

        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for the new request
        cancelTokenSourceRef.current = axios.CancelToken.source();

        try {

            Api.get(flowTrendURL, searchHeaders)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        // const chartTitle = getChartTitle(identityName);
                        // setFlowsChartDetails({
                        //     chartTitle, itemData: {
                        //         queryData: `identity_name:${encodedIdentityName}`
                        //     }
                        // });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);


                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        data?.identityName,
                        data.assetName,
                        min,
                        max,

                    ) : max ? getChartTitle(
                        data?.identityName,
                        data?.assetName,
                        min,
                        max

                    ) : getChartTitle(
                        data?.identityName,
                        data?.assetName,
                        min

                    );
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `identity_name:${encodedIdentityName}`
                        }
                    });


                })
                .catch((er) => {
                    console.log(er);
                });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled.');
            } else {
                console.log(error);
            }
        }
    }

    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let {
            id: uniqueId,
            identityName,
            assetName,
            is_directory_public,
            directory_name = '',
            asset_type,
            identity_type
        } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `access-flow-trend-${uniqueId}-${asset_type}`
        );

        const encodedIdentityName = encodeURIComponent(identityName);
        const encodedAssetName = encodeURIComponent(assetName);

        const dirParams = `&directory_name=${encodeURIComponent(directory_name)}&is_directory_public=${encodeURIComponent(is_directory_public)}`;
        const flowTrendURL = `/assets/flowcounts?search_type=access&search_value1=${encodedIdentityName}&search_value2=${encodedAssetName}${dirParams}&asset_type=${encodeURIComponent(asset_type)}&identity_type=${encodeURIComponent(identity_type)}`;

        sessionStorage.setItem('flowDataAccess', JSON.stringify(data));

        let queryData = `identity_name:${encodedIdentityName}+asset_name:${encodedAssetName}`;
        // if (directory_name !== '') {
        //     queryData += `+directory_name=${directory_name}`
        // }


        const searchHeaders = {
            headers: {
                search_fields: getSearchFields()
            }
        };
        Api.get(flowTrendURL, searchHeaders)
            .then((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({
                        flows: [],
                        expiry_time: currentTime + 600000
                    });

                    // Chart header and color
                    // const chartTitle = getChartTitle(
                    //     identityName,
                    //     assetName
                    // );




                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData
                        }
                    });

                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) =>
                    a?.time < b?.time ? -1 : 1
                );
                const cachedFlows = {
                    flows: result,
                    identityName,
                    risk,
                    expiry_time: currentTime + 600000
                };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(
                    moment(result?.[0]?.time).valueOf(),
                    moment().subtract(90, 'days').valueOf()
                );
                // const chartTitle = getChartTitle(
                //     identityName,
                //     assetName,
                //     startFromDate
                // );

                const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                    identityName,
                    assetName,
                    appliedFilterTimestamp()[0],
                    appliedFilterTimestamp()[1],

                ) : getChartTitle(
                    identityName,
                    assetName,
                    startFromDate
                );
                setFlowsChartDetails({
                    chartTitle, itemData: {
                        queryData
                    }
                });

                sessionStorage.setItem(
                    `access-flow-trend-${uniqueId}-${asset_type}`,
                    JSON.stringify(cachedFlows)
                );
            })
            .catch((er) => {
                console.log(er);
            });
    };

    useEffect(() => {
        if (!openFlow) {
            setFlowsTrendResponseData(undefined);
        }
    }, [openFlow]);

    const handleIssueFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        let idx = e.currentTarget.id?.toString();
        let hostDetails = responseData?.result.find(
            (item: AccessesObj) => item._id === idx
        );
        const isDirectory = hostDetails.is_flow_for_directory;
        let currHostDetails = hostDetails.asset_host_detail;
        const s_Type = hostDetails?.identity_type;
        const d_type = hostDetails?.asset_type;
        let assetsRef = '';
        currHostDetails.map((item: any, index: any) => {
            if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
        });

        let dir_host = hostDetails?.directory_hostname;

        if (dir_host.includes(',')) {
            dir_host = `"${dir_host}"`;
        }

        let host_id = hostDetails.identity

        if (host_id.includes(',')) {
            host_id = `"${host_id}"`;
        }

        PostureSearchState.setIsAdSearchApplied(false);

        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

        if (timeSearchParam === "") {
            if (isDirectory) {
                if (dir_host) {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(dir_host) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type,
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                } else {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(hostDetails?.asset) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type,
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                }
            } else {
                history.push(
                    '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                    encodeURIComponent(hostDetails.identity) +
                    '%2Bd_type:' +
                    d_type +
                    '%2Bd_name:' +
                    encodeURIComponent(hostDetails?.asset) +
                    `%2Brule_name^Shadow External Access` +
                    '%2Bstatus:Open%2Bs_type:' +
                    s_Type,
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'AccessIncidents',
                        prevUrl,
                        prevSearch
                    }
                );
            }

        } else {
            if (isDirectory) {
                if (dir_host) {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(dir_host) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                } else {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(hostDetails?.asset) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                }
            } else {
                history.push(
                    '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                    encodeURIComponent(hostDetails.identity) +
                    '%2Bd_type:' +
                    d_type +
                    '%2Bd_name:' +
                    encodeURIComponent(hostDetails?.asset) +
                    `%2Brule_name^Shadow External Access` +
                    '%2Bstatus:Open%2Bs_type:' +
                    s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'AccessIncidents',
                        prevUrl,
                        prevSearch
                    }
                );
            }
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const getExceptionData = (item: AccessesObj) => {
        return [
            `d_name:${item.asset}`,
            `dst_hostnames:` +
            item.asset_host_detail
                ?.map((item: any) => item.hostname)
                .join(',') || '',
            `dst_ips:` +
            item.asset_host_detail?.map((item: any) => item.ip).join(',') ||
            '',
            // `src_ips:` + item.identity_host_detail?.map((item: any) => item.ip).join(',') || '',
            // `src_hostnames:` + item.identity_host_detail?.map((item: any) => item.hostname).join(',') || '',
            `s_name:${item.identity}`,
            `rule_name:` + getRules(item)
        ];
    };

    const getIncCloseQuery = (item: AccessesObj) => {
        return (
            `{"is_external":"false","s_name":"` +
            item.identity +
            `","d_protocol":"` +
            item.asset_protocol +
            `","d_name":"` +
            item?.asset +
            `","status":"Open"}`
        );
    };

    const getRules = (item: AccessesObj) => {
        let rule = '';
        Object.values(item.rules).map((i: RulesDetailObj) => {
            if (i.issue_count > 0) {
                rule += i.name + ',';
            }
        });
        return rule.substring(0, rule.length - 1);
    };

    const loadBasePostureActionProps = (item: AccessesObj) => {
        item.host_detail = [
            ...(item?.asset_host_detail?.length ? item?.asset_host_detail : []),
            ...(item?.identity_host_detail?.length
                ? item?.identity_host_detail
                : [])
        ] as any;
        return {
            actionData: { ...item, _id: item.asset },
            menuItems:
                item.issue_count > 0
                    ? AccessesActionMenu
                    : AccessesActionMenu.filter(
                        (item: PostureMenuItem) =>
                            item.action == PostureMenuItemAction.MARK_KNOWN
                    ),
            getContent: () => [
                `d_name:${item.asset}`,
                `s_name:${item.identity}`
            ],
            exceptionData: getExceptionData(item),
            classes: 'posture-column action_button',
            incContent: () => [
                `inc_count:${item.issue_count}`,
                `s_name:${item.identity}`,
                `d_name:${item.asset}`
            ],
            closeIncQuery: getIncCloseQuery(item),
            issueCount: item.issue_count,
            reloadList: fetchIdentities,
            markKnownOptions: {
                options: AccessColumnMarkKnownOptions
            },
            keyName: `access-${item._id}`,
            showId: rule_name
        };
    };

    const handleMouseEnterHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.parentElement.parentElement.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('show-block');
            ele.classList.remove('hidden-block');
        }
    };

    const handleMounseLeaveHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('hidden-block');
            ele.classList.remove('show-block');
        }
    };

    const handleFilterWidget = (widgetState: any) => {
        if (filterData) {
            setOpenPanelFilterWidgets(widgetState);
        } else {
            addToast(
                'Unable to apply filters, please retry in few minutes or contact administrator.',
                { appearance: 'error' }
            );
        }
    };

    const handleFilterDisabled = (widgetState: any) => {
        setDisabledFilter(widgetState);
        // reloadIssuesDisabled(widgetState);
    };

    const onCloseAdvanceSearch = useCallback(() => {
        setShowAdvanceSearch(false);
    }, []);

    const applySearchHandler = useCallback(
        (data: any, clearAll: boolean = false, deletedKeys = []) => {
            let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
            let filterObj = {};
            for (let i in tempFilterObj) {
                if (deletedKeys.indexOf(i) == -1)
                    filterObj[i] = tempFilterObj[i];
            }

            filterObj = { ...filterObj, ...data }

            handleApplyAdvancedSearch(
                filterObj,
                clearAll,
                setIsClearAll,
                setTags,
                setTagFlag,
                ENTITIES.ACCESSES.pageType
            );
            resetSelectionState();
        },
        [tags, q]
    );

    const applyTimeBasedSearchHandler = ((data, clear) => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (clear) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                delete filterObj[TIME_BASED_SEARCH_FIELD];
                clear = false;
            }
            clear = Object.keys(filterObj).length === 0 ? true : false;
        } else {
            filterObj = { ...filterObj, ...data }
        }
        handleApplyAdvancedSearch(
            filterObj,
            clear,
            setIsClearAll,
            setTags,
            setTagFlag,
            ENTITIES.IDENTITY.pageType
        );
    })

    const onAdvanceSearchClick = () => {
        setShowAdvanceSearch(true);
    }

    useEffect(() => {
        handleManualSearch(tags, ENTITIES.ACCESSES.pageType)
    }, [tags])

    const handleFailedDirClick = (item: AccessesObj) => {
        if (item?.failed_auth_list && item?.failed_auth_list?.length > 0) {
            setFailedDir(item)
        }
    }

    const handleAddtionalResultColumn = (val) => {
        setShowAdditionalResult(val);
        if (additionalResultHighlightClass === "")
            setAdditionalResultHighlightClass("glowing-animation");
    }

    useEffect(() => {
        if (additionalResultHighlightClass !== "" && showAdditionalResult) {
            setTimeout(() => {
                setAdditionalResultHighlightClass("");
            }, 15000)
        }
    }, [additionalResultHighlightClass])

    useEffect(() => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (filterObj[TIME_BASED_SEARCH_FIELD]) {
            setIsTimeBasedSearchApplied(true);
        } else {
            setIsTimeBasedSearchApplied(false);
        }
    }, [tags])


    const getItemIconClass = (item) => {
        switch (item.identity_type) {
            case "Device":
                return "device_icon";
            case "App":
                return "device_icon";
            case "Service/Application":
                    return item?.identity_category_list && item?.identity_category_list.length > 0 ?  "cloud_service_application_account_icon": "service_application_account_icon";
            case "Service/Computer Account":
                    return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_computer_account_icon" : "service_computer_account_icon";
            case "Service/Key and Secret":
                    return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_key_secret__icon" : "service_key_secret__icon";  
            case "Service/Service Account":
                    return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_account_icon": "service_service_account_icon";
            case "Service/Service Principal":
                    return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_principal_icon" : "service_service_principal_icon";
            case "Service/Token":
                        return  item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_token_icon"  :"service_service_token_icon";
            case "User/Agent":
                return "user_agent_icon";
            case "User/Background Browsing":
                return "user_browsing_icon";
            case "User":
                return "user_interactive_icon";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
        }
    }

    const getDestinationItemIconClass = (item) => {
        switch (item.asset_type) {
            case "Device":
                return "device_icon";
            case "App":
                return "service_application_account_icon";
            case "Service":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "gear_icon_cloud" : "gear_icon_issue";
            case "Service/Application":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_application_account_icon" : "service_application_account_icon";
            case "Service/Computer Account":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_computer_account_icon" : "service_computer_account_icon";
            case "Service/Service Account":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_service_account_icon" : "service_service_account_icon";
            case "Service/Service Principal":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_service_principal_icon" : "service_service_principal_icon";
            case "Service/Key and Secret":
                    return item?.asset_category_list && item.asset_category_list?.length > 0  ? "cloud_service_key_secret__icon" : "service_key_secret__icon";
            case "Service/Token":
                    return item?.asset_category_list && item.asset_category_list?.length > 0  ? "cloud_service_service_token_icon" : "service_service_token_icon";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
        }
    }

    const getItemIconTitle = (item) => {
        switch (item?.identity_type) {
            case "Device":
                return "Device";
            case "App":
                return "App";
            case "Service":
                return "Service";
            case "Service/Application":
                return !item.is_known_identity ? "Unresolved Service/Application" : "Service/Application";
            case "Service/Computer Account":
                return !item.is_known_identity ? "Unresolved Service/Computer Account" : "Service/Computer Account";
            case "Service/Service Account":
                return !item.is_known_identity ? "Unresolved Service/Service Account" : "Service/Service Account";
            case "Service/Service Principal":
                return !item.is_known_identity ? "Unresolved Service/Service Principal" : "Service/Service Principal";
            case "Service/Key and Secret":
                return !item.is_known_identity ? "Unresolved Service/Key and Secret" : "Service/Key and Secret";
            case "Service/Token":
                return !item.is_known_identity ? "Unresolved Service/Token" : "Service/Token";
            case "User/Agent":
                return !item.is_known_identity ? "Unresolved User/Agent" : "User/Agent";
            case "User/Background Browsing":
                return !item.is_known_identity ? "Unresolved User/Background Browsing" : "User/Background Browsing";
            case "User":
                return !item.is_known_identity ? "Unresolved User" : "User";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return 'N/A';
        }

    }


    const getItemDestinationTitle = (item) => {
        switch (item?.asset_type) {
            case "Device":
                return "Device";
            case "App":
                return "App";
            case "Service":
                return "Service";
            case "Service/Application":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Application" : "Service/Application";
            case "Service/Computer Account":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Computer Account" : "Service/Computer Account";
            case "Service/Service Account":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Service Account" : "Service/Service Account";
            case "Service/Service Principal":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Service Principal" : "Service/Service Principal";
            case "Service/Key and Secret":
                    return item?.asset_category_list && item.asset_category_list?.length > 0  ? "Cloud Service/Key and Secret" : "Service/Key and Secret";
            case "Service/Token":
                    return item?.asset_category_list && item.asset_category_list?.length > 0  ? "Cloud Service/Token" : "Service/Token";
            
            case "User/Agent":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Agent" : "User/Agent";
            case "User/Background Browsing":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Background Browsing" : "User/Background Browsing";
            case "User":
                return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User" : "User";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return 'N/A';
        }

    }

    const clickIcon = (item)=>{
        setShowMatchRule(true);
        setMatchRuleData(item);
    }

    const renderData = (response: any) => {
        return response.map((item: AccessesObj) => {
            let id_hostsList: any = [];
            let asset_hostsList: any = [];
            {
                item.identity_host_detail && item.identity_host_detail.map((group: any) => {
                    if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null) {
                        const hostname = group.hostname.startsWith('127.') ? 'N.A.' : group.hostname;
                        if (item.identity_host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                            id_hostsList.push({
                                "hostname": hostname,
                                "city_name": group.city_name,
                                "country_code2": group.country_code2,
                                "country_name": group.country_name,
                                "ip": group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip,
                                "state_name": group.state_name
                            })
                        }
                        else {
                            if (group.hostname?.toLowerCase() !== "unknown")
                                id_hostsList.push({
                                    "hostname": hostname,
                                    "city_name": group.city_name,
                                    "country_code2": group.country_code2,
                                    "country_name": group.country_name,
                                    "ip": group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip,
                                    "state_name": group.state_name
                                })
                        }
                    }
                })
            }

            {
                item.asset_host_detail && item.asset_host_detail.map((group: any) => {
                    if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null &&
                        group.ip_port !== "" && group.ip_port !== undefined && group.ip_port !== null) {
                        const ip_port = group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip_port;
                        const hostname = group.hostname.startsWith('127.') ? 'N.A.' : group.hostname;
                        if (item.asset_host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                            asset_hostsList.push({ "hostname": hostname, "ip_port": ip_port })
                        }
                        else {
                            if (group.hostname?.toLowerCase() !== "unknown")
                                asset_hostsList.push({ "hostname": hostname, "ip_port": ip_port })
                        }
                    }
                })
            }

            return (
                <React.Fragment>
                    <tr key={item._id?.toString()}>
                        {
                            authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
                            <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 25 : 25, padding: '0px' }}>
                                <AMCheckbox indeterminate={false} onClick={onSetSelectRow.bind(this, item)} checked={getSelectRow(item)}
                                    disabled={false} /></td>
                        }
                        <td className={'access-dot'} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 330 : 330, padding: "0", border: "none!important", position: "relative", borderRight: "none" }}>
                            <tr className='dot_outer'>
                                <td style={{ borderBottom: "none", borderRight: "none" }}>
                                    <div className="copy_field copy_host_count" style={{ minWidth: '290px' }}>
                                        <Tooltip
                                            classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                                            title={
                                                <div className="service_list">

                                                    {item.identity_full_name?.toLowerCase() !== item.identity?.toLowerCase() ?
                                                        <div>
                                                            <h2 style={{ marginBottom: 0 }} >
                                                                <span className="ellipsis_idassetname tooltip_ellipses" title={item.identity_full_name?.toString()}>{item.identity_full_name?.toString()}</span>
                                                                <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity_full_name)}></div>
                                                            </h2>
                                                            <h2 style={{ marginTop: 0, paddingTop: '6px' }} >
                                                                <span className="ellipsis_idassetname" title={item.identity?.toString()}>{item.identity?.toString()}</span>
                                                                <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
                                                            </h2>
                                                        </div>
                                                        : <h2>
                                                            <span className="ellipsis_idassetname tooltip_ellipses" title={item.identity?.toString()}>{item.identity?.toString()}</span>
                                                            <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
                                                        </h2>}



                                                    {/* <h2>
                                                <span className="ellipsis_idassetname">{item.identity?.toString()}</span>
                                                <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
                                            </h2> */}
                                                    {id_hostsList.length > 0 ?
                                                        <table>
                                                            <tr>
                                                                <th className="align_left">Hosts</th>
                                                            </tr>
                                                            {id_hostsList.map((group: any, index: any) => {
                                                                if (index < 5) {
                                                                    return (<tr
                                                                        key={group.hostname + '-' + index}
                                                                        onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}
                                                                    >
                                                                        <td className="hostname_td align_left display-flex-column" onMouseOver={(e) => handleMouseEnterHost(index, group.hostname, e)} onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}>
                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                            >
                                                                                {group.country_code2 ?
                                                                                    <span className="flag_wrapper"
                                                                                    >
                                                                                        {group.country_name ?
                                                                                            <div className="flag_icon" style={{ position: 'relative' }}>
                                                                                                <AMCountryFlag countryCode={group.country_code2} />
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </span>
                                                                                    :
                                                                                    <span className="flag_wrapper">
                                                                                        {group.country_name ?
                                                                                            <div className="location_icon">
                                                                                            </div>
                                                                                            :
                                                                                            <div className="flag_icon" style={{ position: 'relative' }}>
                                                                                                <div className='no_flag'></div>
                                                                                            </div>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                                <span
                                                                                    className="ellipsis_idassetname margin_left30">{group.hostname}</span>
                                                                                <div style={{ flex: '1' }}>
                                                                                    <div className="copy_idassetname"
                                                                                        style={{ position: 'relative', flex: 1, marginLeft: 0, float: 'right' }}
                                                                                        title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={`hidden-block  paddingLeft20 host-name-list-${index}-${group.hostname}`} >
                                                                                {group.hostname ? <li>
                                                                                    <strong>Hostname</strong>: {group.hostname}</li> : null}
                                                                                {group.country_name ? <li><strong>Country</strong>: {group.country_name}</li> : null}
                                                                                {group.state_name ? <li><strong>State</strong>: {group.state_name}</li> : null}
                                                                                {group.city_name ? <li><strong>City</strong>: {group.city_name}</li> : null}
                                                                                {group.ip ? <li><strong>Ip</strong>: {group.ip}</li> : null}
                                                                            </div>

                                                                        </td>
                                                                    </tr>)
                                                                }
                                                            })}
                                                            {id_hostsList.length > 5 ?
                                                                <tr>
                                                                    <td className="hostname_td align_left">
                                                                        <span className="ellipsis_idassetname">...</span>
                                                                    </td>
                                                                </tr> : null}
                                                        </table>
                                                        : null
                                                    }
                                                </div>
                                            }>
                                            <span className={((item.is_known_identity === false && item?.identity_type === "User") ?
                                                "ellipsis_access_host droplist" :
                                                "ellipsis_access droplist access-page") + (id_hostsList.length <= 1 && item?.identity_type === "User" ? ' ellipsis_acces_host_ext' : ' ')}
                                            >
                                                <span style={{ display: 'inline-block', maxWidth: window.matchMedia("(min-width: 2560px)").matches ? '260px' : '260px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.identity}</span>
                                                <span><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} /></span>
                                            </span>
                                        </Tooltip>
                                        <div className="copy_access" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
                                        {(item.is_known_identity === false && item?.identity_type === "User" && id_hostsList.length > 1) ? <span className="host_count">
                                            <i>(on multiple hosts)</i></span> : null}
                                    </div>

                                </td>
                                <td style={{ borderBottom: "none", borderRight: "none" }} className="td_col_width ">
                                    <PostureCategory categories={item.identity_category_list || []} title={getItemIconTitle(item)}
                                        type={item.identity_type}
                                       
                                    >
                                        <div className={getItemIconClass(item)}
                                        >
                                            {(item.is_known_identity === false && (item?.identity_type === "User" || item?.identity_type === "User/Agent" || item?.identity_type === "User/Background Browsing")) ? <span>?</span> : null}
                                            {(item.is_known_identity === false && (item?.identity_type === "Service" || item?.identity_type === "Service/Application" ||  item?.identity_type === 'Service/Computer Account' || item?.identity_type === 'Service/Key and Secret' || item?.identity_type === 'Service/Token' || item?.identity_type === 'Service/Service Principal' || item?.identity_type === 'Service/Service Account')) ? <span>?</span> : null}
                                        </div>
                                    </PostureCategory>
                                    <span className='connect_arrow'></span>
                                </td>
                            </tr>
                        </td>
                        <td className={'access-dot'}
                            style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 330 : 330, padding: "0", border: "none!important" }}>
                            <tr className='dot_outer'>
                                <td style={{ borderBottom: "none", borderRight: "none", position: 'relative' }}>
                                    <div className="copy_field width100">
                                        <Tooltip
                                            classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                                            title={
                                                <div className="service_list2">
                                                    <h2>
                                                        <span className="ellipsis_idassetname tooltip_ellipses" title={item.asset?.toString()}>{item.asset?.toString()}</span>
                                                        <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.asset)}></div>
                                                    </h2>
                                                    {asset_hostsList.length > 0 ?
                                                        <table>
                                                            <tr>
                                                                <th className="align_left">Hostname</th>
                                                                <th className="align_left">IP:Port</th>
                                                            </tr>
                                                            {asset_hostsList.map((group: any, index: any) => {
                                                                if (index < 5) {
                                                                    return (<tr>
                                                                        <td title={group.hostname} className="hostname_td align_left">
                                                                            <span className="ellipsis_idassetname">{group.hostname}</span>
                                                                            <div className="copy_idassetname float-right-margin-none" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                                                        </td>
                                                                        <td className="align_left">{group.ip_port}</td>
                                                                    </tr>)
                                                                }
                                                            })}
                                                            {asset_hostsList.length > 5 ?
                                                                <tr>
                                                                    <td colSpan={2} className="hostname_td align_left">
                                                                        <span className="ellipsis_idassetname">...</span>
                                                                    </td>
                                                                </tr> : null}
                                                        </table>
                                                        : null
                                                    }
                                                </div>
                                            }>
                                            <div className="ellipsis_access_asset droplist align_left">
                                                <span style={{ display: 'inline-block', maxWidth: window.matchMedia("(min-width: 2560px)").matches ? '250px' : '250px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.asset}</span>
                                                <span><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} /></span>
                                            </div>
                                        </Tooltip>

                                        <div className="copy_access_asset" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.asset)}></div>
                                    </div>
                                </td>
                                <td className="dir-icon-img" style={{ borderBottom: "none", borderRight: "none", position: 'relative', width: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }}>

                                    {item.is_directory_present ?
                                        item.directory_flow_status === "success" ?
                                            <div className={"dir_icon_green " + (item?.directory_category_list &&
                                                (item?.directory_category_list?.length > 0 || item?.is_directory_public) && ' dir_icon_green_cloud')} style={{ right: '5px' }}>
                                                <div className="titletip">
                                                    {(item?.directory_category_list?.length > 0 || item?.is_directory_public) ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                                                    <DirectoryCategory categories={item.directory_category_list || []} />
                                                    {/* Type: {item.asset_type} <br/>
                                                Protocol: {item.asset_protocol} <br/>
                                                Port: {item.asset_port} <br/> */}
                                                </div>
                                            </div>
                                            :
                                            item.directory_flow_status === "fail" ?
                                                <div className={
                                                    "dir_icon_" + (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? 'red' :
                                                        Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                                            Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'yellow' : 'grey') + ' ' +
                                                    (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? '' :
                                                        Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                                            Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'risk_2' : 'risk_0') +
                                                    ((item?.directory_category_list && item?.directory_category_list.length > 0 || item?.is_directory_public) ? ' dir_icon_' + (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? 'red' :
                                                        Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                                            Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'yellow' : '') + '_cloud' : '')
                                                }
                                                    style={{
                                                        cursor: item?.failed_auth_list && item?.failed_auth_list.length > 0 ? 'pointer' : '', right: '5px'
                                                    }}
                                                    onClick={() => handleFailedDirClick(item)}
                                                >
                                                    <div className="titletip">
                                                        {item?.failed_auth_list?.length ?
                                                            <span>{item?.directory_category_list?.length > 0 ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                                                                Fail Count: {item.directory_flow_fail_count} <br />
                                                            </span> : <span>No recent failed authentications.</span>
                                                        }
                                                        <DirectoryCategory categories={item.directory_category_list || []} />
                                                    </div>
                                                </div>
                                                :
                                                item.directory_flow_status === "unknown" ?
                                                    <div className={"dir_icon_grey " + (item?.directory_category_list &&
                                                        (item?.directory_category_list?.length > 0 || item?.is_directory_public) && ' dir_icon_grey_cloud')} style={{ right: '5px' }}>
                                                        <div className="titletip">
                                                            {(item?.directory_category_list?.length > 0 || item?.is_directory_public) ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                                                            <DirectoryCategory categories={item.directory_category_list || []} />
                                                            {/* Type: {item.asset_type} <br/>
                                                Protocol: {item.asset_protocol} <br/>
                                                Port: {item.asset_port} <br/> */}
                                                        </div>

                                                    </div>
                                                    :
                                                    <div></div>
                                        : null
                                    }
                                </td>
                                <td
                                    style={{ position: "relative", borderBottom: "none", borderRight: "none" }}
                                    className="td_col_width">
                                    <PostureCategory categories={item.asset_category_list || []} title={getItemDestinationTitle(item)}
                                        type={item.asset_type}
                                    >
                                    

                                 <div className='asset_type_icons'>
                                    <div className={getDestinationItemIconClass(item)} >
                                        {(item.asset_is_known === false && (item?.asset_type === "User" || item?.asset_type === "User/Agent" || item?.asset_type === "User/Background Browsing") ) ? <span>?</span> : null}
                                        {(item.asset_is_known === false && (item?.asset_type === "Service" || item?.asset_type === "Service/Application" ||  item?.asset_type === 'Service/Computer Account' || item?.asset_type === 'Service/Key and Secret' || item?.asset_type === 'Service/Token' || item?.asset_type === 'Service/Service Principal' || item?.asset_type === 'Service/Service Account')) ? <span>?</span> : null}
                                    </div>
                                </div>
                                    </PostureCategory>
                                    <BasePostureActionMenu isTimeBasedSearchApplied={isTimeBasedSearchApplied} {...loadBasePostureActionProps(item)} />
                                </td>
                            </tr>
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 65 : 65 }}>
                            <div className="ellipsis_name_accesses" title={item.asset_protocol} id={item._id.toString()}>
                                {item.asset_protocol}
                            </div>
                        </td>


                        {/* <td  style={{width:window.matchMedia("(min-width: 2560px)").matches ? 110 : 55, padding:"0", border:"none!important"}}>
                            <tr className='dot_outer'>

                                <td style={{"width":"5%", minWidth:window.matchMedia("(min-width: 2560px)").matches ? 60 : 30, borderBottom:"none"}}
                                    id={item._id?.toString()}
                                    data-rulename={item.rules.unknown_access.name}
                                    className={item.rules.unknown_access.issue_count===0? "" : "cursor_pointer"}
                                    onClick={item.rules.unknown_access.issue_count===0? ()=>{} : handleRuleFilterClick}
                                >
                                    <div className="">
                                        {item.rules.unknown_access.issue_count===0? <span className='dot_rule_hide'>&bull;</span>:<span className='dot_rule' style={{color:getRiskLevelColor(item.unknown_access)}}>&bull;
                                            <div className='hover_data'>
                                               <div className='dot_rule_count' style={{backgroundColor:getRiskLevelColor(item.unknown_access)}} >{item.rules.unknown_access.issue_count}</div>
                                               <div className='dot_rule_text'>Incidents</div>
                                            </div>
                                        </span>}
                                    </div>
                                </td>
                                
                            </tr>
                        </td> */}

                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70 }}>
                            {item.flow_count === 0 ?
                                <div>1</div>
                                : (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
                                    <div className={`${item.flow_count > 1 && 'shadowbox'}`} id={item._id?.toString()}
                                        onClick={(event) => { if (item.flow_count > 1) { handleFlowClick(event, { id: item._id, identity_type: item.identity_type, is_directory_public: item?.is_directory_public, identityName: item.identity?.toString(), directory_name: item?.directory_hostname, assetName: item.asset?.toString(), risk: undefined, asset_type: item.asset_type }) } }}
                                    >
                                        {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div> :
                                    <div id={item._id?.toString()}>
                                        {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                            }
                           
                        </td>
                        {
                            showUnsuccessAuth ?
                                <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 78 : 78 }}>
                                    {
                                        <div className="" id={item.auth_failed_percent}>
                                            {Number(item?.auth_failed_percent).toFixed(1)} %
                                        </div>
                                    }
                                </td> : null
                        }
                        {/* <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 128 : 58 }}>
                            {item.rules_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={handleRuleMatchClick} id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.rules_count)}</span>
                                </div>
                                :
                                <div id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.rules_count)}</span>
                                </div>
                            }
                        </td> */}
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : 60 }}>
                            {item.issue_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={handleIssueFilterClick} id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.issue_count)}</span>
                                </div>
                                :
                                <div id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.issue_count)}</span>
                                </div>
                            }
                        </td>
                        {showAdditionalResult && <td className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }}>
                            {getAdditionalResultCellValue(item, selectedField, ENTITIES.ACCESSES.pageType)}
                        </td>
                        }
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 135 : 135 }}>
                            <div style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 135 : 135, margin: "0 auto" }}>
                                {moment(item.latest_time).format('MMM DD YYYY, hh:mm A')}
                            </div>
                            <div className='shadowbox ' onClick={()=>clickIcon(item)} style={{margin:'10px auto 10px', width:'110px',float:'none'}}>More Details</div>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 40 : 40 }}>
                            <IDScore data={item} />
                        </td>
                    </tr >
                </React.Fragment >
            );
        });
    }

    const handlePaginate = (selectedPage: any) => {
        param.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(param)
    }

    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleSearchFn(e, tags)
    }

    const handleLensSearch = () => {
        handleLensSearchFn(tags);
    }


    useEffect(() => {
        if (tagFlag && tags.length >= 0 || isClearAll) {
            param.page = 1;
            param.q = tags.join("+");
            param.d_name = undefined;
            param.risk = undefined;
            param.d_protocol = undefined;
            param.hash = undefined;
            param.e_time = undefined;
            param.s_time = undefined;
            param.rule_name = undefined;
            setQuery(param);
            setIsClearAll(false); // reset clearall status.
            setTagFlag(false);
        }
    }, [tags, tagFlag])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }

    const deleteTag = (index: number) => {
        const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
        let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
        if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
            newTags = [...newTags, ...anyActivityTimeTag];
        }

        setTags(newTags);

        setTagFlag(true);
        PostureSearchState.setIsAdSearchApplied(false);
    }


    const compareElements = (a: any, b: any) => {
        if (a.element_count > b.element_count) {
            return -1;
        }
        if (a.element_count < b.element_count) {
            return 1;
        }
        return 0;
    }

    const fetchWidget = () => {
        setLoading(true);
        const queryParams = {
            ...(hard_refresh_state && { 'hard_refresh': 'true' })
        }

        PostureSummary.GET(queryParams, (res: IApiResponse) => {
            setLoading(false);
            if (res.status === 200) {
                setWidgetData(undefined);
                setTimeout(() => setWidgetData(res.data))
                res.data.access_summary.sort(compareElements)
                let n = 5
                if (res.data.access_summary.length < n) {
                    n = res.data.access_summary.length
                }
                let slicedArray = res.data.access_summary.slice(0, n);
                if (res.data.access_summary.length > n) {
                    let extraArray = res.data.access_summary.slice(n, res.data.access_summary.length);
                    let extra = {
                        element_count: 0,
                        element_count_internal: 0,
                        element_count_with_issues: 0,
                        element_count_with_issues_internal: 0,
                        type: "Other Accesses"
                    }
                    // extra.element_count = 0
                    // extra.element_count_internal = 0
                    // extra.element_count_with_issues = 0
                    // extra.element_count_with_issues_internal = 0
                    // extra.type = "Other Accesses"
                    let element_count = 0,
                        element_count_internal = 0,
                        element_count_with_issues = 0,
                        element_count_with_issues_internal = 0;
                    extraArray.map((item: any) => {
                        element_count += item.element_count
                        element_count_internal += item.element_count_internal
                        element_count_with_issues += item.element_count_with_issues
                        element_count_with_issues_internal += item.element_count_with_issues_internal
                    })

                    extra.element_count = element_count
                    extra.element_count_internal = element_count_internal
                    extra.element_count_with_issues = element_count_with_issues
                    extra.element_count_with_issues_internal = element_count_with_issues_internal
                    slicedArray.push(extra)
                }
                //Set ID widget 
                setIdWidget(slicedArray)
                setRuleWidget(res.data.access_protocol_summary.protocols)

                const rulesRoot: any = [];
                //SetRuleRootCount Data
                setRuleRootIdentity(rulesRoot);
            }
        }, (err: any) => {
            console.log(err);
            setLoading(false);
            setResponseData(undefined);
        })
    }

    const handleGotoPage = () => {
        let pageValue = (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value;
        pageValue = (pageValue !== "") ? pageValue : "1";
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', pageValue);
        (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value = "";
        history.push(window.location.pathname + "?" + currentUrlParams?.toString());
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    //Download CSV
    const headers = [
        { label: "Identity Name", key: "identity" },
        { label: "Identity Type", key: "identity_type" },
        { label: "Host 1", key: "identity_host_detail[0].hostname" },
        { label: "Host 2", key: "identity_host_detail[1].hostname" },
        { label: "Host 3", key: "identity_host_detail[2].hostname" },
        { label: "Host 4", key: "identity_host_detail[3].hostname" },
        { label: "Host 5", key: "identity_host_detail[4].hostname" },
        { label: "Asset Name", key: "asset" },
        { label: "Asset Directory Status", key: "directory_flow_status" },
        { label: "Asset Type", key: "asset_type" },
        { label: "Host 1", key: "asset_host_detail[0].hostname" },
        { label: "Host 2", key: "asset_host_detail[1].hostname" },
        { label: "Host 3", key: "asset_host_detail[2].hostname" },
        { label: "Host 4", key: "asset_host_detail[3].hostname" },
        { label: "Host 5", key: "asset_host_detail[4].hostname" },
        { label: "IP:Port 1", key: "asset_host_detail[0].ip_port" },
        { label: "IP:Port 2", key: "asset_host_detail[1].ip_port" },
        { label: "IP:Port 3", key: "asset_host_detail[2].ip_port" },
        { label: "IP:Port 4", key: "asset_host_detail[3].ip_port" },
        { label: "IP:Port 5", key: "asset_host_detail[4].ip_port" },
        { label: "Protocol", key: "asset_protocol" },
        { label: "Access Issued - Unknown Access", key: "rules.unknown_access.issue_count" },
        { label: "# Flows", key: "flow_count" },
        { label: 'Directory Fail Count', key: 'directory_flow_fail_count' },
        { label: "Unsuccessful Authentication Percent", key: "auth_failed_percent" },
        { label: "Overall # Incidents", key: "issue_count" },
        { label: "Latest Activity", key: "latest_time" },
        { label: "Score", key: "score" }
    ];

    const [downData, setDownData] = useState<string | Data>([]);
    const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(0)
    const [loadingCSV, setLoadingCSV] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();


    const getUserList = async (selectedPage?: any) => {
        param.page = selectedPage === 0 ? 1 : selectedPage + 1;
        param.rpp = 100;
        const searchHeaders = await getSearchHeaders(param)
        return Api.get("/accesses", {
            params: { ...param, is_export_csv: true },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then(res => {
                res.data = validateLocalIps(res.data, 'identity_host_detail');
                res.data = validateLocalIps(res.data, 'asset_host_detail');
                res.data.result.map((item: any, index: any) => { currDownData.push(item) });
                //console.log(currDownData);
                return currDownData;
            }).catch((error: any) => {
                setLoadingCSV({ loading: false, setData: false })
                if (error.response.status === 500 || error.response.status === 524) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const downloadCSV = async () => {
        setTotalDownloaded(0);
        setLoadingCSV({ loading: true, setData: false })
        let i = 0;
        do {
            const downData = await getUserList(i);
            setTotalDownloaded(i * 100);
            if (i > Math.floor(totalCount! / 100)) {
                setDownData(downData);
                setTimeout(() => {
                    setLoadingCSV({ loading: false, setData: true })
                    if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
                        csvLinkEl?.current?.link.click();
                    }
                });
            }
            i = i + 1;
        } while (i <= Math.floor(totalCount! / 100) + 1)

    }

    const handleTabClick = (to: string) => {
        if (PostureSearchState.isAdSearchApplied) {
            const tempTags = tags.map(item => encodeURIComponent(item));
            history.push(`/${to}?order_by=desc&sort_by=score&q=${tempTags.join('%2B')}`)
        } else {
            history.push(`/${to}?order_by=desc&sort_by=score`);
        }
    }

    useEffect(() => {
        if (PostureSearchState.isAdvancedSearchOpen) {
            setShowAdvanceSearch(true)
        }
    }, [])

    const [showOudatedData, setShowOutdatedData] = useState(false);

    const outDated = () => {
        let clearOutdatedDataInterval = null;
        setShowOutdatedData(false);

        let tempTags = getTagsForTimeBasedSearch(tags);

        tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
            if (tempTags && tempTags.length > 0)
                return item === tempTags[0][1]
            return false;
        })
        if (tempTags && tempTags.length > 0) {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
            clearOutdatedDataInterval = setTimeout(() => {
                if (isPresetPeriodOptionSelected()) {
                    setShowOutdatedData(true);
                }
            }, 30000);
        }
        return () => {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
        }
    }
    useEffect(() => {
        outDated();
    }, [tags])

    useEffect(() => {
        PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
    }, [showAdvanceSearch])

    const matchingPlaybookFoundFn = () => {
        const newParams = { ...query };
        newParams.entity_type = ENTITIES.ACCESSES.id;
        setQuery(newParams);
        matchingPlaybookFound(newParams, getSearchHeaders)
    }

    const onFieldSelect = (field, fields) => {
        setSelectedField(field);
        setAdditionalFields(fields);
    }

    const getPostureSearchTemplate = () => {
        if (tags.find((item: string) =>
            item.includes('num_of_auth_failed_flow')
        )) {
            return JSON.parse(JSON.stringify(PostureSearchTemplate)).map(item => {
                if (item.search_key === "auth_failed_percent" && item['dataField'] && item['dataField']['Accesses']) {
                    delete item['dataField']['Accesses'];
                }
                return item;
            });
        }
        return PostureSearchTemplate;
    }

    return (
        <>
            <div className="clrBoth margintop10"></div>
            <div className='hide-table-border-top'></div>
            <div className="">
                <div className="flow_table_container">
                    <div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
                        <TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=access'} pageType={'accesses'}></TimeBasedSearchContainer>
                        <PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
                        <PostureActionButtons
                            selectAll={selectAll}
                            onArchiveClick={onArchive.bind(null, reloadIdentities)} selectCount={selectCount}
                            isOpenIncidents={isIncidentsOpen}
                            onArchiveEstimate={onArchiveEstimate.bind(null)}
                            entity='accesses'
                            isHidden={true} ref={archiveBtnRef}
                        />
                        {showAdvanceSearch && <PostureFilter
                            filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
                            notSupportedKeys={notSupportedKeys} filters={tags}
                            isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
                            onClose={onCloseAdvanceSearch}
                            disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
                            matchingPlaybookFoundFn={matchingPlaybookFoundFn}
                            entityType={ENTITIES.ACCESSES.id}
                            query={query}
                            showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
                            preMetaDataHandler={preMetaDataHandler}
                            isTimeBasedSearchApplied={isTimeBasedSearchApplied}
                            apiUrl={'/posture/daily_flow_count?entity_type=access'} pageType={'accesses'}
                        />}
                        {
                            openPanelFilterWidgets ?
                                <FilterWidget toggleWidget={handleFilterWidget}
                                    disabledState={handleFilterDisabled}
                                    updateAfterFilter={reloadIdentities} issueFiltersData={filterData} /> : ""
                        }
                    </div>

                    <div id="tab1" className="tab-panel">
                        <table id="table" className={showAdditionalResult ? "id_asset_tb sticky_table_top posture-table-width access-tab" : "id_asset_tb sticky_table_top access-tab"}>
                            <thead>
                                <tr>
                                    <th colSpan={13} className="" style={{ background: "#ffffff", color: "#4e5267" }} >
                                        <div className='flex-space-between'>
                                            <div>
                                                <div className="reload_container">
                                                    <div ref={reloadRef} className="reload_color_icon" onClick={reloadIdentities}></div>
                                                </div>

                                                <div className="table_title">Last Updated: {lastUpdate}</div>
                                                {showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" className='outdated-data-msg' /> : <></>}
                                            </div>
                                            {/* <div className="search_white_icon">&nbsp;</div> */}
                                            {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
                                            <div className='maxWidth550 display-flex'>
                                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                    <div className="search_container">
                                                        <div className="search_white_icon"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={handleLensSearch}
                                                        >&nbsp;</div>&nbsp;
                                                        <Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
                                                        {/* {tags?.map((tag, index) => <div key={tag + index} title={tag?.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
                                                        <input
                                                            className="search_input"
                                                            value={searchInput}
                                                            placeholder="Search..."
                                                            onKeyDown={handleSearch}
                                                            onChange={handleSearchChange}
                                                        />
                                                        {getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
                                                            setTagFlag(true);
                                                            PostureSearchState.setIsAdSearchApplied(false)
                                                            if (isTimeBasedSearchApplied) {
                                                                const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
                                                                setTags(tempTags);
                                                            } else {
                                                                setTags([])
                                                            }
                                                        }}>&nbsp;X</span> : null}
                                                    </div>
                                                </div>
                                                <div className='issue-advance-search-btn-con  display_flex_center accesses-btn'
                                                    style={{ float: 'right' }}
                                                ><button onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search margin-right-10'} >Advanced Search</button>
                                                    <PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
                                                        isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </th>
                                </tr>

                                <tr>
                                    {
                                        authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
                                        <th className={showAdditionalResult ? "posture-table-checkbox-column" : ''} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 30 : 30, padding: '0px' }}>
                                            <AMMultiCheckbox
                                                indeterminate={selectAllIndeterminate}
                                                menuItems={PostureMultiSelectCheckboxItems}
                                                onClick={PostureMultiSelectCheckboxItems[0].callback}
                                                // checked={selectAll || false}
                                                checked={selectAll}
                                                // disabled={!openIssuesCount || openIssuesCount < 1}
                                                disabled={false}
                                            />
                                        </th>
                                    }
                                    <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 380 : 380 }} className="no_padding">
                                        <tr style={{ display: "flex" }}><th className='line_ht30' colSpan={5} style={{ width: "100%", borderRight: "none" }}>Identity</th></tr>
                                        <tr className="th_ver_height">
                                            <th data-headerName="identity" className={(selectedColumn == 'identity' ? currentSort : " tablesort_down")} onClick={handleSort} style={{ width: "75%", borderTop: "1px solid #fff", borderRight: "none", display: "flex", alignItems: "center" }}><span className='align_left float_left'>Name</span></th>
                                            <th data-headerName="identity_type" className={(selectedColumn == 'identity_type' ? currentSort : " tablesort_down")} onClick={handleSort} style={{ width: "15%", borderTop: "1px solid #fff", borderRight: "none", display: "flex", justifyContent: "center", alignItems: "center" }}><span>Type</span></th>
                                        </tr>
                                    </th>
                                    <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 380 : 380 }} className="no_padding">
                                        <tr style={{ display: "flex" }}><th className='line_ht30' colSpan={5} style={{ width: "100%", borderRight: "none" }}>Asset</th></tr>
                                        <tr className="th_ver_height">
                                            <th data-headerName="asset" className={(selectedColumn == 'asset' ? currentSort : " tablesort_down")} onClick={handleSort} style={{ width: "65%", borderTop: "1px solid #fff", borderRight: "none", borderLeft: "none", display: "flex", alignItems: "center" }}><span className='align_left float_left'>Name</span></th>
                                            <th data-headerName="is_directory_present"
                                                className={(selectedColumn == 'is_directory_present' ? currentSort : " tablesort_down") + ' dir_icon_access_th'}
                                                onClick={handleSort} style={{ minWidth: "2%", borderTop: "1px solid #fff", borderRight: "none", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className='dir_icon_access'></div><span></span></th>
                                            <th data-headerName="asset_type" className={(selectedColumn == 'asset_type' ? currentSort : " tablesort_down")} onClick={handleSort}
                                                style={{
                                                    width: "15%", borderTop: "1px solid #fff", borderRight: "none",
                                                    paddingLeft: is4k === 2 ? 5 : 10,
                                                    display: "flex", justifyContent: "center", alignItems: "center"
                                                }}><span>Type</span></th>
                                        </tr>
                                    </th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 65 : 65 }} data-headerName="asset_protocol" className={(selectedColumn == 'asset_protocol' ? currentSort : " tablesort_down")} onClick={handleSort}>Protocol</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 78 : 78 }} data-headerName="flow_count" className={(selectedColumn == 'flow_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall<br /> Number<br />of<br />Flows</th>
                                    {showUnsuccessAuth ? <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : 60 }} data-headerName="auth_failed_percent" className={(selectedColumn == 'auth_failed_percent' ? currentSort : " tablesort_down")} onClick={handleSort}>% Unsuccessful<br />Auth<br /></th> : null}
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }} data-headerName="issue_count" className={(selectedColumn == 'issue_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall<br />Number<br />of<br />Incidents</th>
                                    {showAdditionalResult && (
                                        selectedField !== null ? (
                                            <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }} data-headerName={selectedField['search_key']} className={additionalResultHighlightClass + ' ' + (additionalFields.includes(selectedColumn) ? currentSort : " tablesort_down") + (additionalFields.length > 1 ? ' additional_result_sort_icon' : '')} onClick={handleSort}>
                                                <div className='additional-results-col'>
                                                    <AdditionalResult options={tags} filterTemplate={getPostureSearchTemplate(PostureSearchTemplate)} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ACCESSES.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
                                                </div>
                                            </th>)
                                            : (
                                                <th className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }}>
                                                    <div className='additional-results-col'>
                                                        <AdditionalResult options={tags} filterTemplate={getPostureSearchTemplate(PostureSearchTemplate)} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ACCESSES.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
                                                    </div>
                                                </th>
                                            )
                                    )
                                    }
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 135 : 135 }} data-headerName="latest_time" className={(selectedColumn == 'latest_time' ? currentSort : " tablesort_down")} onClick={handleSort}>Latest Activity</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 40 : 40 }} data-headerName="score" className={(selectedColumn == 'score' ? currentSort : " tablesort_down")} onClick={handleSort}>Score</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                        </tr> :
                                        responseData?.result == 'error' || responseData?.result.length == 0 ?
                                            <tr>
                                                <td colSpan={13} style={{ textAlign: 'center' }}>{tableFetchError}</td>
                                            </tr>
                                            :
                                            responseData && responseData.result !== null && responseData.result.length > 0 ? renderData(responseData.result) :
                                                <tr>
                                                    <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                                </tr>
                                }
                            </tbody>

                        </table>
                        <div className="clrBoth"></div>
                    </div>
                    <div className="download_section">
                        <button type={('button')} onClick={downloadCSV} className={"width180 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>
                        {loadingCSV.loading ?
                            <div className='loading_info'>
                                Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Accesses
                            </div>
                            : null}
                        {PartnerConfigState?.PartnerShortProduct && <CSVLink
                            headers={headers}
                            // filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Accesses.csv`}
                            filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Accesses')}
                            data={downData!}
                            ref={csvLinkEl}
                        />}
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                            <Link to={'/archivedata'} className='link-text font12'>Accesses inactive for more than 60 days are archived here.</Link>
                        </div>
                    </div>

                    <div className="float_right goto_page">Go to page:
                        <input
                            type="text"
                            name="page_no"
                            className=""
                            defaultValue=""
                            placeholder="No."
                        />
                        <button type="button" onClick={handleGotoPage} className="button_gray float_right">Go</button>
                    </div>
                    <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        forcePage={param.page !== undefined ? (param.page - 1) : 0}
                        pageCount={responseData && responseData.result.length > 0 ? (Math.ceil(responseData.total / responseData.rpp)) : 1}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        onPageChange={handlePaginate}
                    />
                </div>
            </div>
            {showRuleMatch ? <RuleMatch data={ruleMatch} prevURL={prevUrl} handleRuleMatchClose={() => setShowRuleMatch(false)} /> : null}
            {showMatchRule ?  <MatchRule data={matchRuleData} handleRuleMatchClose={() => setShowMatchRule(false)} /> :null}
            {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} onRangeChange={handleRangeChange} zoomLevel={zoomLevel} updatedExtremes={updatedExtremesRef} showZoom={true} /> : ''}
            {failedDir && <FailedDirPopUp data={failedDir} onClose={() => setFailedDir(undefined)} />}

        </>
    )
}

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    hard_refresh: StringParam,
    filters: withDefault(ArrayParam, []),
    entity_type: StringParam,
    playbook_type: StringParam
}, Accesses));