import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedDirBotAttackState, ThresholdType } from "../../../../types/playbooks-config";
import { ThresholdSelect } from "../../ref/ThresholdSelect";
import { getDefaultConfigState } from "../../helpers/playbook-helper";



export const SuspectedDirectoryBot = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedDirBotAttackState>) => {
    const [state, setState] = useState<SuspectedDirBotAttackState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleSeparateHost = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            dir_params: {
                ...state.dir_params, user_auth_fail_nhosts_today: parseInt(e.target.value)
            }
        })
    }

    const handleAuthFail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, user_auth_fail_count_today: parseInt(e.target.value) } })
    }

    useEffect(() => {
        /*  if (getExpression) {
             getExpression(JSON.parse(JSON.stringify(state?.dir_params)))
         } */
    })

    const onThresholdSelect = (value) => {
        const defaultSt = getDefaultConfigState(ruleType) as SuspectedDirBotAttackState;
        let st;
        if (state?.dir_params.param_thresholds) {
            st = [...state?.dir_params.param_thresholds];
        } else {
            st = [...defaultSt?.dir_params.param_thresholds];
        }
        st[0].threshold_type = value.target.value
        st[1].threshold_type = value.target.value
        let v1 = defaultSt?.dir_params?.user_auth_fail_count_today
        let v2 = defaultSt?.dir_params?.user_auth_fail_nhosts_today
        if (value.target.value === ThresholdType.AUTOMATIC) {
            st[0].allow_ml_modify = true;
            st[0].threshold_confidence = 'low';
            st[1].allow_ml_modify = true;
            st[1].threshold_confidence = 'low';
        } else {
            st[0].allow_ml_modify = false;
            st[0].threshold_confidence = undefined;
            st[1].allow_ml_modify = false;
            st[1].threshold_confidence = undefined;
        }
        setState({ dir_params: { user_auth_fail_count_today: v1, user_auth_fail_nhosts_today: v2, param_thresholds: st, param_durations: state?.dir_params?.param_durations } });
    }


    return <>
        <form className="suspected-dir-bot-container dir-main-container">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || ThresholdType.MANUAL} />
            </div>
            <div className={(state?.dir_params?.param_thresholds?.[0]?.threshold_type === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>
                <div className="suspected-dir-bot-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        A directory or IdP will be marked as under a bot/botnet attack when:</label>
                </div>
                <div className="suspected-dir-bot-row">
                    <label>
                        Number of separate hosts used by an account in the current day is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_auth_fail_nhosts_today}</label> :
                            <input type="number" onChange={handleSeparateHost} name="num_sep_host" value={state?.dir_params?.user_auth_fail_nhosts_today} />}
                </div>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }} className="font14">AND</div>
                <div className="suspected-dir-bot-row">
                    <label>
                        Number of authentication failures within the current day for the account crosses
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_auth_fail_count_today}</label> :
                            <input type="number" onChange={handleAuthFail} name="num_fail_per_day" value={state?.dir_params?.user_auth_fail_count_today} />
                    }
                </div>
            </div>
        </form>
    </>
}