import React, { useEffect } from "react"
import { BaseProps } from "../../constants/types";
import './directory-config.css';
import { DirParamThreshold, EnumAdAdminState, ThresholdType } from "../../../../types/playbooks-config";
import { ThresholdSelect } from "../../ref/ThresholdSelect";
import { getDefaultConfigState } from "../../helpers/playbook-helper";



const EnumADAdmin = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<EnumAdAdminState>) => {
    const [state, setState] = React.useState<EnumAdAdminState>(defaultState);
    /*    const [checked, setChecked] = React.useState<any>({
           dir_bad_username_count_curhour: isSavedOnce ? (parentState?.dir_params?.dir_bad_username_count_curhour ? true : false) : (state?.dir_params.dir_bad_username_count_curhour ? true : false),
           dir_bad_username_nadmin_curhour: isSavedOnce ? (parentState?.dir_params?.dir_bad_username_nadmin_curhour ? true : false) : (state?.dir_params.dir_bad_username_nadmin_curhour ? true : false)
       }); */
    const [checked, setChecked] = React.useState<any>({
        dir_bad_username_count_curhour: true,
        dir_bad_username_nadmin_curhour: true
    });

    useEffect(() => {
        const result = {} as any;
        const st = state?.dir_params;
        Object.keys(checked).forEach((i: string) => {
            if (i && checked[i] && st && st[i as keyof typeof st]) {
                result[i as keyof typeof result] = st[i as keyof typeof st];
            }
        });
        if (Object.keys(result).length > 0) {
            getState({ dir_params: { ...state?.dir_params, ...result } });
        } else {
            getState({ dir_params: { ...state?.dir_params, dir_bad_username_count_curhour: undefined, dir_bad_username_nadmin_curhour: undefined } });
        }
    }, [state, checked])

    useEffect(() => {
        setState(defaultState);
        setChecked({
            dir_bad_username_count_curhour: (defaultState?.dir_params?.dir_bad_username_count_curhour ? true : false),
            dir_bad_username_nadmin_curhour: (defaultState?.dir_params?.dir_bad_username_nadmin_curhour ? true : false)
        })
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                dir_bad_username_count_curhour: (parentState.dir_params.dir_bad_username_count_curhour ? true : false),
                dir_bad_username_nadmin_curhour: (parentState.dir_params.dir_bad_username_nadmin_curhour ? true : false)
            })
        }
    }, [isSavedOnce])

    const handleNumAttempts = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, dir_bad_username_count_curhour: parseInt(e.target.value) } });
        setChecked({ ...checked, dir_bad_username_count_curhour: e.target.value ? true : false })
    }

    const handleAdminAttempts = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, dir_bad_username_nadmin_curhour: parseInt(e.target.value) } })
        setChecked({ ...checked, dir_bad_username_nadmin_curhour: e.target.value ? true : false })
    }

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    useEffect(() => {
        const user_input1 = state?.dir_params?.dir_bad_username_count_curhour;
        const user_input2 = state?.dir_params?.dir_bad_username_nadmin_curhour;
        const object = {
            user_input1, user_input2
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    const onThresholdSelectNumAttempts = (value) => {
        const params: DirParamThreshold = {
            allow_ml_modify: false,
            param_name: 'dir_bad_username_count_curhour',
            threshold_confidence: undefined,
            threshold_type: ThresholdType.MANUAL
        };
        if (value.target.value == ThresholdType.AUTOMATIC) {
            params.allow_ml_modify = true;
            params.threshold_type = value.target.value;
            params.threshold_confidence = 'low'
        }
        const paramsArray = state?.dir_params?.param_thresholds?.filter((i) => i.param_name != 'dir_bad_username_count_curhour');
        paramsArray.push(params)
        setState({ dir_params: { ...state?.dir_params, param_thresholds: paramsArray }, });
    }

    const onThresholdSelectNumAdminAttempts = (value) => {
        const defaultSt = getDefaultConfigState(ruleType) as EnumAdAdminState;
        let st;
        if (state?.dir_params.param_thresholds) {
            st = [...state?.dir_params.param_thresholds];
        } else {
            st = [...defaultSt?.dir_params.param_thresholds];
        }
        st[0].threshold_type = value.target.value
        st[1].threshold_type = value.target.value
        let v1 = defaultSt?.dir_params?.dir_bad_username_count_curhour
        let v2 = defaultSt?.dir_params?.dir_bad_username_nadmin_curhour
        if (value.target.value === ThresholdType.AUTOMATIC) {
            st[0].allow_ml_modify = true;
            st[0].threshold_confidence = 'low';
            st[1].allow_ml_modify = true;
            st[1].threshold_confidence = 'low';
        } else {
            st[0].allow_ml_modify = false;
            st[0].threshold_confidence = undefined;
            st[1].allow_ml_modify = false;
            st[1].threshold_confidence = undefined;
        }
        setState({ dir_params: { dir_bad_username_count_curhour: v1, dir_bad_username_nadmin_curhour: v2, param_thresholds: st } });
    }

    const getDefaultThresholdType = () => {
        return state?.dir_params?.param_thresholds?.[0].threshold_type || ThresholdType.MANUAL;
    }

    return <>
        <div className="dir-main-container">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelectNumAdminAttempts}
                    defaultValue={getDefaultThresholdType()} />
            </div>
            <div className={(getDefaultThresholdType() === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>
                <div className="dir-container-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        An Active Directory will be marked as under Admin Enumeration when:</label>
                </div>
                <div className="dir-container-row">
                    <label htmlFor="num-attempts">
                        Number of attempts made in the current hour is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label marginbottom10">{state?.dir_params?.dir_bad_username_count_curhour}</label> :
                            <input type="number"
                                className={" flex-item-last"}
                                onChange={handleNumAttempts} name="num_attempts" value={state?.dir_params?.dir_bad_username_count_curhour || ''} />
                    }
                </div>
                <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 10 }} className="font14">AND</div>
                <div className="dir-container-row">
                    <label htmlFor="num-admin-attempts">
                        Number of admin accounts attempted in the current hour crosses
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.dir_bad_username_nadmin_curhour}</label> :
                            <input type="number" onChange={handleAdminAttempts}
                                className={" flex-item-last"}
                                name="num_admin_attempts" value={state?.dir_params?.dir_bad_username_nadmin_curhour || ''} />
                    }
                </div>
            </div>
        </div>
    </>
}

export default React.memo(EnumADAdmin);