import { ISplunkFormFieldConfig } from "./SplunkFieldForm";
const compare = (a: ISplunkFormFieldConfig, b: ISplunkFormFieldConfig) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
}

export const SplunkFieldArray = [
    {
        name: 'zscaler_zia_web',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler (ZIA) Web',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-web',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'zscaler_zia_dns',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler (ZIA) DNS',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-dns',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'zscaler_zpa_user_activity',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler (ZPA)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalerlss-zpa-app',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true

    },
    {
        name: 'palo_alto',
        placeholder: 'Enter comma separated index names',
        label: 'Palo Alto VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'pan:traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'on_prem_ad',
        placeholder: 'Enter comma separated index names',
        label: 'Active Directory',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'WinEventLog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'Microsoft Entra ID (Azure AD)',
        placeholder: 'Enter comma separated index names',
        label: 'Azure AD',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'mscs:azure:eventhub',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'aws_vpc',
        placeholder: 'Enter comma separated index names',
        label: 'AWS VPC flow logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'aws:cloudwatchlogs:vpcflow',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'gcp_vpc',
        placeholder: 'Enter comma separated index names',
        label: 'GCP VPC flow logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'google:gcp:compute:vpc_flows',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'azure_nsg',
        placeholder: 'Enter comma separated index names',
        label: 'Azure NSG flow logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'mscs:resource:securityGroup',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true

    },
    {
        name: 'cisco_meraki',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Meraki',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'meraki:organizationsecurity',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'openvpn',
        placeholder: 'Enter comma separated index names',
        label: 'Open VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'openvpn',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'checkpoint_vpn',
        placeholder: 'Enter comma separated index names',
        label: 'Checkpoint VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cp_log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_traffic',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet Traffic',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_event',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet Event',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_event',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_utm',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet UTM',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_utm',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'f5vpn',
        placeholder: 'Enter comma separated index names',
        label: 'Big F5 VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'f5:bigip:apm:syslog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true

    },
    // {
    //     name: 'perimeter81',
    //     placeholder: 'Enter comma separated index names',
    //     label: 'Perimeter 81',
    //     placeholder_src_type: 'Enter Splunk Source type',
    //    placeholder_timezone: 'Enter logs timezone value, for example: EST',
    //   default_timezone: "UTC_+00:00"

    // },
    {
        name: 'cisco_umbrella_dns',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella DNS',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:dns',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'cisco_umbrella_proxy',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella Proxy',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:proxy',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'cisco_umbrella_ip',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella IP',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:ip',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    // {
    //     name: 'cato',
    //     placeholder: 'Enter comma separated index names',
    //     label: 'Cato',
    //     placeholder_src_type: 'Enter Splunk Source type',
    //    placeholder_timezone: 'Enter logs timezone value, for example: EST',
    //    default_timezone: "UTC_+00:00"

    // },
    {
        name: 'cisco_asa',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco ASA',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:asa',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'cisco_ftd',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco FTD',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:asa',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'sonicwall',
        placeholder: 'Enter comma separated index names',
        label: 'Sonicwall',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'sonicwall:traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'okta',
        placeholder: 'Enter comma separated index names',
        label: 'Okta',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'OktaIM2:log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_audit',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Audit',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_activity',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Activity',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_authentication',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Authentication',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'google_workspace_saml',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace SAML',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:saml',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'google_workspace_login',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace Login',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:login',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'google_workspace_oauth',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace OAuth',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:token',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'aws_cloudtrail',
        placeholder: 'Enter comma separated index names',
        label: 'AWS CloudTrail',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'aws:cloudtrail',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'zscaler_firewall',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler Firewall',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-fw',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'ecc',
        placeholder: 'Enter comma separated index names',
        label: 'ECC audit logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true,
        isAmOps: true
    },
    {
        name: 'bluecoat_proxy',
        placeholder: 'Enter comma separated index names',
        label: 'Blue Coat ProxySG',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'bluecoat:proxysg:access:syslog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }
].sort(compare) as Array<ISplunkFormFieldConfig>;


export const SplunkFieldArrayNew = [
    {
        name: 'Zscaler ZIA (DNS)',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler ZIA (DNS)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-dns',
        
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Zscaler ZIA (Web)',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler ZIA (Web)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-web',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Zscaler ZPA',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler (ZPA)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalerlss-zpa-app',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'SonicWall Firewall & VPN',
        placeholder: 'Enter comma separated index names',
        label: 'Sonicwall VPN & FW',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'sonicwall:traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Palo Alto Firewall & VPN',
        placeholder: 'Enter comma separated index names',
        label: 'Palo Alto Firewall & VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'pan:traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'OpenVPN',
        placeholder: 'Enter comma separated index names',
        label: 'Open VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'openvpn',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Netflow Traffic (IPFIX, Sflow)',
        placeholder: 'Enter comma separated index names',
        label: 'Netflow Traffic (IPFIX, Sflow)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_traffic',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet Traffic',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_traffic',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_event',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet Event',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_event',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'fortinet_utm',
        placeholder: 'Enter comma separated index names',
        label: 'Fortinet UTM',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'fortigate_utm',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'F5 Big-IP APM & VPN',
        placeholder: 'Enter comma separated index names',
        label: 'F5 BigIP APM',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'f5:bigip:apm:syslog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Check Point Firewall & VPN',
        placeholder: 'Enter comma separated index names',
        label: 'Check Point Firewall & VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cp_log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Check Point Perimeter 81',
        placeholder: 'Enter comma separated index names',
        label: 'Check Point Perimeter 81',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Cisco Umbrella',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Cisco Meraki',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Meraki',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'meraki:organizationsecurity',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },    
    {
        name: 'Cisco Firepower Threat Defense (FTD)',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco FTD',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:asa',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },    
    {
        name: 'Cisco ASA',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco ASA',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:asa',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },   
    {
        name: 'Cato VPN',
        placeholder: 'Enter comma separated index names',
        label: 'Cato VPN',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'Crowdstrike Falcon EDR',
        placeholder: 'Enter comma separated index names',
        label: 'Crowdstrike Falcon EDR',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'CrowdStrike:Event:Streams:JSON',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'Bastion SSH',
        placeholder: 'Enter comma separated index names',
        label: 'Bastion SSH',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'GCP VPC Flow Logs',
        placeholder: 'Enter comma separated index names',
        label: 'GCP VPC Flow Logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'google:gcp:compute:vpc_flows',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'Azure NSG Flow Logs',
        placeholder: 'Enter comma separated index names',
        label: 'Azure NSG Flow Logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'mscs:resource:securityGroup',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'AWS VPC Flow Logs',
        placeholder: 'Enter comma separated index names',
        label: 'AWS VPC Flow Logs',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'aws:cloudwatchlogs:vpcflow',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'AWS S3',
        placeholder: 'Enter comma separated index names',
        label: 'AWS S3',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'aws:cloudtrail',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }, 
    {
        name: 'Ping Identity',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Identity',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Okta IdP',
        placeholder: 'Enter comma separated index names',
        label: 'Okta',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'OktaIM2:log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'OneLogin',
        placeholder: 'Enter comma separated index names',
        label: 'OneLogin',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'IBM Verify SaaS',
        placeholder: 'Enter comma separated index names',
        label: 'IBM Verify SaaS',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Google Workspace',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Microsoft Entra ID (Azure AD)',
        placeholder: 'Enter comma separated index names',
        label: 'Azure AD',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'mscs:azure:eventhub',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'AWS Managed AD',
        placeholder: 'Enter comma separated index names',
        label: 'AWS Managed AD',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'AD Lightweight Directory Service (AD-LDS)',
        placeholder: 'Enter comma separated index names',
        label: 'AD Lightweight Directory Service (AD-LDS)',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Active Directory',
        placeholder: 'Enter comma separated index names',
        label: 'Active Directory',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'WinEventLog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Blue Coat ProxySG',
        placeholder: 'Enter comma separated index names',
        label: 'Blue Coat ProxySG',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'bluecoat:proxysg:access:syslog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Cisco Umbrella DNS Security',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella DNS',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:dns',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Zscaler Firewall',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler Firewall',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'zscalernss-fw',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Microsoft AD (via SIEM)',
        placeholder: 'Enter comma separated index names',
        label: 'Active Directory',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'WinEventLog',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_audit',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Audit',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_activity',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Activity',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'ping_authentication',
        placeholder: 'Enter comma separated index names',
        label: 'Ping Authentication',
        placeholder_src_type: 'Enter Splunk Source type',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'cisco_umbrella_proxy',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella Proxy',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:proxy',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'cisco_umbrella_ip',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco Umbrella IP',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'cisco:umbrella:ip',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'google_workspace_saml',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace SAML',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:saml',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'google_workspace_login',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace Login',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:login',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'google_workspace_oauth',
        placeholder: 'Enter comma separated index names',
        label: 'Google Workspace OAuth',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: 'gws:reports:token',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'iboss Zero Trust SASE',
        placeholder: 'Enter comma separated index names',
        label: 'iboss',
        placeholder_src_type: 'Enter Splunk Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    }
];
