import React, { useEffect } from "react";
import { COLLECTOR_ID_MAPPING } from "../constants/sensor-labels";
import { SyslogCollect } from "./SyslogCollector";
import { Link } from "react-router-dom";

export const IBMVerifyAccess = ({ register, setValue, collectorData, collectionMethodData, showSyslog = true }) => {
    useEffect(() => {
        if (collectorData) {
            setValue('ibm_verify_access_host_ip', collectorData.collector_config?.ibm_verify_access_host_ip);
            setValue('ibm_verify_access_username', collectorData.collector_config?.ibm_verify_access_username);
            setValue('ibm_verify_access_password', collectorData.collector_config?.ibm_verify_access_password);
        }
    }, [collectorData]);

    return (
        <>
            <div className='collector-configuration-vendor-container'>
                <div className={`form_sub_section`}>
                    <div className='form_sub_section_header'>
                        IBM Verify Access Configuration
                    </div>
                    <div className='form_sub_section_content'>
                        {showSyslog && <SyslogCollect collectionMethodData={collectionMethodData} collectorName={COLLECTOR_ID_MAPPING.IBM_VERIFY_ACCESS}
                            showHeader={false}
                        />}
                        <div className="form_sub_section">
                            <div className='form_sub_section_header'>
                                API parameters for Reverse Proxy and Junction
                            </div>
                            <div className='form_sub_section_content'>
                                <div className='sensor-flex-container'>
                                    <div className='sensor-flex-container-item'>
                                        <label className="margintop10">Hostname/IP*</label>
                                        <input
                                            type='text'
                                            name='ibm_verify_access_host_ip'
                                            ref={register({
                                                required: true,
                                                shouldUnregister: true
                                            })}
                                            placeholder={'Enter Hostname/IP'}
                                        />
                                    </div>
                                </div>

                                <div className='sensor-flex-container'>
                                    <div className='sensor-flex-container-item'>
                                        <label className="margintop10">API Username*</label>
                                        <input
                                            type='text'
                                            name='ibm_verify_access_username'
                                            ref={register({
                                                required: true,
                                                shouldUnregister: true
                                            })}
                                            placeholder={'Enter API Username'}
                                        />
                                    </div>
                                </div>

                                <div className='sensor-flex-container'>
                                    <div className='sensor-flex-container-item'>
                                        <label className="margintop10">API Password*</label>
                                        <input
                                            type='password'
                                            name='ibm_verify_access_password'
                                            ref={register({
                                                required: true,
                                                shouldUnregister: true
                                            })}
                                            placeholder={'Enter API Password'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='error clrBoth font12 margintop10'>
                Note:  It's strongly recommended to configure Directory Query parameters for better quality of data.&nbsp;
                <Link className='link-text font12' to='/dirQueryParam'>
                    <span className='font12'>
                        You can set them here.
                    </span>
                </Link>{' '}
            </div>
        </>
    );
};
