import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useAuthDetails } from '../Authorization'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../components/core';
import { Api } from './../../components/Axios'
import moment from 'moment';
import { PasswordStrengthMeter } from './../../components/PasswordStrengthMeter'
import { DashboardWidgets } from '../../types/response-types';
import { AMToolTip } from '../core/AMToolTip/AMToolTip'
import Ticker from '../Ticker/Ticker';
import { usePartnerStateContext } from '../../store/PartnerContextProvider'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import './Header.scss'
import { Fade, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { GlobalConsts } from '../../GlobalConst'
import { isAmopsUser } from '../../utils/util-methods'
import { ITableResult } from '../core/AMTable/AMAdminTable'
import ModalPopup from '../core/AMModalPopup/ModalPopup'
import Popup from 'reactjs-popup'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';

type User = {
  old_password: string;
  password?: string;
  confirm_password?: string;
}

type LoginHistory = {
  email?: string;
  name?: string;
  role?: string;
  last_logins?: LastLogins[];
}

type LastLogins = {
  login_ip?: string;
  login_time?: string;
}
type ActionType = {
  type?: string;
  id?: string;
}

type DashboardWidget = {
  exclude_widgets?: Array<Object> | null;
}

type SSOList = {
  enable_sso?: boolean;
  name?: string;
  portal_fqdn?: [];
  entity_id?: string;
  metadata_url?: string;
  metadata_xml?: string;
  sso_login_url?: string;
  certificate?: string;
  private_key?: string;
  company_id?: string;
  root_sso?: boolean
}

export const Header = () => {
  const { PartnerConfigState } = usePartnerStateContext();
  const { authDetails } = useAuthDetails()!;
  let location = useLocation()
  const [firstRender, setFirstRender] = React.useState<boolean>(false)
  const [openPanel, setOpenPanel] = React.useState<boolean>(false)
  const [panelType] = React.useState<PanelType>('left')
  const [panelSize, setPanelSize] = React.useState<number>(0)
  const [openPasswordPanelRight, setOpenPasswordPanelRight] = React.useState<boolean>(false)
  const [panelTypeWidgets] = React.useState<PanelType>('left')
  const [panelSizeWidgets, setPanelSizeWidgets] = React.useState<number>(5)

  const [dashboardWidgetValue, setDashboardWidgetValue] = React.useState<DashboardWidgets>()
  const [openPanelRight, setOpenPanelRight] = React.useState<boolean>(false)
  const [panelTypeRight] = React.useState<PanelType>('right')
  const [panelSizeRight] = React.useState<number>(30)
  const { register, handleSubmit, errors, setValue } = useForm<User>()
  const { register: registerDashboard, handleSubmit: handleSubmitDashboard, errors: errors2 } = useForm<DashboardWidget>()
  const [loading, setLoading] = useState<Boolean>(false)
  const [oldPasswordMatch, setOldPasswordMatch] = useState<Boolean>(true)
  const [passwordMatch, setPasswordMatch] = useState<Boolean>(true)
  const [sErrors, setSErrors] = useState<User>()
  const [passWd, setPassWd] = useState("")
  const [ssoData, setSsoData] = useState(null);
  const { addToast } = useToasts()

  const admin_menu = ["/users", "/mfa", "/directories", "/deploy/manageCollector", "/deploy/sensor", "/deploy/collector", "/deploy/supportedCollector", "/deploy", "/dirQueryParam", "/audittrail", "/dataFeed", "/dns", "/smtp", "/sso", "/certificate", "/known_applications", "/intermediary", "/exceptions", "/proxy", "/general_settings", "/archivedata", "/deploy", '/known_public_ip', '/sensor_download', '/token_mg']
  const nousers_menu = ["/deploy/manageCollector", "/deploy/sensor", "/deploy/collector", "/deploy/supportedCollector", "/deploy", "/mfa", "/directories", "/dirQueryParam", "/audittrail", "/dataFeed", "/dns", "/smtp", "/sso", "/certificate", "/known_applications", "/intermediary", "/exceptions", "/proxy", "/general_settings", "/sensor_download"]
  const widget_menu = ["/",]
  const history = useHistory();
  const [loginDetails, setLoginDetails] = useState<LoginHistory>()
  const [lastLogins, setLastLogins] = useState<LastLogins[]>()
  const [actionType, setActionType] = useState<ActionType>()

  const [eyeManager, setEyeManager] = useState(false)
  const [eyeManager1, setEyeManager1] = useState(false)
  const [eyeManager2, setEyeManager2] = useState(false)

  const userDetails = localStorage.getItem('user') as string;
  const user = JSON.parse(userDetails);
  const previousWidth = useRef(window.innerWidth);
  const [isSmallResolution, setIsSmallResolution] = useState(window.innerWidth < 1440);
  const [isBigResolution, setIsBigResolution] = useState(window.innerWidth > 2200);


  const ManageEye = () => {
    let eye = eyeManager ? false : true
    setEyeManager(eye)
  }

  const ManageEye1 = () => {
    let eye = eyeManager1 ? false : true
    setEyeManager1(eye)
  }

  const ManageEye2 = () => {
    let eye = eyeManager2 ? false : true
    setEyeManager2(eye)
  }
  // window.onresize = resize;
  // function resize() {
  //   window.location.reload();
  // }

  useEffect(() => {
    if (admin_menu.includes(location.pathname)) {
      setPanelSize(3)
    } else if (nousers_menu.includes(location.pathname)) {
      setPanelSize(3)
    } else {
      setPanelSize(0)
    }

    if (widget_menu.includes(location.pathname)) {
      setPanelSizeWidgets(2)
    } else {
      setPanelSizeWidgets(0)
    }
  }, [location])



  const showWidget = () => {
    Api.get('/dashboard/widgets')
      .then((res: { data: any }) => {
        if (res.data) {
          setDashboardWidgetValue(dashboardWidgetValue => ({
            ...dashboardWidgetValue,
            compromised: !res.data.includes("compromised"),
            hashes: !res.data.includes("hashes"),
            issue_trend: !res.data.includes("issue_trend"),
            mfa: !res.data.includes("mfa"),
            password: !res.data.includes("password"),
            protocols: !res.data.includes("protocols"),
            recent_issues: !res.data.includes("recent_issues"),
            shadow_identites: !res.data.includes("shadow_identites"),
            credentials_risk: !res.data.includes("credentials_risk"),
            unauthorized_access: !res.data.includes("unauthorized_access"),
            id_access_infra: !res.data.includes("id_access_infra"),
            unknown_access: !res.data.includes("unknown_access"),
            top_new_risky:!res.data.includes("top_new_risky")
          }))
        }
      })
      .catch((error: any) => {
      })
  }

  if (authDetails.authenticated) {
    if (dashboardWidgetValue === undefined)
      setTimeout(() => { showWidget() }, 1000);
  }

  const AdminMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    //document.location.pathname = ("/users");
    history.push("/deploy/manageCollector?order_by=desc&rpp=10&sort_by=name&tableIndex=0");
  }

  const NoUsersMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    history.push("/deploy/manageCollector?order_by=desc&rpp=10&sort_by=name&tableIndex=0");
  }

  const updatePassword = (data: any) => {
    if (data.password === data.old_password) {
      setOldPasswordMatch(false);
      return
    }
    else if (data.confirm_password !== data.password) {
      setPasswordMatch(false);
      return
    } else {
      setLoading(true)
      const headers = { 'Operation': 'PUT' }
      Api.post('user/profile', data, { headers: headers })
        .then((res: { data: any }) => {
          setLoading(false)
          addToast("Password updated. Please login with the new password.", {
            appearance: 'success',
            autoDismiss: true,
          })
          setOpenPasswordPanelRight(false)
          setOpenPanelRight(false)
          history.push("/logout");
        })
        .catch((error: any) => {
          setLoading(false)
          if (error.response.status === 500) {
            addToast("Sorry, something went wrong there, try again.", {
              appearance: 'error',
              autoDismiss: true,
            })
            setSErrors(error.response.data)
          } else if (error.response.status === 419) {
            addToast(error.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            setSErrors(error.response.data)
          } else if (error.response.status === 404) {
            addToast(error.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            setSErrors(error.response.data)
          } else if (error.response.status === 400) {
            addToast(error.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            setSErrors(error.response.data)
          }
        })
    }
  }

  const WidgetMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    history.push("/");
  }

  const onUpdateProfile = (data: any) => {
    setLoading(true)
    Api.post('/users', data)
      .then((res: { data: any }) => {
        setLoading(false)
        //  AfterUserOperation('add')
        addToast("Profile Updated successfully.", {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch((error: any) => {
        setLoading(false)
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
          setSErrors(error.response.data)
        } else if (error.response.status === 404) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
          setSErrors(error.response.data)
        } else if (error.response.status === 400) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
          setSErrors(error.response.data)
        }
      })
  }

  const onUpdateWidget = (data: any) => {
    setLoading(true)
    var filteredWidget = Object.keys(data).filter(function (key) {
      return !data[key]
    });

    Api.post('/dashboard/widgets', { exclude_widgets: filteredWidget.length === 0 ? [""] : filteredWidget })
      .then((res: { data: any }) => {
        setLoading(false)
        //setDashboardWidgetValue(filteredWidget);

        addToast("Dashboard Widget Updated successfully.", {
          appearance: 'success',
          autoDismiss: true,
        })

        window.location.reload();
      })
      .catch((error: any) => {
        setLoading(false)
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error.response.status === 419) {
          setSErrors(error.response.data)
        } else if (error.response.status === 404) {
          addToast("Please check and try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }

  const passwordChanged = (e: any) => {
    setPassWd(e.target.value)
  }

  useEffect(() => {
    switch (actionType?.type) {
      case 'open':
        setOpenPanel(true)
        Api.get('/user/profile')
          .then((res: { data: any }) => {
            setLoginDetails(res.data)
            // setLastLogins(res.data)
          })
          .catch((error: any) => {
          })
        return
      default:
        return
    }

  }, [actionType])

  const fetchSso = () => {
    Api.get('/sso')
      .then((res: { data: SSOList }) => {
        setSsoData(res.data);
      })
      .catch((error: any) => {
        console.log(error);
      })
  }

  useEffect(() => {
    fetchSso();
  }, []);

  const determineActiveClass = (pathname: string) => {
    const activePaths: string[] = [
      "/deploy/collector",
      "/deploy/sensor",
      "/deploy/supportedCollector",
      "/deploy/manageCollector"
    ];

    return activePaths.some(path => pathname.includes(path)) ? 'active' : '';
  }

  const showChangePassword = () => {
    if (authDetails.role.toString().toLowerCase() == 'root') {
      return (!authDetails.sessionindex || authDetails.role.toString().toLowerCase() == 'root') && !ssoData.root_sso;
    } else {
      return (!authDetails.sessionindex) && !ssoData.enable_sso;
    }
  }


  const Menu = () => {
    return (
      <>
        <div className="nav">
          <ul>
            <li className={(widget_menu.includes(location.pathname) ? 'active' : '')}>
              <Link to="/">Dashboard</Link>
            </li>
            <li className={(location.pathname === "/posture/identities" || location.pathname === "/posture/assets" || location.pathname === "/posture/accesses" || location.pathname === "/posture/directory" ? 'active' : '')}>
              <Link to="/posture/identities?order_by=desc&sort_by=score">Identity Security Posture</Link>
            </li>
            <li className={(location.pathname === "/issues" || location.pathname === "/prevention" ? 'active' : '')}>
              <Link to="/issues?order_by=desc&q=status%3AOpen&sort_by=gen_timestamp">Incidents</Link>
            </li>
            <li className={(location.pathname.includes("/tickets") ? 'active' : '')}>
              <Link to="/tickets?order_by=desc&sort_by=_id">Tickets</Link>
            </li>
            <li className={(location.pathname === "/playbooks" ? 'active' : '')}>
              <Link to="/playbooks?order_by=desc&sort_by=playbook_name">Playbooks</Link>
            </li>
            {authDetails.permissions.Admin.users !== "invisible" ?
              <li className={(admin_menu.includes(location.pathname) ? 'active' : '')}>
                <Link to="/deploy/manageCollector?order_by=desc&sort_by=name&tableIndex=0&rpp=10" onClick={AdminMenu}>Admin</Link>
              </li>
              :
              <li className={(nousers_menu.includes(location.pathname) ? 'active' : '')}>
                <Link to="/deploy/manageCollector?order_by=desc&sort_by=name&tableIndex=0&rpp=10" onClick={NoUsersMenu}>Admin</Link>
              </li>
            }
          </ul>
        </div>
        <div className="header-bar-flex">
          {/* <div className="nav_seperator"></div> */}
          <div className='menu_help'>
            <Tooltip
              /* open={true} */
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
              classes={{
                tooltip: 'menu_help_tooltip'
              }}
              title={
                <div className="menu_profile" style={{ display: 'block' }}>
                  <ul>

                    <li data-left_nav-trigger aria-controls="left_nav-name-left" onClick={() => window.open(`${GlobalConsts.DOC_LINK}?${GlobalConsts.DOC_TOKEN}`, "_blank")}>
                      <Link onClick={(e) => e.preventDefault()}>Documentation</Link></li>
                    <li
                      onClick={() => {
                        const releaseNotes = sessionStorage.getItem("release_notes") || GlobalConsts.RELEASE_LINK;
                        if (releaseNotes) {
                          window.open(`${releaseNotes}?${GlobalConsts.DOC_TOKEN}`, "_blank")
                        }
                      }}
                    ><Link onClick={(e) => e.preventDefault()}>What's New </Link></li>
                  </ul>
                </div>
              }>
              <div><QuestionMarkIcon className='header_bar_help_icon' id='header_bar_help_icon' /></div>
            </Tooltip>
          </div>
          {/* <div className="nav_seperator"></div> */}
          <div className="profile">
            <div className="profile_name" title={authDetails.name.toString()}>{authDetails.name}</div>&#9660;
            <div className="menu_profile">
              <ul style={{ minWidth: isAmopsUser(authDetails.email) ? '170px' : '' }}>
                {isAmopsUser(authDetails.email) && user.tenantName && (
                  <li data-left_nav-trigger aria-controls="left_nav-name-left">
                    <a>{user.tenantName}</a>
                  </li>
                )}
                <li data-left_nav-trigger aria-controls="left_nav-name-left" onClick={() => setOpenPanelRight(true)}><Link onClick={() => setActionType({ id: "", type: "open" })} to={location}>Profile </Link></li>
                <li><Link to="/logout">Logout </Link></li>
              </ul>
            </div>
          </div>
        </div>

      </>
    )
  }


  // useEffect(() => {
  //   const handleResize = () => {
  //     const currentWidth = window.innerWidth;

  //     if (currentWidth < 1440) {
  //       setIsSmallResolution(true);
  //     } else {
  //       if (currentWidth > 2200) {

  //         setIsBigResolution(true);
  //       } else {
  //         setIsBigResolution(false);
  //       }
  //       setIsSmallResolution(false);
  //     }

  //     // Call adjustStyles if you need to adjust the styles based on the new width
  //     // setTimeout(() => { adjustStyles(); }, 0);
  //     if (Math.abs(currentWidth - previousWidth.current) > 200)
  //       // window.location.reload();

  //     // Update the previous width to the current width
  //     previousWidth.current = currentWidth;
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const renderPopup = () => {
    const onCloseHandler = () => {
      setIsBigResolution(false)
      setIsSmallResolution(false)
    }
    return (
      <Popup
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={isBigResolution || isSmallResolution}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        onClose={onCloseHandler}
        modal
      >
        <div className="modal release-notes-modal warning-resolution-modal">
          <div>
            <ScreenshotMonitorIcon
              color='warning'
              className='warning-icon'
              style={{ fontSize: '50px', color: '#fe7106', height: '50px', width: '50px' }}
            >

            </ScreenshotMonitorIcon>
          </div>

          <div className="update-title">Adjust Browser Zoom</div>
          <div className="notes-title">
            {isSmallResolution && <span className="notes-title-text">
              For optimal results, please <b>zoom out</b> using your browser’s zoom.
            </span>}
            {isBigResolution && <span className="notes-title-text">
              For optimal results, please <b>zoom in</b>  using your browser’s zoom.
            </span>}
          </div>



          <div className="popup-container-button-container dialog-button">
            <button type={"button"}
              style={{ marginLeft: 'auto', marginBottom: 0 }}
              id={'Close'}
              className={"float_right button_styled "}
              onClick={onCloseHandler}>
              {'Ok'}
            </button>
          </div>
        </div>
      </Popup >
    )
  }

  return (
    <>
      {/* {renderPopup()} */}
      {authDetails.authenticated && <Ticker />}
      <div className="navbar">
        <div className="navbar_inner">
          <div className={"logo " +
            ((PartnerConfigState.PartnerName == undefined || PartnerConfigState.PartnerName == 'AuthMind') ? ' ' : ' ibm_logo')}
            style={{
              cursor: 'pointer',
              backgroundImage: `url(${PartnerConfigState.PartnerLogoPath})`
            }}
            onClick={() => {
              history.push('/');
            }}
          ></div>

          {authDetails.authenticated &&
            <>
              {/* <div className="nav_icon" data-left_nav-trigger aria-controls="left_nav-name-left" onClick={() => setOpenPanel(true)}></div> */}
              <Menu />
              <SlidingPanel
                type={panelType}
                isOpen={true}
                size={panelSize}
                noBackdrop={true}
                panelClassName="admin-menu-slide"
              >
                {
                  admin_menu.includes(location.pathname) ?
                    <ul>
                      {/* <div className="slide_expand" onClick={() => setPanelSize((panelSize === 18 ? 3 : 18))}>{panelSize == 3 && <> <div className="nav_right_arrow"></div> </>}{panelSize == 18 && <> <div className="nav_left_arrow"></div> </>}</div> */}
                      <div className="clrBoth"></div>
                      <li className={determineActiveClass(location.pathname)}>
                        <AMToolTip arrow={true} title="Sensors, Collectors, and Services">
                          <Link className="navicon_sensor" to="/deploy/manageCollector?order_by=asc&sort_by=name&tableIndex=0&rpp=10" onClick={() => setPanelSize(3)}></Link>
                        </AMToolTip>
                      </li>
                      {authDetails.permissions.Admin.users !== "invisible" ?
                        <li className={(location.pathname === "/users" ? 'active' : '')}><AMToolTip arrow={true} title="Registered Users"><Link className="navicon_user" to="/users?order_by=asc&sort_by=name" onClick={() => setPanelSize((3))}></Link></AMToolTip></li>
                        : null
                      }
                      {/* <li className={(location.pathname === "/groups" ? 'active' : '')}><Link className="navicon_group"to="/groups" onClick= {() => setPanelSize((panelSize === 16 ? 16 : 16))}>Registered Groups</Link></li>  */}
                      <li className={(location.pathname === "/directories" ? 'active' : '')}><AMToolTip arrow={true} title="Known Identity Systems"><Link className="navicon_dir" to="/directories?order_by=asc&sort_by=name" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                      <li className={(location.pathname === "/known_applications" ? 'active' : '')}><AMToolTip arrow={true} title="Known Applications"><Link className="navicon_application" to="/known_applications?order_by=asc&sort_by=name" onClick={() => setPanelSize((3))}></Link></AMToolTip></li>
                      <li className={(location.pathname === "/intermediary" ? 'active' : '')}><AMToolTip arrow={true} title="Known VPN/Firewalls/Gateways/NAT"><Link className="navicon_inter" to="/intermediary?order_by=asc&sort_by=name" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                      <li className={(location.pathname === "/known_public_ip" ? 'active' : '')}><AMToolTip arrow={true} title="Known Internal Public IPs"><Link className="navicon navicon_public_ip" to="/known_public_ip?order_by=asc&sort_by=comment" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                      <li className={(location.pathname === "/mfa" ? 'active' : '')}><AMToolTip arrow={true} title="Known MFA Servers"><Link className="navicon_mfa" to="/mfa" onClick={() => setPanelSize((3))}></Link></AMToolTip></li>
                      {authDetails.permissions.Admin.dirQueryParam !== "invisible" ?
                        <li className={(location.pathname === "/dirQueryParam" ? 'active' : '')}><AMToolTip arrow={true} title="Directory Query"><Link className="navicon_dir_query" to="/dirQueryParam" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }
                      <li className={(location.pathname === "/exceptions" ? 'active' : '')}><AMToolTip arrow={true} title="Exceptions"><Link className="navicon_exceptions" to="/exceptions" onClick={() => setPanelSize((3))}></Link></AMToolTip></li>
                      {/*  {authDetails.permissions.Admin.users !== "invisible" ?
                  <li className={(location.pathname === "/audittrail" ? 'active' : '')}><AMToolTip arrow={true} title="Audit Log"><Link className="navicon_audit" to="/audittrail" onClick= {() => setPanelSize(3)}></Link></AMToolTip></li>
                  : null
                } */}
                      {authDetails.permissions.Admin.proxies !== "invisible" ?
                        <li className={(location.pathname === "/proxy" ? 'active' : '')}><AMToolTip arrow={true} title="Proxies"><Link className="navicon_proxy" to="/proxy?order_by=asc&sort_by=name" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }
                      {authDetails.permissions.Admin.sso !== "invisible" ?
                        <li className={(location.pathname === "/sso" ? 'active' : '')}><AMToolTip arrow={true} title="SSO"><Link className="navicon_sso" to="/sso" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }
                      {authDetails.permissions.Admin.audit_trail !== "invisible" ?
                        <li className={(location.pathname === "/audittrail" ? 'active' : '')}><AMToolTip arrow={true} title="Audit Log"><Link className="navicon_audit" to="/audittrail" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }

                      {authDetails.permissions.Admin.archive_data !== "invisible" ?
                        <li className={(location.pathname === "/archivedata" ? 'active' : '')}><AMToolTip arrow={true} title="Archived Data"><Link className="navicon navicon_archive" to="/archivedata" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }
{/* 
                      {authDetails.permissions.Admin.general_settings !== "invisible" ?
                        <li className={(location.pathname === "/general_settings" ? 'active' : '')}><AMToolTip arrow={true} title="General Settings"><Link className="navicon_settings" to="/general_settings" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }
 */}

                      {authDetails.permissions.Admin.download !== "invisible" ?
                        <li className={(location.pathname === "/sensor_download" ? 'active' : '')}><AMToolTip arrow={true} title="Downloads"><Link className="navicon_download_sensor" to="/sensor_download" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                        : null
                      }

                      {
                        authDetails.permissions.Admin.api_token !== "invisible" ?
                          <li className={(location.pathname === "/token_mg" ? 'active' : '')}><AMToolTip arrow={true} title="API Tokens"><Link className="navicon_api" to="/token_mg?sort_by=expiration_date" onClick={() => setPanelSize(3)}></Link></AMToolTip></li>
                          : null
                      }

                      {/* <li className={(location.pathname === "/dataFeed" ? 'active' : '')}><Link className="navicon_data_breach" to="/dataFeed" onClick= {() => setPanelSize(3)}>Data Breach Feeds</Link></li>  */}
                      {/* <li className={(location.pathname === "/dns" ? 'active' : '')}><Link className="navicon_dns" to="/dns" onClick= {() => setPanelSize(3)}>DNS</Link></li> */}
                      {/* <li className={(location.pathname === "/certificate" ? 'active' : '')}><Link className="navicon_ssl"to="/certificate" onClick= {() => setPanelSize(3)}>Certificate</Link></li> */}
                      {/* <li className={(location.pathname === "/smtp" ? 'active' : '')}><Link className="navicon_smtp" to="/smtp" onClick= {() => setPanelSize(3)}>SMTP</Link></li>  */}
                    </ul> : null
                }

              </SlidingPanel>

              <SlidingPanel
                type={panelTypeWidgets}
                isOpen={true}
                size={panelSizeWidgets}
                noBackdrop={true}
              >
                <div>
                  <div className="slide_expand" onClick={() => setPanelSizeWidgets((panelSizeWidgets === 18 ? 2 : 18))} tabIndex={0} onKeyUp={(e) => {
                    if (e.key == "Enter") {
                      setPanelSizeWidgets((panelSizeWidgets === 18 ? 2 : 18))
                    }
                  }}>
                    {panelSizeWidgets === 2 && <> <div className="nav_right_arrow"></div> </>}
                    {panelSizeWidgets === 18 && <> <div className="nav_left_arrow"></div> </>}
                  </div>
                  <div className="clrBoth"></div>

                  <form onSubmit={handleSubmitDashboard(onUpdateWidget)}>
                    <div>
                      {panelSizeWidgets == 18 ? <ul className="widget_menu">
                        <li className="">
                          <input
                            name="top_new_risky"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.top_new_risky}
                            ref={registerDashboard}
                          />
                          <label className="">Top new risky entities</label>
                        </li>
                        <li className="">
                          <input
                            name="recent_issues"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.recent_issues}
                            ref={registerDashboard}
                          />
                          <label className="">Recent Accesses</label>
                        </li>
                       
                        <li className="">
                          <input
                            name="id_access_infra"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.id_access_infra}
                            ref={registerDashboard}
                          />
                          <label className="">ID and Access Infrastructure</label>
                        </li>
                        <li className="">
                          <input
                            name="shadow_identites"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.shadow_identites}
                            ref={registerDashboard}
                          />
                          <label className="">Shadow Activity</label>
                        </li>
                        <li className="">
                          <input
                            name="unauthorized_access"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.unauthorized_access}
                            ref={registerDashboard}
                          />
                          <label className="">Unauthorized Access</label>
                        </li>
                        <li className="">
                          <input
                            name="credentials_risk"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.credentials_risk}
                            ref={registerDashboard}
                          />
                          <label className="">Credentials Risk</label>
                        </li>

                        <li className="">
                          <input
                            name="unknown_access"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.unknown_access}
                            ref={registerDashboard}
                          />
                          <label className="">Unknown Access</label>
                        </li>
                        <li className="">
                          <input
                            name="protocols"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.protocols}
                            ref={registerDashboard}
                          />
                          <label className="">Risky Protocols</label>
                        </li>
                        <li className="">
                          <input
                            name="issue_trend"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.issue_trend}
                            ref={registerDashboard}
                          />
                          <label className="">Incidents Trend</label>
                        </li>


                        <li className="" style={{ display: 'none' }}>
                          <input
                            name="mfa"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.mfa}
                            ref={registerDashboard}
                          />
                          <label className="">Lack of MFA</label>
                        </li>

                        <li className="" style={{ display: 'none' }}>
                          <input
                            name="password"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.password}
                            ref={registerDashboard}
                          />
                          <label className="">Weak Passwords</label>
                        </li>



                        <li className="" style={{ display: 'none' }}>
                          <input
                            name="compromised"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.compromised}
                            ref={registerDashboard}
                          />
                          <label className="">Compromised</label>
                        </li>

                        <li className="" style={{ display: 'none' }}>
                          <input
                            name="hashes"
                            type="checkbox"
                            className={("margintop20 float_left marginleft20")}
                            defaultChecked={dashboardWidgetValue?.hashes}
                            ref={registerDashboard}
                          />
                          <label className="">Bad Hashes</label>
                        </li>





                        <div className="clrBoth"></div>
                        <li className="margintop20">
                          <div className="margintop20 marginleft20">
                            <button
                              type={(loading ? 'button' : 'submit')}
                              className="button_styled"
                            >Apply</button>
                          </div>
                        </li>

                      </ul> : null}
                    </div>
                  </form>
                </div>
              </SlidingPanel>

              <SlidingPanel
                type={panelTypeRight}
                isOpen={openPanelRight}
                size={panelSizeRight}
                panelClassName="sidepane fix_width"
              >
                <div className="pane_container">
                  <div className="pane_heading">Profile</div>
                  <div className="pane_close" onClick={() => setOpenPanelRight(false)}></div>
                  <div className="hr_ruler"></div>
                  <div className="panel_body">
                    <form>
                      <label>Email</label>
                      <input type="text" className="input_disable" id="userId" value={authDetails.email} name="uid" readOnly />

                      <label>Name</label>
                      <input type="text" className="input_disable" id="userNane" value={authDetails.name} name="uname" readOnly />

                      <label>Role</label>
                      <input type="text" className="input_disable" id="user-role" value={authDetails.role} name="urole" readOnly />

                      <div className="clrBoth margintop20">&nbsp;</div>

                      <label>Last Login Details</label>
                      <div className="clrBoth"></div>
                      <table id="table" className="tablesorter">
                        <thead>
                          <tr>
                            <th>IP Address</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loginDetails?.last_logins?.reverse().filter((item, records) => records < 10).map((item: LastLogins, index: number) => {
                            return <tr>
                              <td>{item.login_ip}</td>
                              <td>{moment(item.login_time).format('MMM DD, YYYY, h:mm A')}</td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                      <div className="footer_line"></div>
                      {/*  {(authDetails.sessionindex==="" || authDetails.sessionindex===undefined)? 
                        <div className="pane_profile_footer">
                          <div className="float_left">
                            <button type="button" className="float_left button_styled" onClick={() => setOpenPasswordPanelRight(true)}>Change Password</button>
                          </div>
                        </div> : null
                        } */}
                      <div className="pane_footer">
                        <div className="launch_button">
                          {
                            ssoData !== null && showChangePassword() ?
                              <button type="button" className="float_left button_styled" onClick={() => setOpenPasswordPanelRight(true)}>Change Password</button>
                              : null
                          }

                          {/* <button type="button" className="float_right button_styled" onClick={() => setOpenPanelRight(false)}>OK</button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </SlidingPanel>

              {/* Change password popup start  */}
              <SlidingPanel
                type={panelTypeRight}
                isOpen={openPasswordPanelRight}
                size={panelSizeRight}
                panelClassName="sidepane fix_width"
              >
                <div className="pane_container">
                  <div className="pane_heading">Change Password</div>
                  <div className="pane_close" onClick={() => setOpenPasswordPanelRight(false)}></div>
                  <div className="hr_ruler"></div>
                  <div className="panel_body">
                    <form onSubmit={handleSubmit(updatePassword)}>
                      <label>Enter Old Password</label>
                      <input
                        type={eyeManager ? 'text' : 'password'}
                        placeholder="Enter Old Password"
                        name="old_password"
                        ref={register({
                          required: true,
                        })}
                        className={(errors.confirm_password ? "error" : "")}
                      />
                      <i className={eyeManager ? 'eye_open_profile' : 'eye_close_profile'} id="togglePassword" onClick={() => ManageEye()}></i>
                      {errors.password && errors.password.type === 'required' && <div className="error marginleft40per">Please enter old password.</div>}
                      {errors.password && errors.password.type !== 'required' && <div className="error marginleft40per">{errors.password.message}</div>}

                      <label>Enter New Password</label>
                      <input
                        type={eyeManager1 ? 'text' : 'password'}
                        placeholder="Enter new password"
                        name="password"
                        ref={register({
                          required: true,
                        })}
                        className={(errors.password ? "error" : "")}
                        onChange={(e) => { passwordChanged(e) }}
                      />
                      <i className={eyeManager1 ? 'eye_open_profile' : 'eye_close_profile'} id="togglePassword" onClick={() => ManageEye1()}></i>
                      {oldPasswordMatch ? null : <div className="error marginleft40per">Old password and new password should not be same.</div>}
                      <PasswordStrengthMeter password={passWd} />
                      {errors.password && errors.password.type === 'required' && <div className="error marginleft40per">Please enter new password.</div>}
                      {errors.password && errors.password.type !== 'required' && <div className="error marginleft40per">{errors.password.message}</div>}
                      {!errors.password && sErrors?.password !== "" && <div className="error marginleft40per">{sErrors?.password}</div>}


                      <label>Confirm New Password</label>
                      <input
                        type={eyeManager2 ? 'text' : 'password'}
                        placeholder="Confirm your password"
                        name="confirm_password"
                        ref={register({
                          required: true,
                        })}
                        className={(errors.confirm_password ? "error" : "")}
                      />
                      <i className={eyeManager2 ? 'eye_open_profile' : 'eye_close_profile'} id="togglePassword" onClick={() => ManageEye2()}></i>
                      {errors.confirm_password && errors.confirm_password.type === 'required' && <div className="error marginleft40per">Please confirm new password.</div>}
                      {errors.confirm_password && errors.confirm_password.type !== 'required' && <div className="error marginleft40per">{errors.confirm_password.message}</div>}
                      {!errors.confirm_password && sErrors?.confirm_password !== "" && <div className="error marginleft40per">{sErrors?.confirm_password}</div>}
                      {passwordMatch ? null : <div className="error marginleft40per">Password not matched.</div>}

                      <div className="font12 error margintop20 marginleft40per">Note: After changing your password, you will be logged out, and you will need to login with the new password.</div>
                      <div className="footer_line"></div>
                      <div className="pane_footer">
                        <div className="launch_button">
                          <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Change</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </SlidingPanel>
            </>
          }
        </div>
      </div>
    </>
  )
}