import React, { useState, useEffect } from "react";
import { BaseProps } from "../../constants/types";
import { DirParamThreshold, SusAttackDisabledState, ThresholdType } from "../../../../types/playbooks-config";
import { ThresholdSelect } from "../../ref/ThresholdSelect";
import { getDefaultConfigState } from "../../helpers/playbook-helper";


const SuspectedDisabledAccount = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SusAttackDisabledState>) => {
    const [state, setState] = useState<SusAttackDisabledState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state?.dir_params, user_disabled_logon_count_curhour: parseInt(e.target.value) } })
    }

    useEffect(() => {
        const user_input1 = state?.dir_params?.user_disabled_logon_count_curhour;
        const object = {
            user_input1
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    const onThresholdSelect = (value) => {
        const defaultSt = getDefaultConfigState(ruleType);
        let curHour = defaultSt?.dir_params?.user_disabled_logon_count_curhour
        const params: DirParamThreshold = {
            allow_ml_modify: false,
            param_name: 'user_disabled_logon_count_curhour',
            threshold_confidence: undefined,
            threshold_type: ThresholdType.MANUAL
        };
        if (value.target.value === ThresholdType.AUTOMATIC) {
            params.allow_ml_modify = true;
            params.threshold_type = value.target.value;
            params.threshold_confidence = 'low'

        }
        setState({ dir_params: { user_disabled_logon_count_curhour: curHour, param_thresholds: [{ ...params }] }, });
    }


    return <>
        <div className="dir-main-container">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || ThresholdType.MANUAL} />
            </div>
            <div className={(state?.dir_params?.param_thresholds?.[0]?.threshold_type === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>
                <div className="dir-container-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        A disabled AD account will be marked as under attack when:</label>
                </div>
                <div className="dir-container-row">
                    <label>
                        Number of login attempts in the current hour is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_disabled_logon_count_curhour}</label> :
                            <input type="number" onChange={handleNumAccWithBadPass} name="num_unauth_attempts" value={state?.dir_params?.user_disabled_logon_count_curhour} />}
                </div>
            </div>
        </div></>
}

export default React.memo(SuspectedDisabledAccount);