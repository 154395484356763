import { IQRadarFormFieldConfig } from "./QRadarFieldForm";

const compare = (a: IQRadarFormFieldConfig, b: IQRadarFormFieldConfig) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
}



export const QRadarFieldArrayNew = [
    {
        name: 'Zscaler ZIA (Web)',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler (ZIA) Web',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Zscaler Nss',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Zscaler ZIA (DNS)',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler ZIA (DNS)',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Zscaler Nss',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'Zscaler ZPA',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler (ZPA)',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Palo Alto Firewall & VPN',
        placeholder: 'Enter comma separated log source ids',
        label: 'Palo Alto Firewall & VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Palo Alto PA Series',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Active Directory',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Active Directory',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Windows Security Event Log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Microsoft Entra ID (Azure AD)',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Azure AD',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Azure Platform',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'AWS VPC Flow Logs',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'AWS VPC flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Amazon AWS Network Firewall',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Azure NSG Flow Logs',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Azure NSG flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Azure Platform',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Cisco Meraki',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Meraki',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Meraki',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'OpenVPN',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Open VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'Check Point Firewall & VPN',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Checkpoint VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Check Point',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'fortinet_utm',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet UTM',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'fortinet_traffic',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet Traffic',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'fortinet_event',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet Event',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'F5 Big-IP APM & VPN',
        placeholder: 'Enter comma separated log source ids',
        label: 'Big F5 VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'F5 Networks BIG-IP APM',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    // {
    //     name: 'perimeter81',
    //     placeholder: 'Log Source Ids (comma separated)',
    //     label: 'Perimeter 81',
    //     placeholder_src_type: 'Enter QRadar Source type'

    // },
    {
        name: 'Cisco Umbrella DNS Security',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella DNS',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_umbrella_proxy',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella Proxy',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_umbrella_ip',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella IP',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    // {
    //     name: 'cato',
    //     placeholder: 'Log Source Ids (comma separated)',
    //     label: 'Cato',
    //     placeholder_src_type: 'Enter QRadar Source type',
    //     default_src_type: ''
    // },
    {
        name: 'Cisco ASA',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco ASA',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Adaptive Security Appliance (ASA)',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Cisco Firepower Threat Defense (FTD)',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco FTD',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Firepower Threat Defense',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'SonicWall Firewall & VPN',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Sonicwall',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'SonicWALL SonicOS',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'Okta IdP',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Okta',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Okta',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_audit',
        placeholder: 'Enter comma separated log source ids',
        label: 'Ping Audit',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_activity',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Ping Activity',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_authentication',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Ping Authentication',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_saml',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace SAML',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_login',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace Login',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_oauth',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace OAuth',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'gcp_vpc',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'GCP VPC flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'IBM Verify Access',
        placeholder: 'Enter comma separated index names',
        label: 'IBM Verify Access',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'IBM Security Verify',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: false
    },
    {
        name: 'Microsoft AD (via SIEM)',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Active Directory',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Windows Security Event Log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    }
].sort(compare) as Array<IQRadarFormFieldConfig>;


export const PROTOCOL_HEADERS = [
    { label: 'Syslog (RFC3164)', value: 'syslog_rfc_3164' },
    { label: 'Syslog (RFC5424)', value: 'syslog_rfc_5424' },
    { label: 'Syslog (RFC3164+LEEF)', value: 'syslog_rfc_3164_with_leef' },
    { label: 'Syslog (RFC5424+LEEF)', value: 'syslog_rfc_5424_with_leef' },
    { label: 'LEEF', value: 'leef' },
    { label: 'None', value: 'none' },
    { label: 'Other', value: 'other' }
];



export const QRadarFieldArray = [
    {
        name: 'zscaler_zia_web',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler (ZIA) Web',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Zscaler Nss',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'zscaler_zia_dns',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler (ZIA) DNS',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Zscaler Nss',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'zscaler_zpa_user_activity',
        placeholder: 'Enter comma separated log source ids',
        label: 'Zscaler (ZPA)',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'palo_alto',
        placeholder: 'Enter comma separated log source ids',
        label: 'Palo Alto VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Palo Alto PA Series',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'on_prem_ad',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Active Directory',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Windows Security Event Log',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'azure_ad_signin',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Azure AD',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Azure Platform',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'aws_vpc',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'AWS VPC flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Amazon AWS Network Firewall',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'azure_nsg',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Azure NSG flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Microsoft Azure Platform',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_meraki',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Meraki',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Meraki',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'openvpn',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Open VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'checkpoint_vpn',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Checkpoint VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Check Point',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true
    },
    {
        name: 'fortinet_utm',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet UTM',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'fortinet_traffic',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet Traffic',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'fortinet_event',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Fortinet Event',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Fortinet FortiGate Security Gateway',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'f5vpn',
        placeholder: 'Enter comma separated log source ids',
        label: 'Big F5 VPN',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'F5 Networks BIG-IP APM',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    // {
    //     name: 'perimeter81',
    //     placeholder: 'Log Source Ids (comma separated)',
    //     label: 'Perimeter 81',
    //     placeholder_src_type: 'Enter QRadar Source type'

    // },
    {
        name: 'cisco_umbrella_dns',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella DNS',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_umbrella_proxy',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella Proxy',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_umbrella_ip',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco Umbrella IP',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Umbrella',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    // {
    //     name: 'cato',
    //     placeholder: 'Log Source Ids (comma separated)',
    //     label: 'Cato',
    //     placeholder_src_type: 'Enter QRadar Source type',
    //     default_src_type: ''
    // },
    {
        name: 'cisco_asa',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco ASA',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Adaptive Security Appliance (ASA)',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'cisco_ftd',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Cisco FTD',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Cisco Firepower Threat Defense',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'sonicwall',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Sonicwall',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'SonicWALL SonicOS',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'okta',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Okta',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'Okta',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_audit',
        placeholder: 'Enter comma separated log source ids',
        label: 'Ping Audit',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_activity',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Ping Activity',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'ping_authentication',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Ping Authentication',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_saml',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace SAML',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_login',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace Login',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'google_workspace_oauth',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'Google Workspace OAuth',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:false

    },
    {
        name: 'gcp_vpc',
        placeholder: 'Log Source Ids (comma separated)',
        label: 'GCP VPC flow logs',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: '',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone:true

    },
    {
        name: 'ibm_security_verify',
        placeholder: 'Enter comma separated index names',
        label: 'IBM Verify SaaS',
        placeholder_src_type: 'Enter QRadar Source type',
        default_src_type: 'IBM Security Verify',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    }
].sort(compare) as Array<IQRadarFormFieldConfig>;