import React, { useEffect, useRef, useState } from "react";
import './weak-pass.css'
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";

type Props = {
  parentState: any,
  isSavedOnce: boolean,
  getState: (state: any) => void,
  defaultState: any,
  formType?: string
}

const WeakPasswordConfig = ({ parentState, isSavedOnce, getState, defaultState, formType }: Props) => {

  const [state, setState] = useState(defaultState);

  const durationRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const dollarRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (defaultState) {
      setState(defaultState);
    }
  }, [defaultState])

  useEffect(() => {
    if (isSavedOnce) {
      const isDollarChecked = parentState?.isDollarChecked || parentState?.IsDollarChecked;
      setState({ ...parentState, isDollarChecked: isDollarChecked });
      if (!parentState.isTimeChecked || (parentState.isTimeChecked && (parentState.unit === undefined || parentState.duration === undefined))) {
        if (durationRef?.current) {
          durationRef.current.value = "";
        }
      }
      if (!isDollarChecked || (isDollarChecked && parentState.dollar === undefined)) {
        if (dollarRef?.current)
          dollarRef.current.value = "";
      }
    }
  }, [isSavedOnce])

  useEffect(() => {
    getState(state);
  })


  const getItemLabel = (op) => {
    if (op?.hasOwnProperty('key')) {
      if (op?.key) {
        return op.value
      } else {
        return ''
      }
    }
    return op || ''
  }

  return (
    <>
      <form action="/">
        <div className="playbook-config-title">
          A password will be considered weak if:
        </div>
        <div className="width40per float_left">
          <input type="checkbox" id="time-checkbox" name="time-checkbox" checked={state?.isTimeChecked}
            className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
            onChange={(e) => {
              if (formType && ['edit', 'view'].includes(formType))
                return;
              if (e.target.checked) {
                setState({
                  ...state,
                  isTimeChecked: true
                })
              }
              else {
                durationRef.current.value = "";
                setState({
                  ...state,
                  isTimeChecked: false,
                  /*   duration: undefined,
                    unit: undefined */
                })
              }
            }}
          />
          <label className=""
            /* htmlFor="time-checkbox" */
            style={{ float: "none" }}>
            Password takes less than
            <div className="clrBoth margintop10"></div>
            <div className="policy_in policy_overlaping">
              {
                formType && ['edit', 'view'].includes(formType) ?
                  <span className="margin-left-5">
                    {state?.duration} {state?.unit} to crack
                  </span> :
                  <>
                    <input ref={durationRef} type="number" min={1} id="time" name="time" value={state?.duration}
                      disabled={!state?.isTimeChecked}
                      onChange={(e) => {
                        if (formType && ['edit', 'view'].includes(formType))
                          return;
                        setState({
                          ...state,
                          duration: +e.target.value
                        })
                      }}
                    />
                    <CheckboxAutocomplete
                      options={['seconds', 'minutes', 'hours'].map((i) => ({ key: i, value: i }))}
                      getItemLabel={getItemLabel}
                      classes={{ root: 'weak-pass-sel' }}
                      onChange={(e, config) => {
                        if (formType && ['edit', 'view'].includes(formType))
                          return;
                        setState({
                          ...state,
                          unit: config[0].value
                        })
                      }}
                      hideClearBtn={true}
                      value={{ key: state?.unit, value: state?.unit }}
                      disabled={!state?.isTimeChecked}
                    />
                    to crack
                  </>
              }
            </div>
          </label>
        </div>
        <div className="width10per float_left">
          <label className="">OR</label>
        </div>
        <div className="width40per float_left">
          <input type="checkbox" id="dollar-checkbox" name="dollar-checkbox" checked={state?.isDollarChecked}
            className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
            onChange={(e) => {
              if (formType && ['edit', 'view'].includes(formType))
                return;
              if (e.target.checked) {
                setState({
                  ...state,
                  isDollarChecked: true
                })
              }
              else {
                dollarRef.current.value = "";
                setState({
                  ...state,
                  isDollarChecked: false,
                  /*  dollar: undefined */
                })
              }
            }}
          />
          <label className=""
            /* htmlFor="dollar-checkbox" */
            style={{ float: "none" }}>
            Password takes less than
            {/* <div className="clrBoth margintop10"></div>
            <div className="policy_in" style={{ marginLeft: 30, float:"left", marginRight:4 }}>
              <input type="number" id="dollar" name="dollar" value={state.dollar} className="" 
                  onChange={(e)=>{
                      setState({
                          ...state,
                          dollar: e.target.value
                      })
                  }}
              />
            </div>
            dollars to crack */}

            <div className="clrBoth margintop10"></div>
            {
              formType && ['edit', 'view'].includes(formType) ?
                <span className="margin-left-35">{state?.dollar} dollars to crack</span> :
                <div className="policy_in policy_overlaping">
                  <input ref={dollarRef} type="number" min={1} id="dollar" name="dollar" value={state?.dollar} className=""
                    disabled={!state?.isDollarChecked}
                    onChange={(e) => {
                      if (formType && ['edit', 'view'].includes(formType))
                        return;
                      setState({
                        ...state,
                        dollar: +e.target.value
                      })
                    }}
                  />
                  dollars to crack
                </div>
            }
          </label>
        </div>

        <div className="clrBoth"></div>
      </form>
    </>
  );
};

export default React.memo(WeakPasswordConfig);
