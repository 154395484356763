import React from 'react';

const UnauthorizedLoginTimeView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An AD account will be marked as having repeated login
                    attempts at invalid time when number of login attempts in the current hour exceeds{' '}
                    <strong>
                        {
                            config?.dir_params
                                .user_unauthorized_time_count_curhour
                        }
                    </strong>
                </span>
            </li>
        </>
    );
};

export default React.memo(UnauthorizedLoginTimeView);
