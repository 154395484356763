import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { Api } from "../../components/Axios";
import CheckboxAutocomplete from "../../components/core/AMAutoComplete/AMAutoComplete";
import CollectorCard from "./collectorCard";
import { SensorPopup } from "./SensorPopup";
import { FlowSensorContainer } from "./sub/FlowSensorContainer";
import { CollectorConfigurationContainer } from "./sub/CollectorConfigurationContainer";
import { SiemConfiguration } from "./sub/SiemConfiguration";
import { POPUP_SUB_TITLE_META } from "./constants/sensor-labels";
import { isAmopsUser } from "../../utils/util-methods";
import "./supportedCollector.scss";
import { ITableResult } from "../../components/core/AMTable/AMAdminTable";
import { useAuthDetails } from "../../components/Authorization";

const SupportedCollector: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<any[]>([]);
  const [groupedData, setGroupedData] = useState<any>({});
  const [selectedVal, setSelectedVal] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [configurationData, setConfigurationData] = useState(null);
  const [showCollectorConfiguration, setShowCollectorConfiguration] =
    useState(false);
  const [showSiemConfiguration, setShowSiemConfiguration] = useState(false);
  const [showFlowSensor, setShowFlowSensor] = useState(false);
  const [showADSensor, setShowADSensor] = useState(false);
  const [showADLDSSensor, setShowADLDSSensor] = useState(false);
  const [showADSensorWEF, setShowADSensorWEF] = useState(false);
  const [showADLDSSensorWEF, setShowADLDSSensorWEF] = useState(false);
  const userDetails = localStorage.getItem('user') as string;
  const user = JSON.parse(userDetails);
  const { authDetails } = useAuthDetails()!;
  const [addata, setAddata] = useState<
  ITableResult<any> | any
>();
const [networkdata, setNetworkdata] = useState<
  ITableResult< any> | any
>();
const [onpremdata, setOnpremdata] = useState<
  ITableResult<any> | any
>();

  const fetchSupportedCollectorList = () => {
    Api.get(`/collectors/supported`)
      .then((resp: any) => {
        const data = resp.data as any;
        setData(data);
      })
      .catch(() => alert("error"));
  };


  const fetchAdCollectorList = () => {
    Api.get(`/collectors/managed?category=ad-cloud-idp`)
      .then((resp: any) => {
        const data = resp.data as ITableResult<any>;
        setAddata(data);
      })
      .catch(() =>{

      }
       
        )
  };

  const fetchNetworkCollectorList = () => {
    Api.get(`/collectors/managed?category=network-access`)
      .then((resp: any) => {
        const data = resp.data as ITableResult<any>;

        if (isAmopsUser(authDetails.email)) {
          setNetworkdata(resp.data);
        } else {
          const filteredResult = resp.data.result.filter(
            (item: any) =>
              item.collector_data_source !== "Evernorth Control Center"
          );
          const filteredData = {
            ...resp.data,
            result: filteredResult,
          };
          setNetworkdata(filteredData);
        }
      })
      .catch(() =>
       {}
        )
  };


  useEffect(() => {
    fetchAdCollectorList();
    fetchNetworkCollectorList();

    fetchSupportedCollectorList();
  }, []);

  useEffect(() => {
    if (data) {
      const grouped = data.reduce((acc: any, curr: any) => {
        const { category } = curr;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(curr);
        return acc;
      }, {});
      setGroupedData(grouped);
    }
  }, [data]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    if (category) {
      const decodedCategory = decodeURIComponent(category);
      const selectedOption = options.find(
        (option) => option.value === decodedCategory
      );
      setSelectedVal(selectedOption ? [selectedOption] : []);
    }
  }, [location.search]);

  const sortCategories = (categories: string[], filter: any) => {
    const desiredOrder = [
      "SIEM",
      "Active Directory & Cloud IdPs",
      "Network Access",
      "Cloud & On-Premise Infrastructure",
    ];

    if (filter && filter[0] && filter[0] !== null && !['collect via splunk', 'collect via ibm qradar', 'collect via sumologic'].includes(filter[0].value.toLowerCase())) {
      categories = categories.filter((category) => category === filter[0].value);
    } 

    if (filter && filter[0] && filter[0] !== null && ['collect via splunk', 'collect via ibm qradar', 'collect via sumologic'].includes(filter[0].value.toLowerCase())){
      categories = [
        "Active Directory & Cloud IdPs",
        "Network Access",
        "Cloud & On-Premise Infrastructure",
      ];
    }

    return categories.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));
  };

  const options = [
    { key: "SIEM", value: "SIEM" },
    { key: "ADCI", value: "Active Directory & Cloud IdPs" },
    { key: "NA", value: "Network Access" },
    { key: "COP", value: "Cloud & On-Premise Infrastructure" },
    { key: "QRADAR", value: "Collect via IBM Qradar", showSeparator: true },
    { key: "SPLUNK", value: "Collect via Splunk" },
    { key: "SUMOLOGIC", value: "Collect via Sumologic" },
  ];

  const handleSelectionChange = (event: any, filteredValue: any) => {
    setSelectedVal(filteredValue);
    const selectedCategory = filteredValue[0]?.value || null;
    if (selectedCategory) {
      history.push(`?category=${encodeURIComponent(selectedCategory)}`);
    } else {
      history.push(location.pathname);
    }
  };

  const getItemLabel = (
    option: { key: string; value: string } | { key: string; value: string }[]
  ) => {
    if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
    } else {
      return option?.value ? option?.value : "";
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filterItemsBySearchQuery = (items: any[]) => {
    return searchQuery
      ? items.filter((item) =>
          item.collector_data_source
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        )
      : items;
  };

  const onAddCollector = (data) => {
    setConfigurationData(data);
    if (data && data["category"] === "SIEM") {
      setShowSiemConfiguration(true);
    } else if ( data && (data.collector_data_source === "Microsoft AD (Domain Controller) Agent" )) {
      setShowADSensor(true);
    } else if ( data && data.collector_data_source === "Microsoft AD (Windows Event Forwarding) Agent") {
      setShowADSensorWEF(true); 
    }
     else if ( data && data.collector_data_source === "Microsoft AD-LDS (Domain Controller) Agent"
    ) {
      setShowADLDSSensor(true);
    } else if ( data && data.collector_data_source === "Microsoft AD-LDS (Windows Event Forwarding) Agent") {
      setShowADLDSSensorWEF(true);
    }
    else {
      setShowCollectorConfiguration(true);
    }
  };

  const getSubTitle = (type) => {
    if (/qradar/i.test(type) && /saas/i.test(type)) {
      return POPUP_SUB_TITLE_META.QRADAR_SAAS.title;
    } else if (/qradar/i.test(type)) {
      return POPUP_SUB_TITLE_META.QRADAR.title;
    } else if (/splunk/i.test(type) && /cloud/i.test(type)) {
      return POPUP_SUB_TITLE_META.SPLUNK_CLOUD.title;
    } else if (/splunk/i.test(type)) {
      return POPUP_SUB_TITLE_META.SPLUNK.title;
    } else if (/sumo/i.test(type)) {
      return POPUP_SUB_TITLE_META.SUMOLOGIC.title;
    }
  };

  const getGuideLink = (type) => {
    if (/qradar/i.test(type) && /saas/i.test(type)) {
      return POPUP_SUB_TITLE_META.QRADAR_SAAS.guideLink;
    } else if (/qradar/i.test(type)) {
      return POPUP_SUB_TITLE_META.QRADAR.guideLink;
    } else if (/splunk/i.test(type) && /cloud/i.test(type)) {
      return POPUP_SUB_TITLE_META.SPLUNK_CLOUD.guideLink;
    } else if (/splunk/i.test(type)) {
      return POPUP_SUB_TITLE_META.SPLUNK.guideLink;
    } else if (/sumo/i.test(type)) {
      return POPUP_SUB_TITLE_META.SUMOLOGIC.guideLink;
    }
  };

  const renderCollectorCards = (items , isDisabled ,category) => {
    const sortedItems = items
      .filter((item) => isAmopsUser(user.email) || !item.is_only_amops)
      .sort((a, b) => a.vendor_name.localeCompare(b.vendor_name));
      
    return sortedItems.map((item) => (
      <CollectorCard
        key={item._id}
        item={item}
        onAddCollector={() => onAddCollector(item)}
        isDisabled={isDisabled}
        category = {category}
      
      />
    ));
  };

  const extractFilterKey = (value) => {
    const parts = value.toLowerCase().split(' ');
    return parts[parts.length - 1];
  };

  const setIsDisabled = (category:string) => {
    
    if (isAmopsUser(authDetails.email)) {
      return false;

    }
    
    if(category === 'Active Directory & Cloud IdPs'){
      return false;
    }

    if (category === 'Network Access'){
      if(addata?.result?.length === 0 ){
        return true
      } else {
        return false;
      }
    }

    if (category === 'Cloud & On-Premise Infrastructure'){
      if(networkdata?.result?.length === 0 ){
        return true
      } else {
        return false;
      }
    }

    return false;


  }

  return (
    <>
      <Box style={{ paddingBottom: "20px" }}>
        <div
          className="page_title_area supported-collector-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div className="autocom">
            <CheckboxAutocomplete
              label="Select Option"
              options={options}
              value={selectedVal}
              onChange={handleSelectionChange}
              getItemLabel={getItemLabel}
              multiple={false}
              showSeparator={true}
            />
          </div>

          <div className="search_container issue_page scrollbar-container marginleft10">
            <div
              className="search_white_icon flex_none"
              style={{ cursor: "pointer" }}
            >
              &nbsp;
            </div>
            &nbsp;
            <input
              className="search_input search-input-width flex_none admin-page"
              value={searchQuery}
              placeholder="Collector/SIEM name"
              onChange={handleSearchChange}
              name="query"
            />
          </div>
        </div>

        {sortCategories(Object.keys(groupedData), selectedVal).map(
          (category, index) => {
            const items = filterItemsBySearchQuery(groupedData[category]);
            const filterKeys = ["splunk", "qradar", "sumologic"];
            const filteredItems =
              selectedVal.length > 0 &&
              filterKeys.includes(extractFilterKey(selectedVal[0].value))
                ? items.filter(item => item[`is_via_${extractFilterKey(selectedVal[0].value)}`])
                : items;

            const isDisabled = setIsDisabled(category);
            return (
              <div
                className={index === 0 ? "first-div" : "table-distance"}
                style={{ clear: "both" }}
                key={category}
              >
                <div className="table-header">
                  {category}
                </div>
                <div className="supported-container">
                  {renderCollectorCards(filteredItems ,  isDisabled , category
                  )}
                </div>
              </div>
            );
          }
        )}
      </Box>

      {showCollectorConfiguration && (
        <SensorPopup
          isOpen={showCollectorConfiguration}
          onCloseHandler={() => {
            setShowCollectorConfiguration(false);
          }}
          title={`Add ${configurationData.collector_data_source} collector`}
          classNames="sensor-modal-popup-container"
          logoClass={configurationData.vendor_name
            .replace(/\s+/g, "_")
            .toLowerCase()}
        >
          <CollectorConfigurationContainer
            onCloseHandler={() => {
              setShowCollectorConfiguration(false);
            }}
            collectorName={configurationData.collector_data_source}
            id={""}
            data={configurationData}
            setShowFlowSensor={setShowFlowSensor}
          />
        </SensorPopup>
      )}
      {showSiemConfiguration && (
        <SensorPopup
          isOpen={showSiemConfiguration}
          onCloseHandler={() => {
            setShowSiemConfiguration(false);
          }}
          title={`Add ${configurationData.collector_data_source} sensor`}
          classNames="sensor-modal-popup-container"
          logoClass={configurationData.vendor_name
            .replace(/\s+/g, "_")
            .toLowerCase()}
          subTitle={getSubTitle(configurationData.collector_data_source)}
          guideLink={getGuideLink(configurationData.collector_data_source)}
        >
          <SiemConfiguration
            onCloseHandler={() => {
              setShowSiemConfiguration(false);
            }}
            collectorName={configurationData.collector_data_source}
            id={""}
            data={configurationData}
          />
        </SensorPopup>
      )}
      {showFlowSensor && (
        <SensorPopup
          isOpen={showFlowSensor}
          onCloseHandler={() => {
            setShowFlowSensor(false);
          }}
          title={"Add Flow Sensor"}
          subTitle="Leverage a Flow Sensor to collect from one or more local data sources and/or local SIEMs."
          guideLink="#"
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="3"
            onCloseHandler={() => {
              setShowFlowSensor(false);
            }}
          />
        </SensorPopup>
      )}
      {showADSensor && (
        <SensorPopup
          isOpen={showADSensor}
          onCloseHandler={() => {
            setShowADSensor(false);
          }}
          title={`Add AD (Domain Controller) Agent`}
          subTitle="Leverage an AD (Domain Controller) Agent to collect events and insights from a Microsoft AD Directory."
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="1"
            onCloseHandler={(val) => {
              setShowADSensor(false);
              if (val === 'ok') {
                history.push("/deploy/sensor");
              }
            }}
          />
        </SensorPopup>
      )}
          {showADSensorWEF && (
        <SensorPopup
          isOpen={showADSensorWEF}
          onCloseHandler={() => {
            setShowADSensorWEF(false);
          }}
          title={`Add AD (Windows Event Forwarding) Agent`}
          subTitle="Leverage an AD (Windows Event Forwarding) Agent to collect events and insights from a Microsoft AD Directory."
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="18"
            onCloseHandler={(val) => {
              setShowADSensorWEF(false);
              if (val === 'ok') {
                history.push("/deploy/sensor");
              }
            }}
          />
        </SensorPopup>
      )}
       {showADLDSSensor && (
        <SensorPopup
          isOpen={showADLDSSensor}
          onCloseHandler={() => {
            setShowADLDSSensor(false);
          }}
          title={`Add AD-LDS (Domain Controller) Agent`}
          subTitle="Leverage an  AD-LDS (Domain Controller) Agent to collect events and insights from a Microsoft AD LDS Directory."
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="17"
            onCloseHandler={(val) => {
              setShowADLDSSensor(false);
              if (val === 'ok') {
                history.push("/deploy/sensor");
              }
            }}
          />
        </SensorPopup>
      )}
      {showADLDSSensorWEF && (
        <SensorPopup
          isOpen={showADLDSSensorWEF}
          onCloseHandler={() => {
            setShowADLDSSensorWEF(false);
          }}
          title={`Add AD LDS Sensor WEF`}
          subTitle="Leverage an AD (Windows Event Forwarding) Agent  to collect events and insights from a Microsoft AD LDS Directory."
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="19"
            onCloseHandler={(val) => {
              setShowADLDSSensorWEF(false);
              if (val === 'ok') {
                history.push("/deploy/sensor");
              }
            }}
          />
        </SensorPopup>
      )}
    </>
  );
};

export default SupportedCollector;
