import "./top-issues-skeleton.scss";

export const TopIssuesSkeleton = () => {
    return (
        <>
            <div className="skeleton-top-issues-container animate-pulse">
                <div className="skeleton-title"></div>
                <div className="skeleton-content">
                    <div className="skeleton-header-row"></div>
                    <div className="skeleton-rows">
                    {[...Array(3).keys()].map((p) => {
                        return <div key={p} className="skeleton-row"></div>;
                    })} 
                    </div>
                </div>
            </div>
        </>
    );
};
