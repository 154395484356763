import { makeStyles } from '@material-ui/core/styles';
import {
  Autocomplete,
  MenuItem,
  Select
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { camelCase } from "./posture-filter-utils";
import useValidate from '../hooks/useValidate';
import { getValueOnType } from "./posture-filter-utils";
import CheckboxAutocomplete from '../AMAutoComplete/AMAutoComplete';

const useStyles = makeStyles({
  select: {
    "& ul": {
      paddingTop: '0px',
      paddingBottom: '0px',
      borderRadius: '0px'
    },
    "& li": {
      '&:hover': {
        backgroundColor: 'rgb(104 104 104 / 14%)',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&:hover': {

          backgroundColor: 'rgb(104 104 104 / 14%)',
        }
      }
    },
  },
});
const epochToDate = function (EPOC) {
  try {
    if (EPOC) {
      const date = new Date(EPOC * 1000); //received timestamp in seconds, convert it to miliseconds.
      if (moment(date).isValid()) {
        return date;
      } else {
        return "";
      }
    }
    return "";
  } catch (error) {
    console.log(error);
    return "";
  }
};

const dateToEpoch = function (dateString) {
  try {
    const dateVal = new Date(new Date(dateString).setSeconds(0, 0));
    // console.log(`resetting datetime seconds to 0....${dateVal}`);
    if (moment(dateVal.toUTCString()).isValid()) {
      const epochVal = new Date(dateVal.toUTCString()).getTime();
      return epochVal / 1000; // send timestamp in seconds.
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

function restrictToCommaSeperatedNumeric(event) {
  if (
    (event.which < 48 || event.which > 57) &&
    event.which !== 8 &&
    event.which !== 44 &&
    event.which !== 45
  ) {
    event.preventDefault();
    return false;
  }
}
function restrictToNumeric(event) {
  if ((event.which < 48 || event.which > 57) && event.which !== 8 && event.which !== 45) {
    event.preventDefault();
    return false;
  }
}

function isObjectArray(arr){
  return arr.every((item) => typeof item === 'object' && !Array.isArray(item));
}
const FilterInput = React.forwardRef((props, ref) => {
  const {
    onChange,
    value,
    controlType = "text",
    id,
    controlValues,
    validations,
    placeholderText = "",
    casing = '',
    filterChanged,
    operatorChanged
  } = props;
  const classes = useStyles();
  const [highlightErrorInput, setHighlightErrorInput] = useState(false);
  const { validate } = useValidate();
  const [isValid, setIsValid] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const InputControlRef = useRef();
  const currentDate = new Date();
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [previousEvent, setPreviousEvent] = useState('');
  const inputValueRef = useRef(inputValue);
  useImperativeHandle(ref, () => {
    return {
      isValid: () => isValid,
    };
  });

  const highlightIfError = (inputValue) => {
    if (!inputValue) {
      setHighlightErrorInput(true);
    } else {
      setHighlightErrorInput(false);
    }
  };

  const dateTimeInputChangeHandler = (event) => {
    const datetime = event || "";
    // s_time:1669035600
    // e_time:1669039200
    //const epochDateTime = Date.parse(new Date(datetime).toUTCString())/1000;
    const isInputValid = validate(validations, datetime);
    setIsValid(isInputValid);
    setInputValue(dateToEpoch(datetime));
  };

  const dateTimeRangeInputChangeHandler = (event, dateType) => {
    const datetime = event || "";
    let isValid = true;
    if (dateType === "start-date") {
      setInputValue((prevValue) => {
        // isValid = validate(datetime,epochToDate(prevValue?.e_date))
        const oldValue = prevValue?.[1] || value[1];
        setIsValid(true);
        return [dateToEpoch(datetime), oldValue];
        //return { ...prevValue, s_date: dateToEpoch(datetime) };
      });
    } else if (dateType === "end-date") {
      setInputValue((prevValue) => {
        //isValid = validate(epochToDate(prevValue?.s_date),datetime);
        const oldValue = prevValue?.[0] || value[0];
        setIsValid(true);
        return [oldValue, dateToEpoch(datetime)];
        //return { ...prevValue, e_date: dateToEpoch(datetime) };
      });
    }
  };

  const textInputChangeHandler = (event) => {
    const inputValue = event.target.value;
    const isInputValid = validate(validations, inputValue);
    setIsValid(isInputValid);
    setInputValue(inputValue);
  };

  const numberArrayInputChangeHandler = (event) => {
    const inputValue = event.target.value;
    const isInputValid = validate(validations, inputValue);
    setIsValid(isInputValid);
    setInputValue(inputValue);
  };

  const numberRangeInputChangeHandler = (event, index) => {
    const inpValue = event.target.value;
    if (value) {
      const updatedValue = [...value];
      updatedValue[index] = inpValue;
      const isInputValid = validate(validations, updatedValue);
      setIsValid(isInputValid);
      setInputValue(updatedValue);
    } else {
      const updatedValue = [];
      updatedValue[index] = inpValue;
      const isInputValid = validate(validations, updatedValue);
      setIsValid(isInputValid);
      setInputValue(updatedValue);
    }
  };

  const textArrayInputChangeHandler = (event) => {
    const inpValue = event.target.value;
    const values = inpValue?.split(",");
    if (inpValue === "") {
      setIsValid(false);
      setInputValue(values);
    } else {
      const isInputValid = validate(validations, values);
      setIsValid(isInputValid);
    }
    setInputValue(values);
  };

  const MultiSelectArrayInputChangeHandler = (event, filterValue) => {
    try {
      const selectedValues = Array.from(new Set(filterValue.map((i) => i?.key || i?.value || i)));
      
      const inputValue = isObjectArray(controlValues)? selectedValues : selectedValues?.filter(p => { return controlValues?.includes(p) });
      const isInputValid = validate(validations, inputValue);
      setIsValid(isInputValid);
      setInputValue(inputValue);
    } catch (error) {
      console.log(error);
    }
  };

  const SelectArrayInputChangeHandler = (event, filterValue) => {
    try {
      const selectedValues = Array.from(new Set(filterValue.map((i) => i.value || i)));
      const isInputValid = validate(validations, selectedValues);
      setIsValid(isInputValid);
      setInputValue(selectedValues);
    } catch (error) {
      console.log(error);
    }

  };

  const AutoCompleteInputChangeHandler = (event, value) => {
    setIsValid(true);
    const selectedValues = Array.from(new Set(value.map((i) => i.key || i)));
    setInputValue(selectedValues);
  };

  const numberInputChangeHandler = (event, index) => {
    const inputValue = event.target.value;
    const isInputValid = validate(validations, inputValue);
    setIsValid(isInputValid);
    setInputValue(inputValue);
  };

  const timeNumberChangeHandler = (event, index) => {
    const inpValue = event.target.value;
    if (value && value.length > 0) {
      const updatedValue = [...value];
      updatedValue[index] = inpValue;
      if (index === 1) {
        setIsValid(true);
        setInputValue(updatedValue);
      } else {
        const isInputValid = validate(validations, updatedValue[0]);
        setIsValid(isInputValid);
        setInputValue(updatedValue);
      }
    } else {
      const updatedValue = [];
      updatedValue[index] = inpValue;
      if (index === 1) {
        setIsValid(true);
        setInputValue(updatedValue);
      } else {
        const isInputValid = validate(validations, updatedValue[0]);
        updatedValue[1] = controlValues[0]
        setIsValid(isInputValid);
        setInputValue(updatedValue);
      }
    }

  };

  const onChangeHandler = (event, ...rest) => {
    switch (controlType) {
      case "datetime":
        dateTimeInputChangeHandler(event);
        break;
      case "datetime-range":
        dateTimeRangeInputChangeHandler(event, rest[0]);
        break;
      case "text":
        textInputChangeHandler(event);
        break;
      case "number":
        numberInputChangeHandler(event, rest[0]);
        break;
      case "number-range":
        numberRangeInputChangeHandler(event, rest[0]);
        break;
      case "number-array":
        numberArrayInputChangeHandler(event);
        break;
      case "text-array":
        textArrayInputChangeHandler(event);
        break;
      case "multi-select":
        MultiSelectArrayInputChangeHandler(event, rest[0]);
        break;
      case "select":
        SelectArrayInputChangeHandler(event, rest[0]);
        break;
      case "array-autocomplete":
        AutoCompleteInputChangeHandler(event, rest[0]);
        break;
      case "time-number":
        timeNumberChangeHandler(event, rest[0]);
        break;
      default:
        textInputChangeHandler(event);
    }
  };

  const getItemLabel = (
    option, options
  ) => {        
   if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
   } else if (typeof option == 'string') {
      return option     
   } else {
     let label = option?.value ? option?.value : "";
     if (option?.key && options?.length) {
       const l = options.filter((i) => i?.key == option.key)[0]
       if (l?.value) {
        return l.value
       }
     }
      return label;
    }        
  };

  const renderControl = () => {
    switch (controlType) {
      case "text":
        return (
          <input
            ref={InputControlRef}
            placeholder={placeholderText}
            type="text"
            id={id}
            key={id}
            name={id}
            defaultValue={value}
            onChange={onChangeHandler}
            className={`${highlightErrorInput ? "error" : ""}`}
          />
        );
      case "number":
        return (
          <>
            <input
              ref={InputControlRef}
              onKeyPress={restrictToNumeric}
              placeholder={placeholderText}
              type="text"
              id={id}
              key={id}
              name={id}
              defaultValue={value}
              onChange={onChangeHandler}
              className={`${highlightErrorInput ? "error" : ""}`}
            />
          </>
        );
      case "number-array":
        return (
          <>
            <input
              ref={InputControlRef}
              onKeyPress={restrictToCommaSeperatedNumeric}
              placeholder={placeholderText}
              type="text"
              id={id}
              key={id}
              name={id}
              defaultValue={value}
              onChange={onChangeHandler}
              className={`${highlightErrorInput ? "error" : ""}`}
            />
          </>
        );
      case "number-range":
        return (
          <div className="number-range">
            <input
              type="text"
              onKeyPress={restrictToNumeric}
              id={`${id}1`}
              key={`${id}1`}
              name={`${id}1`}
              defaultValue={value?.[0] || ""}
              onChange={(event) => {
                onChangeHandler(event, 0);
              }}
              className={`${highlightErrorInput ? "error" : ""}`}
              placeholder="Enter numeric value"
            />
            <input
              type="number"
              id={`${id}2`}
              key={`${id}2`}
              name={`${id}2`}
              defaultValue={value?.[1] || ""}
              onChange={(event) => {
                onChangeHandler(event, 1);
              }}
              className={`${highlightErrorInput ? "error" : ""}`}
              placeholder="Enter numeric value"
            />
          </div>
        );
      case "multi-select":
        return (
          <CheckboxAutocomplete
              label={placeholderText}
              options={controlValues.map((i) => ({
                key: i?.key || i,
                value: i?.value || i,
                showSeparator: false
            }))}
              value={value.map((i) => ({key:i, value:i}))}
              onChange={onChangeHandler}
              getItemLabel={getItemLabel}
              multiple={true}
              showSeparator={true}  
              classes={{popper:'posture-filter-item-popper'}}
            />       
        );
      case "select":
        return (
          <CheckboxAutocomplete
              label={placeholderText}
              options={controlValues.map((i) => ({key:i, value:i}))}
              value={Array.isArray(value) ? value.map((i) => ({key:i, value:i})) : value.split(",").map((i) => ({key:i, value:i}))}
              onChange={onChangeHandler}
              getItemLabel={getItemLabel}
              multiple={false}
              classes={{popper:'posture-filter-item-popper'}}
              showSeparator={true}                          
            />        
        );
      case "array-autocomplete":
        return (
          <>
            <CheckboxAutocomplete
              label={placeholderText}
              options={controlValues.map((i) => ({
                key: i?.key || i,
                value: i?.value || i,
                showSeparator: false
            }))}
              value={value.map((i) => ({key:i, value:i}))}
              onChange={onChangeHandler}
              getItemLabel={getItemLabel}
              multiple={true}
              showSeparator={true}       
              classes={{popper:'posture-filter-item-popper'}}
            />
            {/* <Autocomplete
              onChange={onChangeHandler}
              multiple
              ChipProps={{
                size: "small",
                className: "chips",
                deleteIcon: (
                  <span style={{ cursor: "pointer", color: "white" }}>x</span>
                ),
              }}
              blurOnSelect={false}
              id={id}
              value={value}
              options={controlValues}
              getOptionLabel={(option) => {
               return option === 'Suspected Attack on Expired AD Account' ? 'Suspected Attack on Expired AD Account/Password' : option
            }}
              renderInput={(params) => {
                return (
                  <div
                    className={`auto-populate-container ${highlightErrorInput ? "error" : ""}`}
                    ref={params.InputProps.ref}
                  >
                    <CustomAutoPopulateTagContainer
                      ref={params.InputProps.ref}
                      count={params.InputProps.startAdornment?.length}
                      tags={params.InputProps.startAdornment}
                      placeholderText={placeholderText}
                      inputProps={params.inputProps}
                    />
                  </div>
                );
              }}
              sx={{ width: "500px" }}
              disableCloseOnSelect={true}
              clearOnBlur={false}
              clearOnEscape={false}
              inputValue={autoCompleteValue}
              onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'reset') {
                  if (previousEvent === "click" && event.type === 'change') {
                    setAutoCompleteValue('');
                  } else {
                    setAutoCompleteValue(newInputValue);
                  }
                }
                setPreviousEvent(event.type);
              }}
              onClose={(event, reason) => {
                if (reason === 'blur')
                  setAutoCompleteValue('');
              }}
            /> */}
          </>
        );

      case "text-array":
        return (
          <>
            <input
              ref={InputControlRef}
              placeholder={placeholderText}
              type="text"
              id={id}
              key={id}
              name={id}
              defaultValue={(value || [])?.join(",")}
              onChange={onChangeHandler}
              className={`${highlightErrorInput ? "error" : ""}`}
            />
          </>
        );
      case "datetime-range":
        return (
          <div className="date-time-container date-range">
            <div className="date-control">
              <ReactDatePicker
                maxDate={currentDate}
                showTimeSelect
                selected={epochToDate(value?.[0])}
                onChange={(event) => {
                  onChangeHandler(event, "start-date");
                }}
                selectsStart
                timeIntervals="1"
                dateFormat="Pp"
                startDate={epochToDate(value?.[0])}
                endDate={epochToDate(value?.[1])}
                className={`${highlightErrorInput ? "error" : ""}`}
                placeholderText="Select date from"
              />
            </div>
            {/* <span className="date-icon"><CalenderIcon/></span> */}
            <span className="range-seperator">-</span>
            <div className="date-control">
              <ReactDatePicker
                maxDate={currentDate}
                showTimeSelect
                selected={epochToDate(value?.[1])}
                timeIntervals="1"
                onChange={(event) => {
                  onChangeHandler(event, "end-date");
                }}
                selectsEnd
                dateFormat="Pp"
                startDate={epochToDate(value?.[0])}
                endDate={epochToDate(value?.[1])}
                minDate={epochToDate(value?.[0])}
                className={`${highlightErrorInput ? "error" : ""}`}
                placeholderText="Select date to"
              />
            </div>
            {/* <span className="date-icon"><CalenderIcon/></span> */}
          </div>
        );
      // two text boxes int value
      case "datetime":
        return (
          <>
            <div className="date-time-container">
              <div className="date-control" style={{width: "100%"}}>
                <ReactDatePicker
                  maxDate={currentDate}
                  selected={epochToDate(value)}
                  onChange={onChangeHandler}
                  showTimeSelect
                  timeIntervals="1"
                  dateFormat="Pp"
                  className={`${highlightErrorInput ? "error" : ""}`}
                  placeholderText="Select date"
                  />
                </div>
            </div>
          </>
        );
      case "time-number":
        return (
          <>
            <div className='time-number'>
              <input
                onKeyPress={restrictToNumeric}
                placeholder={placeholderText}
                type="text"
                id={`${id}1`}
                key={`${id}1`}
                name={`${id}1`}
                defaultValue={value?.[0] || ""}
                onChange={(event) => {
                  onChangeHandler(event, 0);
                }}
                className={`${highlightErrorInput ? "error" : ""}`}
              />
              <Select
                placeholder={placeholderText}
                id={`${id}2`}
                key={`${id}2`}
                name={`${id}2`}
                onChange={(event) => {
                  onChangeHandler(event, 1);
                }}
                defaultValue={value?.[1] || controlValues[0]}
                MenuProps={{ classes: { paper: classes.select }, MenuListProps: { className: 'search-multi-select' } }}
                className={`${highlightErrorInput ? "select-error select-input" : "select-input"
                  }`}
                sx={{
                  paddingTop: "0 !important",
                  width: "49%"
                }}
              >
                {controlValues.map((item) => {
                  return <MenuItem value={item}>{(casing === 'camel') ? camelCase(item) : item}</MenuItem>;
                })}
              </Select>
            </div>
          </>
        );
      default:
        return (
          <input
            ref={InputControlRef}
            placeholder={placeholderText}
            type="text"
            id={id}
            key={id}
            name={id}
            defaultValue={value}
            onChange={onChangeHandler}
            className={`${highlightErrorInput ? "error" : ""}`}
          />
        );
    }
  };

  // validate filters value coming from search textbox from issues page i.e: existing saved Validations
  useEffect(() => {
    if (validations?.length > 0) {
      const isInputValid = validate(validations, value);
      highlightIfError(isInputValid);
      setIsValid(isInputValid);
      if (InputControlRef?.current?.value && (value === '' || value === undefined || value === null)) {
        InputControlRef.current.value = '';
      }
    }
  }, [validations, value, validate]);

  useEffect(() => {
    if (inputValue !== null) {
      highlightIfError(isValid);
      if ((filterChanged || operatorChanged) && inputValueRef.current === inputValue) {
        onChange(getValueOnType(controlType), isValid);
      } else {
        onChange(inputValue, isValid);
      }
    }
  }, [inputValue, isValid]);

  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue])

  return <>{renderControl()}</>;
});

export default FilterInput;

function SingleTag({ tags, showMore, show }) {
  const showMoreHandler = () => showMore(!show);
  return (
    <div className="single-tag">
      <span className="adv-tags" title={tags[0]?.props?.label || ''}>{tags[0]}</span>
      {tags.length > 1 && (
        <span onClick={showMoreHandler} className="more">
          {`(+${tags.length - 1}) ${show ? "less" : "more"}...`}{" "}
        </span>
      )}
    </div>
  );
}

function ExcessTags({ tags, show, showMore }) {
  const excessTags = tags?.slice(1);
  const ref = useRef(null);
  const showMoreHandler = () => showMore(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        showMore(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showMore]);

  return excessTags ? (
    <div ref={ref} className={`adv-excess-tags-container ${show && "active"}`}>
      <span className="adv-excess-close" onClick={showMoreHandler}>
        x
      </span>
      {excessTags.map((tag, index) => (
        <span key={`${index + 1}`} className={`adv-tags`} title={tag?.props?.label || ''}>
          {tag}
        </span>
      ))}
    </div>
  ) : null;
}

function CustomAutoPopulateTagContainer({
  count,
  tags,
  placeholderText,
  inputProps,
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className={`adv-search-input ${count > 0 && "selected"}`}>
        <input
          style={{ flexBasis: "109px !important;", flexGrow: "0" }}
          placeholder={placeholderText}
          type="text"
          {...inputProps}
        />
      </div>
      {count && (
        <>
          <SingleTag tags={tags} showMore={setShow} show={show} />
          <ExcessTags tags={tags} showMore={setShow} show={show} />
        </>
      )}
    </>
  );
}
