import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { NTLMRelayAttackState, DirParamThreshold, ThresholdType } from "../../../../types/playbooks-config";
import { ThresholdSelect } from "../../ref/ThresholdSelect";
import { getDefaultConfigState } from "../../helpers/playbook-helper";

export const NTLMRelayAttack = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<NTLMRelayAttackState>) => {
    const [state, setState] = useState<NTLMRelayAttackState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state?.dir_params, dir_ntlm_relay_count_today: parseInt(e.target.value) } })
    }

    useEffect(() => {
        const op = state?.dir_params?.dir_ntlm_relay_count_today;
        const object = {
            user_input1: op
        }
        if (getExpression) {
            getExpression(object);
        }
    })

    const onThresholdSelect = (value) => {
        const defaultSt = getDefaultConfigState(ruleType);
        let curHour = defaultSt?.dir_params?.dir_ntlm_relay_count_today
        const params: DirParamThreshold = {
            allow_ml_modify: false,
            param_name: 'dir_ntlm_relay_count_today',
            threshold_confidence: undefined,
            threshold_type: ThresholdType.MANUAL
        };
        if (value.target.value === ThresholdType.AUTOMATIC) {
            params.allow_ml_modify = true;
            params.threshold_type = value.target.value;
            params.threshold_confidence = 'low'

        }
        setState({ dir_params: { dir_ntlm_relay_count_today: curHour, param_thresholds: [{ ...params }] }, });
    }


    return <>
        <form className="suspected-pass-spray-container">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || ThresholdType.MANUAL} />
            </div>
            <div className={(state?.dir_params?.param_thresholds?.[0]?.threshold_type === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>
                <div className="suspected-pass-spray-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        An Active Directory will be marked as under NTLM relay attack when:</label>
                </div>

                <div className="suspected-pass-spray-row">
                    <label>
                        Number of attempts within the current day is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params.dir_ntlm_relay_count_today}</label> :
                            <input type="number"
                                style={{ marginTop: 'auto' }}
                                onChange={handleNumAccWithBadPass} name="num_acc_bad_pass" value={state?.dir_params.dir_ntlm_relay_count_today} />
                    }
                </div>
            </div>
        </form>
    </>
}