import React from "react";
import './tags.css';

type Props = {
  tags: string[];
  deleteTag: (index:number, tg?:string)=>void
};

const Tags = ({ tags, deleteTag }: Props) => {
  const renderTags = tags?.map((tag, index, list) => {
    if (index > 2) {
      return null;
    }
    if (index > 1) {
        const excessTags = tags.slice(2);
      return (
        <div className="excess-tags">{`${list?.length-2} more...`}
        <div className="container">
            {excessTags.map((tg,idx,lst) => {
                return(
                    <>
                    <div
                        key={tg + (idx+2)}
                        title={tg.toString()}
                        className={`excess-tag search_tag flex_none tag-${tg.split(":")[0]}`}>
                        <span>{tg}&nbsp;</span>
                        <span className="search_close_btn" onClick={() => deleteTag(idx+2, tg)}>
                        X
                        </span>
                    </div>
                    </>
                )
            })}
        </div>
        </div>
      );
    }
    return (
      <div
        key={tag + index}
        title={tag.toString()}
        className={`search_tag flex_none tag-${tag.split(":")[0]} tag-${tag.split("~")[0]} tag-${tag.split("|")[0]}`}
      >
        <span>{tag}&nbsp;</span>
        <span className="search_close_btn" onClick={() => deleteTag(index, tag)}>
          X
        </span>
      </div>
    );
  });
  return <>{tags.length > 0 && renderTags}</>;
};

export default Tags;
