import React, { useState, useEffect, useRef } from 'react'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { useForm } from 'react-hook-form'
import { Api } from '../../components/Axios'
import { EditPanel } from './sub'
import DatePicker from "react-datepicker";
import { format, differenceInCalendarDays, subMonths, addMonths, setDate, getDate } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import "./audittrail.css";
import { useToasts } from '../../components/core';
import { useAuthDetails } from '../../components/Authorization'
import { CSVLink, CSVDownload } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import moment from 'moment';
import Popup from 'reactjs-popup';
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'
import { usePartnerStateContext } from '../../store/PartnerContextProvider'
import { getExportFileName } from '../../utils/util-methods'

type ActionType = {
    type?: string;
    id?: string;
}

type AuditTrail = {
    user_id?: string;
    detail_json?: string;
    user_name?: string;
    user_email?: string;
    timestamp?: string;
    ip_address?: string;
    feature_sub_type?: string;
    feature_type?: string;
    summary?: string;
}

type SysLog = {
    id?: string;
    host?: string;
    port?: number;
    transport?: string;
    auth_required: boolean;
    certificate?: string;
    cert_pvt_key?: string;
    cert_chain?: string;
    remote_server?: string;
}

type AuditLog = {
    order_by: string,
    page: number,
    result: AuditTrail[]
    rpp: number,
    sort_by: string,
    total: number
}

const AT = ({ query, setQuery }: any) => {
    const { authDetails } = useAuthDetails()!;
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const { PartnerConfigState } = usePartnerStateContext()

    const [showError, setShowError] = useState<string>("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [audit, setAudit] = useState<AuditLog | undefined>()

    // const [sys, setSys] = useState<SysLog | undefined>()
    const [loadingSyslog, setLoadingSyslog] = useState<Boolean>(false)
    const [showenabled, setShowEnabled] = useState<Boolean>(false);
    const [tlsCheck, setTlsCheck] = useState<boolean>();
    const [portType, setPortType] = useState<string | undefined>();

    const { register, handleSubmit, errors, setValue } = useForm<SysLog>()
    const [addSyslog, setSyslog] = useState<SysLog>()
    const date90 = new Date()
    date90.setDate(date90.getDate() - 90);
    const [startDate, setStartDate] = useState<Date>(date90);
    const [endDate, setEndDate] = useState(new Date());
    const { addToast } = useToasts()
    const headers = [
        { label: "Time of Action", key: "timestamp" },
        { label: "Registered User's Name", key: "user_name" },
        { label: "Registered Username/Email", key: "user_email" },
        { label: "Registered User's IPs", key: "ip_address" },
        { label: "Menu", key: "feature_type" },
        { label: "Sub-menu", key: "feature_sub_type" },
        { label: "Action", key: "summary" },
        { label: "Status", key: "status" },
        { label: "Action Details (JSON)", key: "detail_json" }
    ];
    const [downData, setDownData] = useState<string | Data>([]);
    const [loading, setLoading] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();

    const getUserList = (selectedPage?: any) => {
        const page: any = selectedPage === 0 ? 1 : selectedPage + 1;
        return Api.get(`/audittrail?rpp=100&page=${page}&from=${moment(startDate).format('yyyy-MM-DD')}&to=${moment(endDate).format('yyyy-MM-DD')}`)
            .then(res => {
                res.data.result.map((item: any, index: any) => {
                    item.detail_json = item.detail_json.replace(/,/g, ' ');
                    currDownData.push(item)
                });
                return currDownData;
            }).catch((error: any) => {
                setLoading({ loading: false, setData: false })
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const getCountList = () => {
        return Api.get(`/audittrail?rpp=100&page=1&from=${moment(startDate).format('yyyy-MM-DD')}&to=${moment(endDate).format('yyyy-MM-DD')}`)
            .then(res => {
                setTotalCount(res.data.total)
                if (res.data.total === 0) {
                    setShowError("No Records available to download")
                    setLoading({ loading: false, setData: true })
                }
            }).catch((error: any) => {
                setLoading({ loading: false, setData: false })
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    useEffect(() => {
        //console.log(totalCount)      
        if (totalCount === 0) {
        }
        else {
            setShowError("")
            downloadCSV()
        }
    }, [totalCount])

    const downloadCSV = async () => {
        if (differenceInCalendarDays(endDate, startDate) > 365) {
            setShowError("Logs for more than 1 year cannot be downloaded.");
            return false;
        }
        else {
            setLoading({ loading: true, setData: false })
            //Check for total count 
            if (totalCount === 0)
                getCountList();
            else {
                let i = 0;
                const total = Math.ceil(totalCount / 100);
                do {
                    const downData = await getUserList(i);
                    i = i + 1;
                    if (i > total) {
                        //loop through total count 
                        setDownData(downData);
                        setTimeout(() => {
                            setLoading({ loading: false, setData: true })
                            setTotalCount(0)
                            csvLinkEl.current.link.click();
                        });
                    }
                } while (i <= total)
            }

        }

    }

    const LoadAT = () => {
        Api.get('/audittrail')
            .then((res: { data: any }) => {
                setAudit(res.data)
            })
            .catch((error: any) => {
            })
    }

    const handleTlsFlagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked) {
            setTlsCheck(true);
            setShowEnabled(true)
        }
        else {
            setTlsCheck(false);
            setShowEnabled(false)
        }
    }

    const LoadSL = () => {
        Api.get('/audittrail/serverinfo')
            .then((res: { data: any }) => {
                setSyslog(res.data)
                console.log("Get request", res.data)
                setValue("host", res.data.host)
                setValue("port", res.data.port)
                setValue("transport", res.data.transport)
                setValue("auth_required", res.data.auth_required)
                setValue("remote_server", res.data.remote_server)
                if (res.data.auth_required === true) {
                    setTlsCheck(true);
                    setShowEnabled(true)
                }
                setValue("certificate", res.data.certificate)
                setValue("cert_pvt_key", res.data.cert_pvt_key)
                setValue("cert_chain", res.data.cert_chain)
            })
            .catch((error: any) => {
            })
    }

    useEffect(() => {
        LoadSL()
    }, [])


    useEffect(() => {
        LoadAT()
    }, [])

    useEffect(
        () => {
            setPortType(portType)
            console.log("Port type", portType)
        }, [portType]
    )

    const deleteConfirm = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post('/audittrail/serverinfo', "", { headers: headers })
            .then((res: { data: any }) => {
                LoadSL()
                addToast("Log configuration deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                closeConfirm()
            })
            .catch((error: any) => {
            })
    }

    useEffect(() => {
        switch (actionType?.type) {
            case 'edit':
                setPanelTitle("Remote Log Configuration")
                setOpenPanel(true)
                return

            case 'delete':
                setOpenConfirm(true)
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        setQuery()
    }

    const [openConfirm, setOpenConfirm] = useState(false);
    const closeConfirm = () => setOpenConfirm(false);

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => setOpenPanel(false)}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                }

            </ModalPopup>
        )
    }

    const AfterEditOperation = (action: string) => {
        LoadSL()
        setOpenPanel(false)
    }
    const partnerName = PartnerConfigState?.PartnerName;

    return (
        <>
            {/* <RightPanel /> */}
            {renderPopup()}
            <div className="float_left" style={{ width: '48%' }}>
                <div className="page_title_area width75per">
                    <h2>Download Audit Log</h2>
                </div>
                <div className="box_grid_layout">
                    <div className='font14'>Select date range to export audit logs:</div>
                    <div className="clrBoth margintop20"></div>

                    <div className='width25per float_left'>
                        <label>From</label>
                    </div>

                    <div className='width75per float_left'>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date: Date) => setStartDate(date)}
                            // minDate={subMonths(new Date(), 12)}
                            // maxDate={addMonths(new Date(), 0)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                        />
                    </div>
                    <div className='width25per float_left'>
                        <label>To</label>
                    </div>

                    <div className='width75per float_left'>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date: Date) => setEndDate(date)}
                            minDate={startDate}
                            // maxDate={addMonths(new Date(), 0)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                        />
                    </div>

                    <div className='width25per float_left'><label></label></div>
                    <div className='width75per float_left red font12'>
                        <label className='width100per margintop0'>{showError}</label>
                    </div>

                    <div className="box_grid_footer_line"></div>
                    <div className="box_grid_footer" style={{ paddingTop: '0' }}>
                        <div className="float_right">
                            <button type={('button')} className={" " + (loading.loading ? 'loader' : 'button_styled')} onClick={(e) => downloadCSV()}>Export CSV</button>
                            {PartnerConfigState?.PartnerShortProduct && <CSVLink
                                headers={headers}
                                /* filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Auditlogs.csv`} */
                                filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Auditlogs')}
                                data={downData!}
                                ref={csvLinkEl}
                            />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="float_right" style={{ width: '48%' }}>
                <div className="width75per" >
                    <h2 style={{ paddingBottom: '20px' }}>Remote Log Configuration</h2>
                </div>
                <div className="box_grid_layout" style={{ marginRight: '0%' }}>

                    {
                        addSyslog?.remote_server == 'syslog' ?
                            <><div className="clear_both"></div>
                                <label className="font_semibold width50per">Log Destination : </label>
                                <label className="width50per">Remote Log Server</label>
                                <label className="font_semibold width50per">Host : </label>
                                <label className="width50per" >{addSyslog?.host}</label>

                                <label className="font_semibold width50per">Transport : </label>
                                <label className="width50per" >{addSyslog?.transport}</label>

                                <label className="font_semibold width50per">Port : </label>
                                <label className="width50per" >{addSyslog?.port}</label></> :
                            addSyslog?.remote_server == 's3_bucket' ? <>
                                <><div className="clear_both"></div>
                                    <label className="font_semibold width36per">Log Destination : </label>
                                    <label style={{ width: 'auto' }} >Cloud Storage Bucket(Hosted by {partnerName})</label>
                                </>
                            </> :
                                <><div className='font14'>No Remote Log configured</div></>
                    }

                    {/* <label className="font_semibold width25per">Authentication required : </label>
                    <label className="width75per">{addSyslog?.auth_required}</label> */}
                    <div className="box_grid_footer_line"></div>
                    <div className="box_grid_footer">
                        {
                            addSyslog?.remote_server ?
                                <button type="button" className="button_gray float_left width" onClick={() => setActionType({ id: "", type: "delete" })}>Clear</button>
                                : null}
                        <button type="button" className="button_styled margintop0 float_right" onClick={() => setActionType({ id: "", type: "edit" })} style={{ "width": "40%" }}>Edit</button>
                    </div>
                </div>
            </div>

            {/* Delete confirmation Popup */}
            <Popup
                open={openConfirm}
                closeOnDocumentClick
                closeOnEscape={false}
                onClose={closeConfirm}
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                modal
            >
                <div className="modal">
                    <div className="close" onClick={closeConfirm}></div>
                    <div className="header">Delete Log Configuration</div>
                    <div className="content">
                        <div className="font16 margintop20 marginbottom20">Are you sure you want to delete Remote Log Configuration?</div>
                    </div>
                    <div className="popup_footer">
                        <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                        <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={deleteConfirm}>Yes</button>
                    </div>
                </div>
            </Popup>

            {/* <table id="table" className="tablesorter">
                <thead>
                    <tr>
                        <th colSpan={9} style={{borderBottom:'1px solid #ffffff', textAlign:'center'}}><span className='font16'>Recent Audit Log Entries</span></th>
                    </tr>
                    <tr>
                        <th style={{width:'10%'}} className="align_left">Time of Action</th>
                        <th style={{width:'10%'}} className="align_left">Registered User's Name</th>
                        <th style={{width:'15%'}} className="align_left">Registered Username/Email</th>
                        <th style={{width:'10%'}} className="align_left">Registered User's IP</th>
                        <th style={{width:'10%'}} className="align_left">Menu</th>
                        <th style={{width:'15%'}} className="align_left">Sub-menu</th>
                        <th style={{width:'10%'}} className="align_left">Action</th>
                        <th style={{width:'10%'}} className="align_left">Status</th>
                        <th style={{width:'20%'}} className="align_left">Action Details (JSON)</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {audit?.result.map((u:any) => (
                        <tr key={u._id}>
                            <td className="align_left">{moment(u.timestamp).format('DD MMM, YYYY, hh:mm A')}</td>
                            <td className="align_left">{u.user_name}</td>
                            <td className="align_left">{u.user_email}</td>
                            <td className="align_left">{u.ip_address}</td>
                            <td className="align_left">{u.feature_type}</td>
                            <td className="align_left">{u.feature_sub_type}</td>
                            <td className="align_left">{u.summary}</td>
                            <td className="align_left">{u.status}</td>
                            <td className="align_left">{u.detail_json}</td>
                            
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </>
    )
}

export default (AT)