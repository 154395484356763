import React, { useEffect, useState } from 'react';

export const IBMVerifySaasCollector = ({
    register,
    setValue,
    collectorData
}) => {
    const [mfaEnabledCheck, setMfaEnabledCheck] = useState(false);
    useEffect(() => {
        if (collectorData) {
            setValue(
                'ibm_token_endpoint',
                collectorData.collector_config?.ibm_token_endpoint
            );
            setValue(
                'ibm_client_id',
                collectorData.collector_config?.ibm_client_id
            );
            setValue(
                'ibm_client_secret',
                collectorData.collector_config?.ibm_client_secret
            );
            setValue(
                'mfa_enabled',
                collectorData.collector_config?.mfa_enabled
            );
            setMfaEnabledCheck(collectorData.collector_config?.mfa_enabled);
        }
    }, [collectorData]);

    const handleMfaEnabledChange = (e) => {
        e.stopPropagation();
        if (e.target.checked) {
            setMfaEnabledCheck(true);
        } else {
            setMfaEnabledCheck(false);
        }
    };

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    IBM Verify SaaS Collector Configurations
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Token Endpoint*</label>
                            <input
                                type='text'
                                name='ibm_token_endpoint'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Token Endpoint'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Client Id*</label>
                            <input
                                type='text'
                                name='ibm_client_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Client Id'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Client Secret*</label>
                            <input
                                type='text'
                                name='ibm_client_secret'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Client Secret'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='sensor-flex-container'>
                <div className='sensor-flex-container-item'>
                    <input
                        name='mfa_enabled'
                        type='checkbox'
                        className={'margintop20'}
                        checked={mfaEnabledCheck}
                        onChange={handleMfaEnabledChange}
                        ref={register}
                    />
                    <label
                        className='auto_width paddingright20'
                        style={{ float: 'unset' }}
                    >
                        Performs MFA?
                    </label>
                </div>
            </div>
        </div>
    );
};
