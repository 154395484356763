import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuthDetails } from '../components/Authorization';
import { Api } from '../components/Axios';
import { useToasts } from '../components/core';
import './Login.css';
import RedirectionContainer from './RedirectionContainer';
import BeforeLoginContainer from './BeforeLoginContainer';

type Inputs = {
    email: string
}
type LoginType = {
    isRootUser?: Boolean
};

function b64DecodeUnicode(str: any) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

const Verify = ({ isRootUser = false }: LoginType) => {
    const { authURLInvoked, authURLResponse } = useAuthDetails();
    const { sso_redirect_url } = authURLResponse || {};

    if (sso_redirect_url !== '' && !isRootUser) {
        window.location.replace(sso_redirect_url);
    }

    const [loading, setLoading] = useState({ loading: false, setData: false })
    const { addToast } = useToasts()
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<Inputs>();
    const { setAuthDetails } = useAuthDetails()!
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (currentUrlParams.get('ref') !== null)
        var loginObj: any = JSON.parse(b64DecodeUnicode(currentUrlParams.get('ref')));

    if (loginObj) {
        const user = {
            name: loginObj.name,
            email: loginObj.email.trim(),
            token: loginObj.token,
            authenticated: true,
            role: loginObj.role,
            permissions: loginObj.permissions,
            sessionindex: loginObj.sessionindex,
            tenantName: loginObj.tenant_name
        }
        Api.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

        window.localStorage.setItem('user', JSON.stringify(user))
        window.localStorage.removeItem('user_auth');
        setLoading({ loading: false, setData: true })
        addToast("You have logged in successfully.", {
            appearance: 'success',
            autoDismiss: true,
        })
        setAuthDetails(user)
        history.push("/")
    }

    const onSubmit = (data: any) => {
        setLoading({ loading: true, setData: false })
        if (data && data.email) {
            data.email = data.email.trim();
        }
        Api.post('/verify', data)
            .then((res: { data: any }) => {
                //check for sso                 
                if (res.data.sso_enabled) {
                    window.location.href = res.data.sso_redirect_url
                }
                else {
                    window.localStorage.setItem('user_auth', res.data.token)
                    setLoading({ loading: false, setData: true })
                }
            })
            .catch((error: any) => {
                setLoading({ loading: false, setData: false })
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    if (error.response.data.email) {
                        addToast(error.response.data.email, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    } else
                        addToast("We encounted validation problem, please correct and try again.", {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 423) {
                    addToast("Your account has been locked due to multiple failed login attempts. Please try again later.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const LoadView = () => {
        if (loading.setData) {
            if (isRootUser) {
                return <Redirect to={{
                    pathname: '/',
                    state: { isRootUser: isRootUser }
                }} />;
            }
            return (
                <Redirect to="/" />
            )
        } else {
            return (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form_header">Login</div>
                    <label>Username/Email</label>
                    <input
                        type="text"
                        placeholder="Enter your Username/Email"
                        name="email"
                        ref={register({
                            required: true,
                            // pattern: {
                            // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            // message: "Please enter valid username/email."
                            // }
                        })}
                    />
                    {errors.email && errors.email.type === 'required' && <div className="error">Please enter username/email.</div>}
                    {errors.email && errors.email.type != 'required' && <div className="error">{errors.email.message}</div>}
                    <button type={(loading.loading ? 'button' : 'submit')} className={" " + (loading.loading ? 'loader' : 'button_login')}>Next</button>
                    <div className="clrBoth"></div>
                </form>
            )
        }
    }
    const renderContent = (sso_redirect_url === '' || isRootUser) ? <BeforeLoginContainer><LoadView /></BeforeLoginContainer> : <RedirectionContainer />
    return renderContent;
}

export default Verify