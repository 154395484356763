import React, { useState, useRef, useEffect } from 'react';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import { getResolutionMatch, getTimeDiffString, withDebounce } from '../../../utils/util-methods';
import moment from 'moment';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';
import { dateToEpoch } from '../utils';
import { AlertComponent } from '../../../pages/sensors/AlertComponent';
import { useTimeBasedSearchActions } from '../TimeBasedSearchActions';

const TimeBasedSearchChartComponent = ({ flowsData, primaryColor = '#9fa1ae', onRangeChange, startDate, isApplyHovered, zoomLevel, updatedExtremes }) => {
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const [chartOptions, setChartOptions] = useState(null);
    const softMin = (flowsData[0][0]) && flowsData.length < 24 ? { softMin: (flowsData[0][0] - 86400 * 1000 * 5) } : {};
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();
    const throttledRange = withDebounce(onRangeChange, 0);

    const updateVal = (min, max, isUserSelection) => {
        let startDate = moment(min).valueOf();
        let endDate = moment(max);
        if (endDate.isAfter(moment())) {
            endDate = moment().valueOf();
        } else {
            endDate = moment(endDate) instanceof moment ? endDate.valueOf() : moment(endDate).valueOf();
        }

        dispatchAction({
            type: ACTION_TYPES.SELECT_DATE_RANGE_HISTOGRAM,
            value: [
                startDate,
                endDate
            ]
        });



        if (isUserSelection) {
            throttledRange(min, max);
        }
    }

    const zoomIn = () => {
        let timeFilter = appliedFilterTimestamp();
        const { min, max } = chartRef.current?.chart.xAxis[0].getExtremes();
        const range = max - min;
        const center = (max + min) / 2;

        // Proportional buffer size
        let bufferPercentage = 0.1; // 10% of the current range as buffer

        if (range > 48 * 60 * 60 * 1000) { // If range is greater than 48 hours  day
            bufferPercentage = 0.3; // Use 5% buffer for larger ranges
        } else if (range <= 48 * 60 * 60 * 1000 && range >= 12 * 60 * 60 * 1000) { // Between 12 hours and 48 hours
            bufferPercentage = 0.5; // Use 10% buffer
        } else if (range < 12 * 60 * 60 * 1000) { // Less than 6 hours
            bufferPercentage = 0.6; // Use 20% buffer for shorter ranges
        }

        const buffer = range * bufferPercentage;
        const newRange = range / 2; // Halve the range for zoom in

        // Calculate new min and max with proportional buffer applied
        const newMin = center - newRange / 2 - buffer;
        const newMax = center + newRange / 2 + buffer;



        // Update the chart data and zoom
        // updateInlineChartVal(newMin, newMax, true);

        if (timeFilter.length > 0) {

            if (timeFilter[0] < newMin && newMax < timeFilter[1]) {
                updateVal(newMin, newMax, true);
            } else {
                updateVal(timeFilter[0], timeFilter[1], true);
            }
        } else {
            updateVal(newMin, newMax, true);
        }

        document.querySelector('.zoom-out').classList.remove('disabled');
        document.querySelector('.reset').classList.remove('disabled');
        //  chart.xAxis[0].setExtremes(newMin, newMax);
    }

    const zoomOut = () => {

        let timeFilter = appliedFilterTimestamp();
        const { min, max } = chartRef.current?.chart.xAxis[0].getExtremes();
        const range = max - min;
        const center = (max + min) / 2;

        // Proportional buffer size for zooming out
        let bufferPercentage = 0.1; // 10% of the current range as buffer

        // Adjust buffer percentage based on the range
        if (range > 48 * 60 * 60 * 1000) { // If range is greater than 30 hours
            bufferPercentage = 0.3; // Use 5% buffer for larger ranges
        } else if (range <= 48 * 60 * 60 * 1000 && range >= 12 * 60 * 60 * 1000) { // Between 12 hours and 48 hours
            bufferPercentage = 0.5; // Use 15% buffer
        } else if (range < 12 * 60 * 60 * 1000) { // Less than 6 hours
            bufferPercentage = 0.6 // Use 30% buffer for shorter ranges
        }

        const buffer = range * bufferPercentage;
        const newRange = range * 2; // Double the range for zoom out

        // Calculate new min and max with proportional buffer applied
        const newMin = center - newRange / 2 - buffer;
        const newMax = center + newRange / 2 + buffer;

        // Update the chart data and zoom
        if (timeFilter.length > 0) {
            if (timeFilter[0] < newMin && newMax < timeFilter[1]) {
                updateVal(newMin, newMax, true);
            } else {
                updateVal(timeFilter[0], timeFilter[1], true);
            }
        } else {
            if( moment(startDate) < moment(newMin) &&  moment() >= moment(newMax) ) {
                updateVal(newMin, newMax, true);
            } else {
                updateVal(moment(startDate).valueOf(), moment().valueOf(),true);
                document.querySelector('.zoom-out')?.classList.add('disabled');
            }
        }

        document.querySelector('.zoom-in').classList.remove('disabled');

        // chart.xAxis[0].setExtremes(newMin, newMax);
    }

    Highcharts.setOptions({
        lang: {
            rangeSelectorFrom: '',
            rangeSelectorTo: '-'
        },
        time: {
            useUTC: false,
        }
    });

    useEffect(() => {
        const maskElement = document.querySelector('.highcharts-navigator-mask-inside');
        if (maskElement) {
            if (isApplyHovered) {
                maskElement.style.fill = '#ffcc33';
                maskElement.style.opacity = 0.3;
            } else {
                maskElement.style.fill = '';  // Reset to original or default color
            }
        }
    }, [isApplyHovered]);

    const updateYAxisExtremes = (maxValue) => {
        if (chartRef.current?.chart) {
            const chrt = chartRef.current.chart;
            const yAxis = chrt.yAxis[0];
            let newYMax = Math.max(6, maxValue * 1.4); // Calculate the new Y-axis max based on flowData
            yAxis.setExtremes(0, newYMax); // Set new Y-axis extremes
        }
    };


    useEffect(() => {

        const timer =  setTimeout(() => {
            if (flowsData && flowsData.length > 0) {
                // Find the max value from the second element of each child array in flowData
                const maxFlowValue = Math.max(...flowsData.map(item => item[1])); // Assuming item[1] contains the value
                updateYAxisExtremes(maxFlowValue);
                if (updatedExtremes?.current?.min !== null && updatedExtremes?.current?.max !== null) {
                    // Calculate the average (center point) between min and max
                    const center = (updatedExtremes?.current?.min + updatedExtremes?.current?.max) / 2;

                    // Calculate the new 1/4 and 3/4 points based on the range between min and max
                    const quarterRange = (updatedExtremes?.current?.max - updatedExtremes?.current?.min) / 4;
                    let newMin = center - quarterRange;  // 1/4 of the range
                    let newMax = center + quarterRange;  // 3/4 of the range
                    
                    // Ensure the range between newMin and newMax does not exceed 14 days (in milliseconds)
                    const maxRange = 45 * 24 * 60 * 60 * 1000;  // 14 days in milliseconds
                    const currentRange = newMax - newMin;
                    
                    if (currentRange > maxRange) {
                        // Adjust the newMin and newMax to be within 14 days, keeping the center fixed
                        const halfMaxRange = maxRange / 2;
                        newMin = center - halfMaxRange;
                        newMax = center + halfMaxRange;
                    }
                    
                    // Set the new extremes to 1/4 and 3/4 points or the adjusted range
                    if (newMin < newMax) {
                        chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                    } else {
                        console.log('error');
                    }
                    

                    if (zoomLevel === '1-minute' && quarterRange * 4 < 60 * 60 * 1000) {
                        document.querySelector('.zoom-in').classList.add('disabled');
                    } else {
                        document.querySelector('.zoom-in').classList.remove('disabled');
                    }
                }
            }

        }, 500)

        return () => {
            clearTimeout(timer);
          };

    }, [flowsData]);

    useEffect(() => {
        let timeFilter = appliedFilterTimestamp();
        setChartOptions({
            credits: {
                enabled: false
            },
            xAxis: {
                crosshair: false,
                lineWidth: 0,
                tickLength: 0,
                labels: {
                    enabled: false
                },
                events: {
                    afterSetExtremes(e) {
                        const output = `${new Date(e.min)} - ${new Date(e.max)}`
                        updateVal(e.min, e.max, false);
                    }
                }
            },
            yAxis: {
                crosshair: false,
                height: 0,
                gridLineWidth: 0,
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            rangeSelector: {
                enabled: true,
                inputEnabled: true,
                inputStyle: {
                    color: 'black',
                    fontWeight: 'normal',
                    fontFamily: 'Metropolis-Regular'
                },
                inputDateFormat: `%b %e %Y, %I:%M %p`,
                buttons: []
            },
            scrollbar: {
                buttonBackgroundColor: 'transparent',
                buttonArrowColor: 'transparent',
                trackBorderColor: 'transparent',
                trackBackgroundColor: 'transparent',
                buttonBorderWidth: 0,
                height: getResolutionMatch(25, 10)
            },
            navigator: {
                useHTML: true,
                tooltip: {
                    enabled: false
                },
                height: getResolutionMatch(100, 55),
                handles: {
                    height: getResolutionMatch(35, 20),
                    width: getResolutionMatch(15, 7),
                },
                series: {
                    color: primaryColor,
                    marker: {
                        enabled: false,
                        fillColor: '#FFFFFF',
                        lineWidth: 0,
                        lineColor: primaryColor
                    },
                    useHTML: true,
                    type: 'areaspline',
                    fillOpacity: 0.2,
                    dataGrouping: {
                        smoothed: true
                    },
                    lineWidth: 1,
                },
                xAxis: {
                    //    ...softMin,
                    // min: moment().subtract(60, 'days').valueOf(), // Example: 30 days before today
                    // max: moment().valueOf(), // Example: today
                    min: updatedExtremes?.current?.min !== null
                        ? updatedExtremes?.current?.min
                        : timeFilter.length > 0 ? timeFilter[0] : moment(startDate).valueOf(),
                    max: updatedExtremes?.current?.max !== null
                        ? updatedExtremes?.current?.max
                        : timeFilter.length > 0 ? timeFilter[1] : moment().valueOf(),
                    labels: {
                        y: getResolutionMatch(50, 35),
                        x: getResolutionMatch(200, 40),
                        useHTML: true,
                        align: 'left',
                        step: getResolutionMatch(4, 2),
                        formatter: function () {
                            if (moment(this.value).isSame(moment(), 'day')) {
                                return `<span class='flow-x-axis-label-navigator'><b>Today</b><span>`;
                            }
                            return `<span class='flow-x-axis-label-navigator'><b>${moment(this.value).format('MMM DD YYYY, hh:mm A')}</b><span>`;
                        }
                    },
                    // plotBands: [{
                    //     from: moment().subtract(60, 'days').valueOf(),
                    //     to: moment().subtract(60, 'days').valueOf(),
                    //     label: {
                    //         text: moment().subtract(60, 'days').format("MMM DD YYYY"),
                    //         align: 'left',
                    //         rotation: 0,
                    //         x: -15,
                    //         y: 20,
                    //         useHTML: true,
                    //         style: {
                    //             color: 'black',
                    //             fontWeight: 'bold',
                    //             fontSize:'1em'
                    //         }
                    //     }
                    // }, {
                    //     from: moment().valueOf(),
                    //     to: moment().valueOf(),
                    //     label: {
                    //         text: moment().format("MMM DD YYYY"),
                    //         align: 'right',
                    //         rotation: 0,
                    //         x: 0,
                    //         y: 20,
                    //         useHTML: true,
                    //         style: {
                    //             color: 'black',
                    //             fontWeight: 'bold',
                    //             fontSize:'1em'
                    //         }
                    //     }
                    // }]
                    plotBands: updatedExtremes?.current.min !== null ? [
                        {
                            from: moment(updatedExtremes?.current.min).valueOf(),
                            to: moment(updatedExtremes?.current.min).valueOf() + 1, // small range to ensure visibility
                            label: {
                                text: moment(updatedExtremes?.current?.min).format('MMM DD YYYY, hh:mm A'),
                                align: 'left',
                                rotation: 0,
                                x: -20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        },
                        {
                            from: moment(updatedExtremes?.current?.max).valueOf(),
                            to: moment(updatedExtremes?.current?.max).valueOf() + 1, // small range to ensure visibility
                            label: {
                                text: moment(updatedExtremes?.current?.max).format('MMM DD YYYY, hh:mm A'),
                                align: 'right',
                                rotation: 0,
                                x: 20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        }
                    ] : timeFilter.length > 0 ? [
                        {
                            from: moment(timeFilter[0]).valueOf(),
                            to: moment(timeFilter[0]).valueOf() + 1, // small range to ensure visibility
                            label: {
                                text: moment(timeFilter[0]).format('MMM DD YYYY, hh:mm A'),
                                align: 'left',
                                rotation: 0,
                                x: -20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        },
                        {
                            from: moment(timeFilter[1]).valueOf(),
                            to: moment(timeFilter[1]).valueOf() + 1, // small range to ensure visibility
                            label: {
                                text: moment(timeFilter[1]).format('MMM DD YYYY, hh:mm A'),
                                align: 'right',
                                rotation: 0,
                                x: 20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        }
                    ] : [
                        {
                            from: moment(startDate).valueOf(),
                            to: moment(startDate).valueOf() + 1,
                            label: {
                                text: moment(startDate).format('MMM DD YYYY, hh:mm A'),
                                align: 'left',
                                rotation: 0,
                                x: -20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        }, {
                            from: moment().valueOf(),
                            to: moment().valueOf() + 1,
                            label: {
                                text: moment().format('MMM DD YYYY, hh:mm A'),
                                align: 'right',
                                rotation: 0,
                                x: 20,
                                y: 20,
                                useHTML: true,
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '1em'
                                }
                            }
                        }]
                },
            },
            series: [{
                lineWidth: 0,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                data: flowsData || []

            }]
        });
    }, [flowsData, updatedExtremes?.current]);

    const resetToDefault = () => {

        const timeFilter = appliedFilterTimestamp();
        if (timeFilter?.length > 0) {
            onRangeChange(timeFilter[0], timeFilter[1]);
        } else {
            onRangeChange(moment(startDate).valueOf(), moment().valueOf());
        }
        document.querySelector('.zoom-out').classList.add('disabled');
    }

    useEffect(() => {
    const timer =   setTimeout(() => {
            const timeFilter = appliedFilterTimestamp();

            if (timeFilter.length > 0) {

                const center = (timeFilter[0] + timeFilter[1]) / 2;

                // Calculate the new 1/4 and 3/4 points based on the range between min and max
                const quarterRange = (timeFilter[1] - timeFilter[0]) / 4;
                const newMin = center - quarterRange;  // 1/4 of the range
                const newMax = center + quarterRange;  // 3/4 of the range


                // Set the new extremes to 1/4 and 3/4 points
                if (newMin < newMax) {
                    chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                   
                }
                document.querySelector('.zoom-out').classList.add('disabled');
            document.querySelector('.reset').classList.add('disabled'); 

            } else {

                if (updatedExtremes?.current?.min === null || !updatedExtremes) {

                    const center = (moment(startDate).valueOf() + moment().valueOf()) / 2;

                    // Calculate the new 1/4 and 3/4 points based on the range between min and max
                    const quarterRange = (moment().valueOf() - moment(startDate).valueOf()) / 4;
                    let newMin = center - quarterRange;  // 1/4 of the range
                    let newMax = center + quarterRange;  // 3/4 of the range
                    
                    // Ensure the range between newMin and newMax does not exceed 14 days (in milliseconds)
                    const maxRange = 14 * 24 * 60 * 60 * 1000;  // 14 days in milliseconds
                    const currentRange = newMax - newMin;
                    
                    if (currentRange > maxRange) {
                        // Adjust the newMin and newMax to be within 14 days, keeping the center fixed
                        const halfMaxRange = maxRange / 2;
                        newMin = center - halfMaxRange;
                        newMax = center + halfMaxRange;
                    }
                    
                    // Set the new extremes to 1/4 and 3/4 points or the adjusted range
                    if (newMin < newMax) {
                        chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                    
                    }
                    //chrt?.xAxis[0].setExtremes(start_ms, moment().valueOf(), true, false);
                    document.querySelector('.zoom-out')?.classList.add('disabled');
                    document.querySelector('.reset')?.classList.add('disabled'); 

                    
                }
            }
             
        }, 100)

        return () => {
            clearTimeout(timer);
          };
    }, []);

    const getChartTitle = (startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) `}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)})`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend`}
                >{`Hourly flows trend 
                }`}</span>
            );
        }
    };

    return (
        <div className='histogram-container'>
            {store.isApplyDisabled && <AlertComponent messages={[{ type: "error", isSummary: false, text: "Adjust your date range to be less than 15 days." }]} />}
            <div className='histogram-chart-container'>

                <div className="zooom-absolute-container" >
                    <div className="zoom-button-container">

                        <button title="Zoom In" className="zoom-button zoom-in">
                            <div className="zoom-in-button" onClick={zoomIn}></div>
                        </button>

                        <button title="Zoom out" className="zoom-button zoom-out">
                            <div className="zoom-out-button" onClick={zoomOut}></div>
                        </button>
                        <div className="zoom-sepearator"></div>
                    </div>
                </div>
                <div style={{ position: 'absolute', left: '22%', top: '10px', fontWeight: 'bold' }}>
                    {updatedExtremes.current.min !== null ? getChartTitle(updatedExtremes.current.min, updatedExtremes.current.max) : appliedFilterTimestamp().length > 0 ? getChartTitle(moment(appliedFilterTimestamp()[0]).valueOf(), moment(appliedFilterTimestamp()[1]).valueOf()) :  getChartTitle(moment(startDate).valueOf(), moment().valueOf())}
                </div>
                <div onClick={resetToDefault}  className='reset' style={{ position: 'absolute', zIndex: 1000, color: 'black', right: '-2px', top: '10px', cursor: 'pointer' }}>X</div>
                <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} constructorType={"stockChart"} />
            </div>
        </div>
    );
};

export default TimeBasedSearchChartComponent;