import React from 'react';

export const NTLMRelayAttackView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An Active Directory will be marked as under NTLM relay
                    attack when number of attempts within the current day is more than{' '}
                    <strong>
                        {config?.dir_params.dir_ntlm_relay_count_today}
                    </strong>
                </span>
            </li>
        </>
    );
};
