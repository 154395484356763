import { GlobalConsts } from "../../../GlobalConst";
import { IPatternConfigItem } from "../ref/PatternForm/PatternFormGenerator";
import { PlaybookAdvanceddSearchConfig } from "./PlaybookAdvancedSearchConfig";
import { RiskOption } from "./types";

export const RiskOptionConfig: RiskOption[] = [
    { key: 'lw', lang: 'Low', className: 'low_dot', value: 'Low', priority: 1 },
    { key: 'md', lang: 'Medium', className: 'medium_dot', value: 'Medium', priority: 2 },
    { key: 'hi', lang: 'High', className: 'high_dot', value: 'High', priority: 3 },
    { key: 'cri', lang: 'Critical', className: 'critical_dot', value: 'Critical', priority: 4 }
]

export enum RuleType {
    LACK_OF_MFA = 'Lack of MFA',
    WEAK_PASSWORD = 'Weak Password',
    COMPROMISED_PASSWORD = 'Compromised Password',
    COMPROMISED_USER = 'Compromised User',
    AUTHENTICATION_QUALITY = 'Authentication Quality',
    AUTH_HASH_SECURITY = 'Auth Hash Security',
    AUTH_PROTOCOL_QUALITY = 'Auth Protocol Quality',
    SHADOW_INDENTITIES = 'Shadow Identities',
    SHADOW_ASSETS = 'Shadow Assets',
    SHADOW_DIRECTORY = 'Shadow Identity Systems',
    SHADOW_ACCESS = 'Shadow Access',
    EXPOSED_ASSETS = 'Exposed Assets',
    SHADOW_EXTERNAL_ACCESS = 'Shadow External Access',
    UNKNOWN_ACCESS = 'Unknown Access',
    SUSPICIOUS_OUTBOUND_ACCESS = 'Suspicious Outbound Access',
    SUSPICIOUS_INBOUND_ACCESS = 'Suspicious Inbound Access',
    UNKNOWN_SAAS_ACCESS = 'Unknown SaaS Access',
    AUTH_HASH_QUALITY = 'Auth Hash Quality',
    ACCESS_TO_UNAUTH_COUNTRIES = 'Access to Unauthorized Countries',
    ACCESS_FROM_UNAUTH_COUNTRIES = 'Access from Unauthorized Countries',
    ACCESS_FROM_PUBLIC_VPN = 'Access from Public VPN',
    ACCESS_TO_PUBLIC_VPN = 'Access to Public VPN',
    ACCESS_FROM_ANON_IP = 'Access from Anonymous IP',
    ACCESS_TO_ANON_IP = 'Access to Anonymous IP',
    DEVIATION_IN_DAILY_ASSET = 'Deviation in Daily Asset Activity',
    UNAUTHORIZED_ASSET_ACCESS = 'Unauthorized Asset Access',
    UNAUTHORIZED_IDENTITY_ACCESS = 'Unauthorized Identity Access',
    SUSPECTED_DIR_BOT = 'Suspected Directory/IdP Bot Attack',
    SUSPECTED_BRUTE_FORCE = 'Suspected Directory/IdP Identity Brute-force Attack',
    SUSPECTED_PASS_SPRAY = 'Suspected Directory/IdP Password Spray Attack',
    NTLM_RELAY_ATTACK = 'Suspected AD NTLM Relay Attack',
    ENUM_AD_ADMINS = 'Enumeration of AD Admins',
    ENUM_AD_USERS = 'Enumeration of AD Users',
    UNAUTH_LOGIN_TIME = 'Repeated AD Login Attempts at Invalid Time',
    UNAUTH_LOGIN_DEVICE = 'Repeated AD Login Attempts from Invalid Device',
    SUSPECTED_ATT_EXP_ACC = 'Suspected Attack on Expired AD Account',
    SUSPECTED_ATT_DIS_ACC = 'Suspected Attack on Disabled AD Account',
    SUSPECTED_ATT_LOCK_ACC = 'Suspected Attack on Locked AD Account',
    ACCOUNT_TAKEOVER = 'Impossible Travel',
    SUSPECTED_ACCESS_TOKEN = 'Suspected Access Token Sharing',
    ASSETS_WITHOUT_MFA = 'Asset No MFA'
}

export enum ActionType {
    BLOCK_ACCESS = 'Block Access',
    ADD_MFA = 'Add MFA',
    NOTIFY_USER = 'Notify User',
    REQUEST_PASSWRD = 'Request Password Reset',
    DASHBOARD_VIS = 'Dashboard Visibility',
    CRATE_TICKET = 'Create Ticket',
    NOTIFY_ADMIN = 'Notify Admin'
}

export const RuleActionConfig = {
    [ActionType['BLOCK_ACCESS']]: {
        [RuleType['LACK_OF_MFA']]: true,
        [RuleType['WEAK_PASSWORD']]: true,
        [RuleType['COMPROMISED_PASSWORD']]: true,
        [RuleType['COMPROMISED_USER']]: true,
    },
    [ActionType['ADD_MFA']]: {
        /* [RuleType['LACK_OF_MFA']]: true,
        [RuleType['WEAK_PASSWORD']]: true,
        [RuleType['COMPROMISED_PASSWORD']]: true,
        [RuleType['COMPROMISED_USER']]: true,
        [RuleType['AUTH_HASH_SECURITY']]: true,
        [RuleType['AUTH_PROTOCOL_QUALITY']]: true,
        [RuleType['AUTHENTICATION_QUALITY']]: true,
        [RuleType['AUTH_HASH_QUALITY']]: true, */
    },
    [ActionType['NOTIFY_USER']]: {
        [RuleType['LACK_OF_MFA']]: true,
        [RuleType['WEAK_PASSWORD']]: true,
        [RuleType['COMPROMISED_PASSWORD']]: true,
        [RuleType['COMPROMISED_USER']]: true,
        [RuleType['SHADOW_INDENTITIES']]: true,
        [RuleType['SHADOW_ACCESS']]: true,
        [RuleType['SUSPICIOUS_OUTBOUND_ACCESS']]: true,
        [RuleType['UNKNOWN_SAAS_ACCESS']]: true,
        [RuleType["ACCESS_TO_UNAUTH_COUNTRIES"]]: true,
        [RuleType["ACCESS_FROM_UNAUTH_COUNTRIES"]]: true,
        [RuleType.ACCESS_FROM_PUBLIC_VPN]: true,
        [RuleType.ACCESS_TO_PUBLIC_VPN]: true,
        [RuleType.ACCESS_FROM_ANON_IP]: true,
        [RuleType.ACCESS_TO_ANON_IP]: true,
        [RuleType.DEVIATION_IN_DAILY_ASSET]: true,
        [RuleType.UNAUTHORIZED_ASSET_ACCESS]: true,
        [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: true
    },
    [ActionType['REQUEST_PASSWRD']]: {
        [RuleType['WEAK_PASSWORD']]: true,
        [RuleType['COMPROMISED_PASSWORD']]: true,
        [RuleType['COMPROMISED_USER']]: true
    }
}

export const ActionConfig = {
    ADD_MFA: {
        id: ActionType.ADD_MFA,
        label: 'Add MFA'
    },
    BLOCK_ACCESS: {
        id: ActionType.BLOCK_ACCESS,
        label: 'Block Access'
    },
    NOTIFY_ADMIN: {
        id: ActionType.NOTIFY_ADMIN,
        label: 'Notify Admins'
    },
    NOTIFY_USER: {
        id: ActionType.NOTIFY_USER,
        label: 'Notify User'
    },
    DASHBOARD_VIS: {
        id: ActionType.DASHBOARD_VIS,
        label: 'Dashboard Visibility'
    },
    REQUEST_PASSWRD: {
        id: ActionType.REQUEST_PASSWRD,
        label: 'Request Password Reset'
    },
    CREATE_TICKET: {
        id: ActionType.CRATE_TICKET,
        label: 'Create Ticket'
    }
}

export const PlaybookTableHeaders = [
    'Playbook Name', 'Issue', 'Identity', 'Asset',
    'Actions', 'Risk', 'Priority'];


export const PlaybookMap: any = {
    [RuleType.SHADOW_ASSETS]: {
        db_key: 'shadow_assets',
        type: 'Shadow Assets',
        label: 'Shadow Asset',
        isReadOnly: true
    },
    [RuleType.SHADOW_DIRECTORY]: {
        db_key: 'shadow_directory',
        type: 'Shadow Identity Systems',
        label: 'Shadow Identity Systems',
        isReadOnly: true
    },
    [RuleType.AUTH_HASH_QUALITY]: {
        db_key: 'hashes_quality',
        type: 'Auth Hash Quality',
        label: 'Auth Hash Quality'
    },
    [RuleType.AUTH_HASH_SECURITY]: {
        db_key: 'hashes',
        type: 'Auth Hash Security',
        label: 'Auth Hash Security'
    },
    [RuleType.AUTH_PROTOCOL_QUALITY]: {
        db_key: 'protocols',
        type: 'Auth Protocol Quality',
        label: 'Auth Protocol Quality'
    },
    [RuleType.COMPROMISED_PASSWORD]: {
        db_key: 'compromised_password',
        type: 'Compromised Password',
        label: 'Compromised Password'
    },
    [RuleType.COMPROMISED_USER]: {
        db_key: 'compromised_user',
        type: 'Compromised User',
        label: 'Compromised User'
    },
    [RuleType.EXPOSED_ASSETS]: {
        dnb_key: 'exposed_assets',
        type: 'Exposed Assets',
        label: 'Exposed Assets',
        isReadOnly: true
    },
    [RuleType.LACK_OF_MFA]: {
        db_key: 'lack_of_mfa',
        type: 'Lack of MFA',
        label: 'Lack of MFA'
    },
    [RuleType.SHADOW_ACCESS]: {
        db_key: 'shadow_access',
        type: 'Shadow Access',
        label: 'Shadow Access',
        isReadOnly: true
    },
    [RuleType.SHADOW_EXTERNAL_ACCESS]: {
        db_key: 'external_access',
        type: 'Shadow External Access',
        label: 'Shadow External Access',
        isReadOnly: true
    },
    [RuleType.SUSPICIOUS_INBOUND_ACCESS]: {
        db_key: 'susp_ib_access',
        type: 'Suspicious Inbound Access',
        label: 'Suspicious Inbound Access'
    },
    [RuleType.SUSPICIOUS_OUTBOUND_ACCESS]: {
        db_key: 'susp_ob_access',
        type: 'Suspicious Outbound Access',
        label: 'Suspicious Outbound Access'
    },
    [RuleType.WEAK_PASSWORD]: {
        db_key: 'weak_password',
        type: 'Weak Password',
        label: 'Weak Password'
    },
    [RuleType.UNKNOWN_SAAS_ACCESS]: {
        db_key: 'unknown_saas_access',
        type: 'Unknown SaaS Access',
        label: 'Unknown SaaS Access',
        isReadOnly: true
    },
    [RuleType.UNKNOWN_ACCESS]: {
        db_key: 'unknown_access',
        type: 'Unknown Access',
        label: 'Unknown Access',
        isReadOnly: true
    },
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: {
        db_key: 'access_to_unauthorized_countries',
        type: 'Access to Unauthorized Countries',
        label: 'Access to Unauthorized Countries',
    },
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: {
        db_key: 'access_from_unauthorized_countries',
        type: 'Access from Unauthorized Countries',
        label: 'Access from Unauthorized Countries'
    },
    [RuleType.ACCESS_FROM_ANON_IP]: {
        db_key: 'access_from_anonymous_ip',
        type: 'Access from Anonymous IP',
        label: 'Access from Anonymous IP',
        isReadOnly: true
    },
    [RuleType.ACCESS_TO_ANON_IP]: {
        db_key: 'access_to_anonymous_ip',
        type: 'Access to Anonymous IP',
        label: 'Access to Anonymous IP',
        isReadOnly: true
    },
    [RuleType.DEVIATION_IN_DAILY_ASSET]: {
        db_key: 'deviation_in_daily_asset',
        type: 'Deviation in Daily Asset Activity',
        label: 'Deviation in Daily Asset Activity',
    },
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: {
        db_key: 'unauthorized_asset_access',
        type: 'Unauthorized Asset Access',
        label: 'Unauthorized Asset Access',
        isCustomConfig: true,
        showImportCSV: true,
        showValidationPopUpOnSave: true
    },
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: {
        db_key: 'unauthorized_identity_access',
        type: 'Unauthorized Identity Access',
        label: 'Unauthorized Identity Access',
        isCustomConfig: true,
        showImportCSV: true,
        showValidationPopUpOnSave: true
    },
    [RuleType.SUSPECTED_DIR_BOT]: {
        type: 'Suspected Directory/IdP Bot Attack',
        label: 'Suspected Directory/IdP Bot Attack'
    },
    [RuleType.SUSPECTED_BRUTE_FORCE]: {
        type: 'Suspected Directory/IdP Identity Brute-force Attack',
        label: 'Suspected Directory/IdP Identity Brute-force Attack'
    },
    [RuleType.SUSPECTED_PASS_SPRAY]: {
        type: 'Suspected Directory/IdP Password Spray Attack',
        label: 'Suspected Directory/IdP Password Spray Attack'
    },
    [RuleType.NTLM_RELAY_ATTACK]: {
        type: 'Suspected AD NTLM Relay Attack',
        label: 'Suspected AD NTLM Relay Attack'
    },
    [RuleType.ENUM_AD_ADMINS]: {
        type: 'Enumeration of AD Admins',
        label: 'Enumeration of AD Admins',

    },
    [RuleType.ENUM_AD_USERS]: {
        type: 'Enumeration of AD Users',
        label: 'Enumeration of AD Users',

    },
    [RuleType.UNAUTH_LOGIN_TIME]: {
        type: 'Repeated AD Login Attempts at Invalid Time',
        label: 'Repeated AD Login Attempts at Invalid Time',

    },
    [RuleType.UNAUTH_LOGIN_DEVICE]: {
        type: 'Repeated AD Login Attempts from Invalid Device',
        label: 'Repeated AD Login Attempts from Invalid Device',

    },
    [RuleType.SUSPECTED_ATT_EXP_ACC]: {
        type: 'Suspected Attack on Expired AD Account',
        label: 'Suspected Attack on Expired AD Account/Password',
    },
    [RuleType.SUSPECTED_ATT_DIS_ACC]: {
        type: 'Suspected Attack on Disabled AD Account',
        label: 'Suspected Attack on Disabled AD Account',

    },
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: {
        type: 'Suspected Attack on Locked AD Account',
        label: 'Suspected Attack on Locked AD Account',
    },
    [RuleType.ACCESS_FROM_PUBLIC_VPN]: {
        type: 'Access from Public VPN',
        label: 'Access from Public VPN',
    },
    [RuleType.ACCESS_TO_PUBLIC_VPN]: {
        type: 'Access to Public VPN',
        label: 'Access to Public VPN',
    },
    [RuleType.ACCOUNT_TAKEOVER]: {
        type: 'Impossible Travel',
        label: 'Impossible Travel',
        isAmOps: true,
        showImportCSV: false
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: {
        type: 'Suspected Access Token Sharing',
        label: 'Suspected Access Token Sharing',        
        showImportCSV: false
    },
    [RuleType.ASSETS_WITHOUT_MFA]: {
        type: 'Asset No MFA',
        label: 'Assets with No MFA Configured',
        showImportCSV: false
    }
}
export const PlaybookDocLinks = {
    'Shadow Assets': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/shadow-assets-directory-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow Identity Systems': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/shadow-direshadow-directory-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/shadow-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Auth Hash Security': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/auth-hash-security-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Auth Protocol Quality': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/auth-protocol-quality-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Auth Hash Quality': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/auth-hash-quality-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank')
    },
    'Compromised Password': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/compromised-password-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Compromised User': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/compromised-user-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Exposed Assets': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/exposed-assets-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank')
    },
    'Lack of MFA': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/lack-of-mfa-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow External Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/shadow-external-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Suspicious Inbound Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspicious-inbound-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Suspicious Outbound Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspicious-outbound-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Unknown Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unknown-access-playbook-upcoming?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Unknown SaaS Access': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unknown-saas-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Weak Password': () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/weak-password-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-from-unauthorized-country-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-to-unauthorized-country-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_PUBLIC_VPN]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-using-public-ip-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_PUBLIC_VPN]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-using-public-ip-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_ANON_IP]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-using-anonymous-ip-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_ANON_IP]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/access-using-anonymous-ip-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unauthorized-asset-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unauthorized-identity-access-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.DEVIATION_IN_DAILY_ASSET]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/deviation-in-daily-asset-activity-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.SUSPECTED_BRUTE_FORCE]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-identity-brute-force-attack-playbook?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.SUSPECTED_ATT_DIS_ACC]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-attack-on-disabled-account-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-attack-on-locked-account-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_ATT_EXP_ACC]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-attack-on-expired-account-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.ENUM_AD_ADMINS]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/enumeration-of-ad-admins-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.ENUM_AD_USERS]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/enumeration-of-ad-users-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_DIR_BOT]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-directory-bot-attack-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.NTLM_RELAY_ATTACK]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-ntlm-relay-attack-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_PASS_SPRAY]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-password-spray-attack-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.UNAUTH_LOGIN_DEVICE]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unauthorized-login-device-playbook?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.UNAUTH_LOGIN_TIME]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/unauthorized-login-time-playbook?${GlobalConsts.DOC_TOKEN} `);
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/suspected-access-token-sharing?${GlobalConsts.DOC_TOKEN} `);

    },
    [RuleType.ACCOUNT_TAKEOVER]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/impossible-travel-playbook?${GlobalConsts.DOC_TOKEN} `);
    },
    [RuleType.ASSETS_WITHOUT_MFA]: () => {
        window.open(`${GlobalConsts.PLAYBOOK_LINK}/assets-with-no-mfa-configured-playbook?${GlobalConsts.DOC_TOKEN} `);
    }

}

export const POSTURE_ISSUE = { label: "Posture Issues", type: "Posture Issues" };

export const ENTITIES = {
    IDENTITY: { id: 'IDENTITY', tab: 'identity', pageType: 'Identities', path: 'identities', label1: 'Identities', label2: 'Identity', apiVal: 'identity' },
    ASSETS: { id: 'ASSETS', tab: 'assets', pageType: 'Assets', path: 'assets', label1: 'Assets', label2: 'Asset', apiVal: 'asset' },
    ACCESSES: { id: 'ACCESSES', tab: 'accesses', pageType: 'Accesses', path: 'accesses', label1: 'Accesses', label2: 'Access', apiVal: 'access' },
    IDENTITY_SYSTEMS: { id: 'IDENTITY_SYSTEMS', tab: 'identity systems', pageType: 'Directory', path: 'directory', label1: 'Identity Systems', label2: 'Identity System', apiVal: 'identity system' }
}

export const DASHBOARD_CATEGORY = [
    { key: 'id_access_infra', value: 'Identity and Access Infrastructure' },
    { key: 'shadow_activity', value: 'Shadow Activity' },
    { key: 'unauthorised_access', value: 'Unauthorized Access' },
];